import React, {useEffect, useState} from 'react';
import CardsNews from "../components/CardsNews/CardsNews";
import {useSelector} from "react-redux";

function News(props) {
    const [imageStyle, setImageStyle] = useState({

    });

    const {addStyle1} = useSelector(state => state.special)
    const {addStyle2} = useSelector(state => state.special)
    const {addStyle3} = useSelector(state => state.special)
    const {addStyle} = useSelector(state => state.special)
    useEffect(() => {
        if (addStyle1) {
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#9DD1FF",
                color:"black",
                width: "100%",
                filter: 'grayscale(50%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
        if (addStyle2) {
            setImageStyle({
                ...imageStyle,
                color:"white",
                backgroundColor: "#464646",
                width: "100%",
                filter: 'grayscale(70%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
        if (addStyle3) {
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#1c1c1c",
                color:"white",
                width: "100%",
                filter: 'grayscale(100%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '0';
            }
        }
        if (addStyle){
            setImageStyle({
                color: "black"
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
    }, [addStyle1, addStyle2, addStyle3, addStyle]);


    return (
        <div style={imageStyle}>
            <CardsNews/>
        </div>
    );
}

export default News;