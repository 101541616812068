import {UPDATE_LANGUAGE} from "../actionTypes/actionTypes";

const initialState = {
    language: localStorage.getItem('language')!=null ? localStorage.getItem('language') :  "uz"
};


export const languageReducer = (state = initialState, action) => {

    const {type, payload} = action;

    switch (type) {
        case UPDATE_LANGUAGE:
            return {
                ...state,
                language: payload,
            };


        default:
            return state;
    }
};