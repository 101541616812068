import {ADD_STYLE, ADD_STYLE1, ADD_STYLE2, ADD_STYLE3, FONT_SIZE} from "../actionTypes/actionTypes";

export const editFontSize = (newValue) => (dispatch) => {
    dispatch({
        type: FONT_SIZE,
        payload:newValue
    });
};
export const addStyle1 = (newValue) => (dispatch) => {
    dispatch({
        type: ADD_STYLE1,
        payload:newValue
    });
};
export const addStyle2 = (newValue) => (dispatch) => {
    dispatch({
        type: ADD_STYLE2,
        payload:newValue
    });
};
export const addStyle3 = (newValue) => (dispatch) => {
    dispatch({
        type: ADD_STYLE3,
        payload:newValue
    });
};
export const addStyle = (newValue) => (dispatch) => {
    dispatch({
        type: ADD_STYLE,
        payload:newValue
    });
};