import React, {useEffect} from 'react';

function Akademiya(props) {
    useEffect(()=>{
        window.scrollTo(0, 0); // Sahifa yuqoriga o'tkazish
    },[])
    return (
        <div className='container'>
            <br/>
            <h4 className='text-center'>Qurolli Kuchlar Akademiyasi tarixi va faoliyati, erishilgan
                yutuqlariga doir ma’lumotlar, o‘quv kafedralari, mutaxassislar
                tayyorlash bo’yicha yo‘nalishlar, Akademiya rahbariyati
                haqida ma’lumotlar.</h4>
            <br/>
            <h4 className='text-center'>KIRISH</h4>
            <br/>
            <p>
                O‘zbekiston Respublikasi Qurolli Kuchlari Akademiyasi bugungi
                kunda O‘zbekiston Respublikasi Qurolli Kuchlariga yuqori
                malakali ofitser kadrlarni tayyorlash va yetkazib berish
                bo‘yicha yetakchi oliy harbiy o‘quv dargohi sanaladi.
            </p>
            <p>
                O‘zbekiston Respublikasi Qurolli Kuchlarining yangi akademiyasi
                O‘zbekiston Respublikasi Prezidentining 2017-yil 25-apreldagi
                farmoniga muvofiq avvalgi harbiy ta’lim muassasasi – Toshkent
                oliy umumqo‘shin qo‘mondonlik bilim yurti negizida o‘zining
                ulug‘vor harbiy an’analarini saqlab, 1994-yilda tashkil topgan
                birinchi Qurolli Kuchlar Akademiyasi va mamlakatimizning boshqa
                yetakchi ilmiy-o‘quv muassasalari tajribalarini o‘zida mujassam
                etgan holda tashkil topdi. Bugungi kunda akademiya harbiy ta’lim
                tizimida yetakchi o‘rinni egallagan yagona harbiy ta’lim markazidir.
            </p>
            <h4 className='text-center'>TARIXI</h4>
            <br/>
            <p>
                1918-yil 4-may kuni RSFSR Sovet xalq komissarlarining avtonom
                respublikasiga “Turkiston harbiy okrugi nomining ta’sis etilishi
                to‘g‘risida” dekreti imzolandi. O‘sha kezlarda Turkistondagi siyosiy
                va harbiy vaziyat favqulodda murakkab bo‘lganligi bois, yosh Turkiston
                Sovet Respublikasiga bir vaqtning o‘zida ham tashqi va ham ichki
                dushmanlar bilan shiddatli qurolli kurash olib borishga to‘g‘ri
                keldi. Qo‘shinlarni tashkil etilishida ko‘plab jiddiy muammolar
                mavjud bo‘lganligi uchun ular barcha turdagi ta’minotda
                yetishmovchiliklarga duch kelishgan. Ayniqsa, malakali harbiy
                qo‘mondonlik kadrlarning yetishmasligi sababli qo‘mondonlik kurslarini
                tashkil etish qarori qabul qilindi. Turkiston Sovet Respublikasi Harbiy
                Komissariatining 1918-yil 12-iyuldagi 456-sonli qarori bilan Toshkent
                shahrida Turkiston Sovet qo‘mondonlik kurslarini tashkil etish bo‘yicha
                komissiya tuzildi.
            </p>
            <br/>
            <p>
                1918-yil 12-iyul Toshkent oliy umumqoʻshin qoʻmondonlik bilim yurtining
                rasmiy tashkil topgan kuni deb qayd qilindi. Turkiston Sovet
                qoʻmondonlik kurslari Rossiya imperatorligi Toshkent harbiy bilim
                yurtining birinchi jahon urushi arafasida Parkent koʻchasida qad
                koʻtargan oʻquv bazasi va uy-joy kazarma fondi binolarida
                joylashtirildi. Kurslarning davomiyligi to‘rt oydan sakkiz
                oygacha bo‘lgan. Kurs dasturi quyidagilarni o‘z ichiga olgan:
                taktik tayyorgarlik, topografiya, artilleriya, muhandislik va
                pulemyot ishi, otish, ot minish, nizomlar, avtomobilsozlik, siyosiy
                iqtisod, geografiya, tarix, fizika asoslari, kimyo, elektrotexnika
                va amaliy mashg‘ulotlar. 1939- 1940- yillarda bilim yurti bitiruvchilari
                Hasan ko‘li va Xalkin-Gol daryosi yaqinida yapon samuraylari, Kareliya
                bo‘yinida finlar bilan bo‘lgan janglarda faol ishtirok etdilar.
                1937-yilda maktab Toshkent harbiy bilim yurti deb qayta nomlandi.
            </p>
            <h4 className='text-center'>BILIM YURTI IKKINCHI JAHON URUSHI YILLARIDA</h4>
            <br/>
            <p>
                Bilim yurti bitiruvchilarining fashizm ustidan gʻalabaga qoʼshgan
                hissasi(1941 – 1945-yillar). 1941-yil 22-iyunda Germaniyaning fashist
                qo‘shinlari xoinlik bilan Sovet Ittifoqi chegaralariga bostirib
                kirdilar. Sovet xalqining nemis-fashist bosqinchilariga qarshi
                urushi boshlandi. Lenin nomidagi Toshkent piyodalar bilim yurti
                frontga yuborilayotgan ofitserlar tarkibini to‘ldirish, bo‘linmalarni
                shakllantirish, alohida o‘qotar brigadalar tuzish va qo‘mondon
                kadrlarni jadal tayyorlash markazi bo‘lgan. 1941-yil iyun oyida
                o‘quv dasturi bo‘yicha leytenantlarning urushdan oldingi navbatdagi
                bitiruvi bo‘lib o‘tdi. Bilim yurtini tashkil topgan paytdan boshlab
                1941-yilgacha bir necha ming piyodalar komandirlari, otliq askarlar,
                artilleriyachilar va o‘rta darajadagi siyosiy xodimlar tamomladi.
                Bu piyodalar komandirlarining birinchi harbiy bitiruvi edi. Doimiy
                shtatdagi ofitserlar hamrohligida bitiruvchilar guruhlari  o‘qchi
                qismlarini to‘ldirish uchun frontga ketdilar.
            </p>
            <br/>
            <p>Ularning ko‘pchiligi 1941-yil iyun-iyul oylarida 310-,312- (196 kishi), 314- (196 kishi) va 316-o‘qchilar bo‘linmalariga turli rahbarlik lavozimlariga yuborilgan. Harbiy qismlar va tuzilmalar yosh ofitserlardan tashqari yana jangovar bo‘linmalar ofitserlari – bilim yurti komandirlari va o‘qituvchilari bilan to‘ldirildi. 1941-yil oktabr-noyabr oylarida O‘rta Osiyo harbiy okrugida ularni Moskva yaqinidagi shiddatli janglarga jo‘natish uchun bilim yurtidan 34- va 35-alohida kursantlar brigadalari tuzildi. Bilim yurti tezkor olti oylik ofitserlar tayyorlash dasturiga muvofiq, urushning dastlabki davrida armiya uchun olti mingdan ziyod ofitser kadrlarni tayyorladi, shu jumladan 1941-yil oktabr oyida tank qo‘shinlari uchun bir guruh ofitserlarni hamda milliy kavaleriya qismlari uchun mahalliy millat vakillaridan bir guruh ofitserlarni tayyorladi. Umuman olganda, 1941-1945 fashizmga qarshi urush yillarida bilim yurtida 25 marta bitiruv o‘tkazilib, 8 mingdan ziyod ofitser kadrlar tayyorlab berildi. Ikkinchi jahon urushi va urushdan oldingi davrgacha bo‘lgan qahramonliklar uchun bilim yurtinining minglab kursantlari va bitiruvchilari orden va medallar bilan taqdirlanganlar. Bilim yurtini 50 dan ortiq bitiruvchilari Sovet Ittifoqi Qahramoni degan yuksak unvonga sazovor bo‘lganlar, ularning ko‘plariga unvonlar vafotidan keyin berilgan.</p>
            <br/>
            <p>1943-yil 30-avgustda bilim yurti Qizil Armiya qo‘mondonligi tarkibini tayyorlashda va Vatan uchun janglarda bevosita ishtirokida erishgan ulkan yutuqlari uchun “Qizil yulduz” ordeni bilan taqdirlandi.</p>

            <h4 className='text-center'>BIZNING BITIRUVCHILARIMIZ – IKKINCHI JAHON URUSHI QAHRAMONLARI</h4>
            <br/>
            <p>Oliy umumqo‘shin qo‘mondonlik bilim yurti (1945-1991-yillar)

                Urushdan keyingi davrda ofitserlarni tayyorlashga talab ortib bordi. Harbiy fan va texnika jadal rivojlandi. Yangi kurash vositalari bo‘lgan ommaviy qirg‘in qurollari paydo bo‘ldi.  Qo‘shinlarning jangovor shayligi yanada kuchayib, tezkorligi va harakatchanligi sezilarli darajada oshdi. Ikkinchi jahon urushi tajribasi, harbiy fan-texnikaning rivojlanishi inobatga olinib, qo‘shinlarni tashkil etish va jangovar tayyorgarligi takomillashtirildi. 1955-yildan boshlab bilim yurtida murakkab darslar o‘tkazila boshlandi. Ularda birlashgan qurol taktikasining turli mavzulari boshqa fanlar – artilleriya, topografiya, muhandislik va kimyoviy moddalarga qarshi tayyorgarlik bilan birgalikda ishlab chiqildi. 1957-yilning boshida bilim yurtida harbiy ilmiy jamiyatning bo‘limi tashkil etildi. Jamiyat a’zolari o‘tgan urush tajribalarini umumlashtirish, harbiy ilm-fanning so‘ngi yutuqlarini o‘rganish va ommalashtirish, harbiy-nazariy konferensiyalarni o‘tkazish bilan shug‘ullandilar. 1958-yilda bilim yurti oliygohga aylantirilib, oliy o‘quv yurtiga tenglashtirildi. Bilim yurtida o‘qish muddati to‘rt yil bo‘ldi. Yangi o‘quv dasturi tarkibiga harbiy fanlar bilan bir qatorda oliy matematika va nazariy mexanika, fizika, kimyo va boshqa fanlar kiritildi. Ijtimoiy fanlarga ham ko‘proq e’tibor berila boshlandi. 1966-1967-yillarda o‘quv markazi hududidan tashqarida, bir necha kun mobaynida tog‘ yoki sahro sharoitida amaliy darslar o‘tkazish ishlari amalga oshirildi. Ko‘pincha bu kabi mashg‘ulotlardan keyin kursantlar o‘quv markaziga, ya‘ni ortga qaytishlari davomida  taktika fanidan o‘quv savollarigni o‘zlashtirganlari holda 30 kilometr piyoda yurishlari lozim edi.
                60-yillardan boshlab kursantlarning tunda o‘q otish (merganlik)  mahoratlarini oshirishga katta e‘tibor qaratildi.</p>
            <br/>
            <p>1965-1966 yillar davomida qo‘shinlar zirhli transportyorlar bilan qurollantirildi. Shu sababli o‘quv dasturi o‘zgartirilib, kursantlarning texnik tayyorgarligiga ko‘proq vaqt ajratila boshlandi. Shunga ko‘ra ta’lim maqsadi ham o‘zgardi: endi kursantlar qo‘shinlarning moddiy ta’minoti fanini hamda zirhli transportyorlar va o‘rta vaznli tanklardan foydalanish qoidalarini nazariy jihatdan, ularga texnik yordam ko‘rsatishni, shuningdek, tanklarni o‘nqir-cho‘nqir joylarda haydashni esa amaliy jihatdan o‘rganadigan bo‘ldilar. 1967-yildan boshlab bilim yurtida tahsil olayotgan bo‘lajak komandirlar yangi texnikani, ya’ni piyodalar jangovar mashinasi (PJM), dushmanga qarshi qo‘llaniladigan zamonaviy otish, raketa va zenit qurollarini o‘rganish bo‘yicha bilimlarni o‘zlashtira boshladilar. Bilim yurtini 1972-yilda bitirgan yosh ofitserlar harbiy ta’lim bilan bir qatorda zirhli va avtotraktor texnikasini qo‘llash bo‘yicha muhandis mutaxassisligi diplomini oldilar.  1974-1977 yillar davomida tankodrom to‘liq qayta jihozlanib, jangovar mashinalar va avtomobil texnikalarini yo‘l harakati qoidasi bo‘yicha haydash bo‘yicha trenajyor sinflar tashkil etildi.</p>
            <br/>
            <h4 className='text-center'>MUSTAQILLIK YILLARIDA BILIM YURTNING FAOLIYATI</h4>
            <br/>
            <p>O‘zbekiston Respublikasi mustaqillikni qo‘lga kiritgach, mamlakatimizdagi ta’lim muassasalari qatori harbiy mutaxassisliklar tayyorlovchi oliy harbiy ta’lim muassassalariga ham turli harbiy yo‘nalishlardagi yuqori malakali, yuksak salohiyatli harbiy kadrlar tayyorlashdek dolzarb vazifa yuklatildi. 1991-1993- yillarda bilim yurtida “Moto-o‘qchi qo‘shinlari qo‘mondonlik taktikasi,” ixtisosligi bo‘yicha ofitserlarni tayyorlashni davom ettirish bilan birga bo’lg’usi ofitserlarni turli yo’nalishlarda o‘qitishga tayyorgarlik ko‘rildi. XX asr oxirida 134-bitiruv kursantlari birinchi bo‘lib O‘zbekiston Respublikasining suvereniteti va mustaqilligini himoya qilish uchun qasamyod qabul qildilar. Bilim yurti ofitserlari va kursantlari bir necha bor O‘rta Osiyo harbiy harakatlar teatridagi vaziyatlarda jangovar vazifalarni bajarish uchun jalb qilingan. Mustaqillik yillarida yangi kafedralar tashkil etildi, bilim yurtidagi barcha inshootlar, o‘quv mashg‘uloti binolari va o‘quv-moddiy bazasi qayta ta’mirlandi, bilim yurti yangicha ko‘rinishga ega bo‘ldi. Toshkent oliy umummqo‘shin bilim yurti Respublika Mudofaa vazirligi tasarrufidagi harbiy ta’lim muassasalari o‘rtasida o‘tkazilgan tanlovlarda g‘oliblikni qo‘lga kiritib, “Eng yaxshi harbiy ta’lim muassasasi” nomiga bir necha bor sazovor bo‘lgan.</p>
            <br/>
            <p>O‘zbekiston Respublikasi Mudofaa vazirining 1993 yil 26 martdagi 46- sonli buyrug‘i  bilan Qizil Bayroqli Qizil yulduz ordenli V.I.Lenin nomidagi Toshkent oliy umumqo‘shin qo‘mondonlik bilim yurti Toshkent oliy umumqo‘shin qo‘mondonlik bilim yurti  (TOUQBYu) deb yuritila boshlandi.</p>
            <br/>
            <p> Sobiq SSRI va O‘zbekistonning davlat tuzumi va ijtimoiy-siyosiy hayotida ro‘y berayotgan o‘zgarishlarga qaramay, Toshkent OUQBYu bir daqiqaga bo‘lsa ham bo‘lajak ofitserlar tayyorlashdek muqaddas vazifani to‘xtatmagan holda, ushbu  ilm dargohida uzluksiz ravishda o‘quv-tarbiyaviy jarayon davom etib kelmoqda.</p>
            <br/>
            <h4 className='text-center'>BIRINCHI O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI AKADEMIYASI (1994 – 2017- yy.)</h4>
            <br/>
            <p>O‘zbekiston Respublikasi Qurolli Kuchlari Akademiyasi O‘zbekiston Respublikasi Vazirlar Mahkamasining 1994-yil 15avgustdagi 414-49-sonli qarori bilan tashkil etilgan. 1995-yil 2-sentyabr kuni Qurolli Kuchlar Akademiyasi O‘zbekiston Respublikasining birinchi Prezidenti I. A. Karimov tomonidan tantanali ravishda ochildi. Akademiyaning asosiy vazifalari boshqaruvning taktika va operativ-taktika bo‘g‘inlari ofitserlari tarkibini tayyorlash, Qurolli Kuchlar rahbar kadrlarini qayta tayyorlash va malakasini oshirish, mudofaa qurilishida olib boriladigan ilmiy-amaliy tadqiqotlarni o‘zaro muvofiqlashtirish va ishlab chiqish, mamlakatda harbiy ta’lim sifat-samaradorligini oshirishdan iboratdir. Tinglovchilarni o qitishda O‘zbekiston Respublikasi Qurolli Kuchlari manfaatlariga javob beruvchi tajribaga ega bo‘lgan mamlakatimizning yetakchi oliy taʼlim muassasalari hamda xorijiy mamlakatlarning tadqiqot markazlari ekspert va mutaxassislari jalb qilinmoqda. Akademiyada harbiy sohada milliy xavfsizlikka tahdid va hurujlarni, zamonaviy urushlar va harbiy mojarolarning o‘ziga xos xususiyatlarini, mudofaa qurilishining xorijiy tajribasini chuqur o‘rganishga, shu asosda o’quv, ilmiy-uslubiy adabiyotlar va axborot-tahliliy materiallarni tayyorlashga, tinglovchilarning harbiy-ilmiy jamiyati faoliyatini amalga oshirishga qaratilgan tizimli ilmiy tadqiqotlar olib borilmoqda. 1994 yildan 2017 yilga qadar Akademiyada jami 21 marotaba bitiruv o‘tkazilib, boshqaruvning operatin-taktik bo‘g‘iniga 1160 nafar ofitserlar tayyorlandi va ulardan 93 nafar Akademiyani a’lo baholarga tamomladi.</p>
            <br/>
            <p>1995 yil 5-mart kuni Toshkent oliy umumqo‘shin qo‘mondonlik bilim yurtiga Mudofaa vaziri tomonidan Jangovar Bayroq va O‘zbekiston Respublikasi Prezidentining Yorlig‘i tantanali ravishda taqdim etildi.</p>
            <br/>
            <p>Toshkent oliy umumqo‘shin qo‘mondonlik bilim yurti, harbiy ta’lim beruvchi yetakchi oliy harbiy ta’lim muassasasi sifatida, turli xil davrlarni boshidan kechirgan (ko‘p asrlik tarixi davomida bilim yurtining nomi 18 marta o‘zgartirilgan), ularning har birini o‘z taraqqiyoti, o‘zining eng yuqori rivojlanish cho‘qqisi mavjud bo‘lgan. Aynan shu maskan, aynan mana shu dargoh o‘z davrining qahramonlarini yetishtirib bergan.</p>
            <br/>
            <p>72 nafar qahramonlar, 200 nafar generallar, minglab ofitserlar davlat mukofotlari bilan taqdirlandilar, 20 nafar bitiruvchlar Mustaqil Davlatlar Hamdo‘stligi mamlakatlaridagi harbiy idoralarda rahbarlik qilganlar. Toshkent OUQBYu bitiruvchilari deyarli barcha Mustaqil Davlatlar Hamdo‘stligi davlatlarining Qurolli Kuchlarida xizmat qilgan va xizmat qilmoqdalar, ko’plari jangovar harakatlar, antiterror operatsiyalarida qatnashganlar va harbiy ishlar bo’yicha malakali mutaxassislar sifatida tanilganlar.</p>
            <br/>
            <p>Akademiya harbiy ta’lim tizimidagi yagona yuqori salohiyatga ega bo‘lgan muassasa sifatida e’tirof etilayotgan harbiy ilm-fan markazidir. Tinglovchi va kursantlarni tayyorlashda mamlakatimizdagi yetakchi oliy o‘quv yurtlari va xorijiy mamlakatlar ilmiy markazlari mutaxassislari jalb qilingan. Ularning tajribasi O‘zbekiston Respublikasi Qurolli Kuchlari ta‘lim oluvchilarida katta qiziqish uyg‘otmoqda.</p>
            <br/>
            <h4 className='text-center'>AKADEMIYA TUZILMASI</h4>
            <br/>
            <p>Akademiya qo‘mondonlik, o‘quv-uslubiy markaz, fakultetlar, kafedralar, oliy ta’lim muassasidan keyingi ta’lim bo‘limi, pedagog kadrlarning malakasini oshirish markazi, ilmiy vzvod va ta’minot bo‘limlaridan tashkil topgan.</p>
            <br/>
            <p>Akademiyada 4 ta fakultet (Umumqo’shin, Chegara qo’shinlari kadrlarini tayyorlash va malakasini oshirish, Harbiy xavfsizlik va davlat mudofaasi va Malaka oshirish), 17 kafedra, 11 ta 4 yillik bakalavr ta’lim yo‘nalishlari, 11 ta 2 yillik magistratura mutaxassisliklari bo‘yicha taktik, operativ-taktik, operativ-strategik bo‘g‘inlari harbiy ofitser kadrlarini tayyorlash, qayta tayyorlash va malakasini oshirish va 8 ta ixtisoslik bo‘yicha tayanch doktoranturada oliy toifali harbiy ilmiy va ilmiy-pedagogik kadrlar hamda malaka oshirish fakultetida 1 oydan 3 oygacha bo‘lgan muddatda 10 ta mutaxassislik bo‘yicha ofitser kadrlarni tayyorlash amalga oshiriladi.</p>
            <br/>
            <p>Shuningdek, O‘zbekiston Respublikasi Mudofaa vazirligi harbiy ta’lim muassasalaridagi pedagog kadrlarni malakasini oshirish Markazida o‘qituvchi-ofitserlarni qayta tayyorlash va malakasini oshirish kurslari yo‘lga qo‘yilgan.</p>
            <br/>
            <h4 className='text-center'>FAKULTETLAR</h4>
            <br/>
            <p>Harbiy xavfsizlik va davlat mudofaasi fakulteti – operativ-taktik va operativ-strategik (strategik) bo‘g‘in ofitserlarni, shuningdek Davlat xavfsizligi va mudofaa sohasida mamlakatning boshqaruv va xo‘jalik boshqarmalari vakillarini tayyorlash, ularni qayta tayyorlash va malakasini oshirishga mo‘ljallangan.</p>
            <br/>
            <p>Ushbu fakultet boshqarmadan, “Harbiy xavfsizlik kafedrasi”, “Harbiy san’at kafedrasi”, “Harbiy boshqaruv kafedrasi” va “Ta’minot turlari kafedrasi” dan tashkil topgan.</p>
            <br/>
            <p>Umumqo‘shin fakulteti – bakalavr darajasidagi kursantlarni tayyorlash uchun mo‘ljallangan.</p>
            <br/>
            <p>Chegara qo‘shinlari kadrlarini tayyorlash, qayta tayyorlash
                va malakasini oshirish fakulteti – ushbu fakultet boshqarma, “Chegara san’ati” va “Chegara faoliyati” kafedralaridan tashkil topgan.</p>
            <br/>
            <p>Malaka oshirish fakulteti – taktik va operativ-taktik boshqaruv bo‘g‘ini ofitser kadrlarni qayta tayyorlash va malakasini oshirish uchun mo‘ljallangan.</p>
            <br/>
            <p>Shuningdek, Akademiyada quyidagi kafedralar mavjud:</p>
            <ol >
                <li>“Umumiy taktika”;</li>
                <li>“Qo‘shinlarni kundalik faoliyatini boshqarish”;</li>
                <li>“Jangovar ta’minot”;</li>
                <li>“Moddiy ta’minot”;</li>
                <li>“Texnik ta’minot”;</li>
                <li>“Gumanitar fanlar”;</li>
                <li>“Qurollanish va otish”;</li>
                <li>“Ichki ishlar vazirligi qo‘shinlarini tayyorlash”;</li>
                <li>“Jismoniy tayyorgarlik va sport”;</li>
                <li>“Tillar”;</li>
                <li>“Tabiiy fanlar”.</li>
            </ol>
            <p>Akademiyaning fakultetlari va kafedralaridagi o‘quv jarayonlari O‘quv – metodik markaz tomonidan tashkillashtiriladi va muvofiqlashtiriladi.</p>
            <p>Qurolli Kuchlar Akademiyasi respublikaning tayanch oliy harbiy ta’lim muassasasi hisoblanib, unda kadrlarni tayyorlash quyidagicha belgilangan:</p>
            <p>-to‘rt yillik o‘quv dasturi bo‘yicha (o‘n bitta ta’lim yo‘nalishi) tayanch oliy harbiy ma’lumotli “bakalavr” darajasidagi taktik bo‘g‘indagi ofitserlar tayyorlanadi;</p>
            <p>-bakalavr darajasidagi kursantlarni tayyorlash Akademiya tarkibidagi Umumqo‘shin fakultetining o‘quv bo‘linmalarida amalga oshiriladi.</p>
            <p>-ikki yillik o‘quv dasturi bo‘yicha (o‘nta yo‘nalishda) oliy harbiy ma’lumotli “magistr” darajasidagi operativ-taktik bo‘g‘ini ofitserlar tayyorlanadi:</p>
            <p>-“Qo‘mondonlik-shtab operativ-taktik” yo‘nalishida ikki yillik muddatda magistr darajasida O‘zbekiston Respublikasi Qurolli Kuchlari kuch tuzilmalarida (Mudofaa vazirligi, Ichki ishlar vazirligi,  Favqulodda vaziyatlar vazirligi, Davlat xavfsizlik xizmati, Chegara qo‘shinlari qo‘mondonligi, Milliy gvardiya) xizmat o‘tayotgan operativ-taktik bo‘g‘indagi ofitserlar o‘qitiladi.</p>
            <p>-bir yillik maxsus o‘quv dasturi bo‘yicha oliy harbiy ma’lumotli “magistr” darajasidagi “Davlat va harbiy boshqaruv” bo‘g‘ini ofitserlari tayyorlanadi.</p>
            <p>-belgilangan muddatda magistr darajasida O‘zbekiston Respublikasi Qurolli Kuchlari kuch tuzilmalarida (Mudofaa vazirligi, Ichki ishlar vazirligi,  Favqulodda vaziyatlar vazirligi, Davlat xavfsizlik xizmati, Chegara qo‘shinlari qo‘mondonligi, Milliy gvardiya) xizmat o‘tayotgan yuqori bo‘g‘inli ofitser va generallar o‘qitiladi.</p>
            <p>-uch yillik maxsus o‘quv dasturi bo‘yicha “falsafa doktori” ilmiy darajasida oliy toifali harbiy ilmiy va ilmiy-pedagogik kadrlar tayyorlanadi.</p>
            <p>Shuningdek, malaka oshirish fakultetida quyidagi mutaxassisliklar bo‘yicha ofitserlar ta’lim oladi:</p>
            <p>3 oylik oliy kurslarda:</p>
            <p>-taktik bo‘g‘in ofitserlari (batalyon komandiri, batalyon komandirlarining o‘rinbosarlari va ularga tenglashtirilganlar);</p>
            <p>-operativ-taktik bo‘g‘in ofitserlari (harbiy okrug va qismlar boshqarmalari ofitserlari va ularga tenglashtirilganlar).</p>
            <p>-1 oylik kurslarda safarbarlik sohasi ofitserlari (Tuman, shahar Mudofaa ishkar bo‘lini va harbiy qism safarbarlik bo‘linmasi boshliqlari).</p>
            <p>15 kunlik kurslarda:</p>
            <p>-razvedka va maxsus operatsiya kuchlari ofitserlari (razvedka va maxsus operatsiya kuchlari  bo‘linmalari komandirining o‘rinbosarlari, razvedka boshliqlari);</p>
            <p>-tarbiyaviy va mafkuraviy ishlar organlari ofitserlari (batalyon komandirlarining TvaMIBO‘lari harbiy okrug va qismlarning ofitserlari hamda ularga tenglashtirilganlar);</p>
            <p>-oziq-ovqat xizmati ofitserlari (harbiy okrug va qismlar oziq-ovqat xizmati boshliqlari va ularga tenglashtirilganlar);</p>
            <p>-kiyim-kechak xizmati ofitserlari (harbiy okrug va qismlar kiyim-kechak xizmati boshliqlari va ularga tenglashtirilganlar);</p>
            <p>-yoqilg‘i-moylash xizmati ofitserlari (harbiy okrug va qismlar yoqilg‘i-moylash maxsulotlari xizmati boshliqlari va ularga tenglashtirilganlar);</p>
            <p>-muhandislik qo‘shinlari xizmati ofitserlari (harbiy okrug
                va qismlar muhandislik xizmati boshlig‘i, muhandislik texnik otryadlari komandirlari va o‘rinbosarlari);</p>
            <p>-radiatsion, kimyoviy, biologik muxofaza qo‘shinlari xizmati ofitserlari (harbiy okrug va qismlar, batalyonlar va ularga tenglashtirilgan RKBM ofitserlari).</p>
            <br/>
            <h4 className='text-center'>MUTAXASSISLAR TAYYORLASH</h4>
            <br/>
            <p>Akademiyaning umumqo‘shin fakultetida quyidagi bakalavr darajasidagi mutaxassislar tayyorlanadi: Qo‘shinlarning taktik qo‘mondonligi (motoo‘qchi bo‘linmalari);</p>
            <p>Qo‘shinlarning taktik qo‘mondonligi (chegara qo‘shinlari);</p>
            <p>Qo‘shinlarning taktik qo‘mondonligi (qo‘shin razvedkasi);</p>
            <p>Qurolli Kuchlar bo‘linmalarida tarbiyaviy va mafkuraviy ishlarni tashkillashtirish;</p>
            <p>Qo‘shinlarning taktik qo‘mondonlik (muhandislik qo‘shinlari);</p>
            <p>Qo‘shinlarning taktik qo‘mondonligi (radiatsion, kimyoviy va biologik muhofaza qo‘shinlari);</p>
            <p>Qo‘shinlarning taktik qo‘mondonligi (Ichki ishlar vazirligi qo‘shinlari);</p>
            <p>Aniqlovchi va qo‘riqlovchi tizimlardan foydalanish bo‘yicha qo‘mondonlik- muhandislik faoliyati;</p>
            <p>Front orti ta’minoti taktik qo‘mondonligi (qo‘shinlarni kiyim-kechak ta’minoti);</p>
            <p>Front orti ta’minoti taktik qo‘mondonligi (qo‘shinlarni oziq-ovqat ta’minoti);</p>
            <p>Front orti ta’minoti taktik qo‘mondonligi (qo‘shinlarni yoqilg‘i ta’minoti).</p>
            <p>Bitiruvchilarga dastlabki ofitserlik “leytenant” harbiy unvoni beriladi hamda ular Qurolli Kuchlarning bo‘linmalari va qismlarida tanlagan mutaxassisliklari bo‘yicha boshlang‘ich harbiy lavozimlarda xizmat qiladilar.</p>
            <p>Akademiyaning Harbiy xavfsizlik va Davlat mudofaasi fakultetida quyidagi magistr darajasidagi mutaxassislar tayyorlanadi:</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (motoo‘qchi qo‘shinlari);</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (tank qo‘shinlari);</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (havo-desant qo‘shinlari);</p>
            <p>Qurolli kuchlar qo‘shinlarida tarbiyaviy va mafkuraviy ishlarni tashkillashtirish;</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (qo‘shinlar razvedkasi);</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (artilleriya qo‘shinlari);</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (aloqa qo‘shinlari);</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (chegara qo‘shinlari);</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (RKB muhofaza qo‘shinlari);</p>
            <p>Qo‘shinlarning operativ-taktik shtab qo‘mondonligi (qo‘shinlar texnik ta’minoti).</p>
            <p>Harbiy xavfsizlik va davlat mudofaasi fakultetida tinglovchilar ikki yillik o‘qish davomida qo‘mondonlik-shtab operativ-taktik mutaxassisligi bo‘yicha tahsil oladilar. Bitiruvchilar Qurolli Kuchlarning boshqaruv lavozimlariga tayinlanadilar.</p>
            <p>Oliy ta’limdan keyingi ta’lim bo‘limi yuqori malakali ilmiy va ilmiy-pedagogik kadrlarni rejali tayyorlash maqsadida tashkil etilgan. Hozirda ushbu bo‘limda 100dan ortiq tadqiqotchilar faoliyat olib boradilar.</p>
            <br></br>
            <h4 className='text-center'>O‘QUV SINFLARI</h4>
            <p>Kursantlar uchun eng qulay o‘quv sharoitlari yaratilgan. Keng va shinam o‘quv xonalari va auditoriyalar eng zamonaviy texnologiyalar: televizorlar, interaktiv doskalar, planshetlar, kompyuterlar, videokonferensaloqa tizimlari, zamonaviy vizualizatsiya uskunalari bilan jihozlangan. Ta’lim jarayonida eng yangi texnologiyalar, modellashtirish va simulyatsiya vositalaridan foydalanish aniq va nazarda tutilgan maqsadlarga to‘laqonli erishish imkonini beradi. Bunday qulay ta’lim sharoitlarini yaratish maqsadlaridan biri, kursant o‘zining muvaffaqiyati, intellektual barqarorligini anglagani holda o’quv jarayonini o‘zi samarali qila olishidir. Ta’lim jarayonini tashkillashtirishda bir vaqtning o‘zida 3508 kishilik o’ringa ega bo‘lgan beshta o‘quv binosi, jami 165 ta auditoriyalar, ulardan: 13 ta ma’ruza sinfi, 14 ta maxsus sinf, 74 ta o‘quv sinfi, 13 ta kompyuter sinfi, 11 ta mavzuviy sinf, 4 ta lingafon sinfi, 2 ta internet sinfi, 5 ta trenajyor sinfi, 6 ta laboratoriya sinfi va 3 ta uslubiy xonalari. Hamda 17 ta harbiy o‘yinlarni o‘tkazish sinfi, 2 ta jangovar tir, 1 ta lazertag harbiy o‘yinlar majmuasi. Shuningdek, yopiq va ochiq turdagi sport majmualari, suzish havzasi, badiiy va elektron kutubxonalar (Axborot-resurs markazi) dan iborat.</p>
            <br/>
            <h4>KURSANTLAR YOTOQXONASI</h4>
            <br/>
            <p>Shinam yotoqxona kursantlarning yangi sharoitlarga tez moslashuvining muhim omillaridan biridir. Kursantlar bir necha yil davomida u yerda yashashlari va o‘qishlari kerak bo‘ladi. Akademiyada to‘rtta yangi, keng va shinam kursantlar yotoqxonasi qurilgan bolib, ularda qulay yashash uchun barcha sharoitlar yaratilgan: to‘shaklari bilan ikkita qulay krovat, ikkita ish stoli, shkaflar. Har bir qavatda Internet tarmog‘iga to‘liq ulangan o‘qish xonalari, zamonaviy keng dushxona, hojatxona va yuvinish xonalari mavjud. Yotoqxonalarda kursantlar uchun kir yuvish xonasi, shuningdek, atelye mavjud.</p>
            <br/>
            <h4 className='text-center'>DALA O‘QUV BAZASI</h4>
            <br/>
            <p>Amaliyot – bo‘lg‘usi mutaxassisni kasbiy tayyorlash tizimining muhim qismidir. Akademiyaning dala o‘quv maydoni Chirchiq shahrida joylashgan bo‘lib, umumiy maydoni 3310,5 gektarni tashkil qiladi.  Dala o‘quv maydoni (poligon) o‘quv rejalari va dasturlari bilan belgilangan barcha harbiy va maxsus-harbiy fanlardan amaliy mashg‘ulotlar tashkillashtirish va me’yorlarni bajarish uchun zarur bo‘lgan quyidagi elementlardan iborat:</p>
            <p>-taktik o‘quv maydoni; o‘quv chegara posti; havo-desant shaharchasi; tog‘ tayyorgarligi shaharchasi; otish shaharchasi (200 metrli tir, 100 metrli tir, guruh qurollaridan otish yo‘nalishi, o‘qotar qurollaridan otish yo‘nalishi, havo nishonlariga otish yo‘nalishi, BTR-80 va BMP-2 direktrisalari, Tank otish shaharchasi, markaziy minora);</p>
            <p>-avtomobillar haydash shaharchasi (avtodrom);</p>
            <p>-jangovar texnikalar xaydash shaharchasi (tankodrom);</p>
            <p>-muhandislik va kimyo shaharchalari;</p>
            <p>-to‘siqli yo‘laklar.</p>
            <p>Dala o‘quv maydoni o‘quv obyektlarida yilning barcha fasllarida, tunda va kundizi 500 nafardan ortiq harbiy xizmatchilar bilan amaliy o‘quv mashg‘ulotlarini tashkillashtirish imkoniyatiga ega.</p>
            <p>Dala bazasi – ta’lim jarayonini dala sharoitida tashkil qilish uchun mo‘ljallangan maskan bo‘lib, zamonaviy qulayliklarga ega o‘quv-moddiy baza barcha mutaxassislik kursantlariga amaliy mashg‘ulotlarda shug’ullanish imkonini beradi. Eng yangi infratuzilma, shinam yotoqxona sifatli ta’lim olish uchun zarur bo‘lgan barcha shart-sharoitlarni yaratmoqda. O’quv bazasida harbiy texnika parki, o‘quv xonalari, yotoqxonalar, ovqatlanish xonasi, sport zali, tibbiyot punkti va madaniyat markazi mavjud.</p>
            <br/>
            <h4 className='text-center'>JISMONIY TARBIYA VA SPORT</h4>
            <p>Akademiyada jismoniy tarbiya va sport bilan shug‘ullanish uchun zarur moddiy-texnik,  tashkiliy va pedagogik sharoitlar yaratildi. Akademiyada zamonaviy stadion, suzish havzasi, trenajor zali, sport zali va skalodrom mavjud. Sport inshootimiz barcha zamonaviy talablarga javob beradi. Akademiyada 15 dan ortiq sport turlari mavjud. Sizga jamoaviy va yakka sport turlari, O‘zbekistonning yetakchi sportchilarining mahorat darslari, jismoniy tarbiya kurslari va jismoniy tayyorgarlik testlarini o‘z ichiga olgan keng qamrovli sport dasturini taklif etamiz. Dastur kursantlarni ishonch, hissiy nazorat, jismoniy chidamlilik va o‘ta og‘ir sharoitlarda harakat qilish qobiliyatini mustahkamlash orqali Qurolli Kuchlarda xizmat qilishga tayyorlashga yordam beradi.</p>
            <br/>
            <h4 className='text-center'>OSHXONA</h4>
            <br/>
            <p>Akademiya oshxonasi taomlarning xilma-xilligi, ularni tayyorlash va pishirish sifati bilan har qanday xo‘randani, mijozni xursand qilishi mumkin. Bu yerda siz nafaqat milliy taomlarni, balki boshqa millat taomlarini ham tatib ko‘rishingiz mumkin.</p>
            <p>Kursantlarning ovqatlanishi asosiy oziq-ovqat va biologik faol moddalarga bo‘lgan ehtiyoj ortib borayotganini e’tiborga olib, o‘sayotgan organizmning xususiyatlarini hisobga olgan holda tashkil etiladi.</p>
            <p>Kursantning ratsioniga go‘sht (mol go‘shti, qo‘zichoq, tovuq, baliq), kolbasa mahsulotlari, yorma (guruch, bug‘doy yormasi, arpa yormasi, grechka, jo‘xori), sut mahsulotlari (sariyog‘, kefir, sut), makaron va non mahsulotlari, sabzavotlar, yangi mevalar, asal, shakar, choy, sharbatlar, kakao, qahva kabilar kiradi.</p>
            <p>Taomlar uzatilishi har xil bo‘lib, – ertalab kursantlar bo‘tqa, kechqurun esa ovqat hazm qilish uchun sabzavotli yengil ovqatlar tanavvul qiladilar. Kundalik ovqatlanish kaloriyasi miqdori 4000 Kkal dan ortiq.</p>
            <br/>
            <h4 className='text-center'>XALQARO HAMKORLIK</h4>
            <br/>
            <p>O‘zbekiston Respublikasi Qurolli kuchlari Akademiyasida xorijiy davlatlarning oliy harbiy ta’lim muassasalari bilan me’yoriy-huquqiy bazani kengaytirish doirasida 5 ta o‘zaro anglashuv memorandumi (Belarus, Koreya Respublika, Xitoy Xalq Respublikasi, Qozog‘iston, Xindiston, AQSh) hamda “Belarus Harbiy akademiyasi” ta’lim muassasasi va Rossiya Bosh shtabi Harbiy Akademiyasi mutaxassislarining ta’lim sohasida xizmat ko‘rsatishlarini nazarda tutuvchi Qurolli kuchlar Akademiyasi ilmiy-pedagogik kadrlar malakasini oshirishga qaratilgan shartnomalar imzolangan. Shu bilan birga halqaro tashkilotlar ShHT Mintaqaviy terrorizmga qarshi tuzilmasi, Mustaqil davlatlar hamdo‘stligi, Xalqaro Qizil Xoch Qo‘mitasi, Yevropadagi Xavfsizlik va Hamkorlik tashkiloti, Shimoliy Atlantika Hamkorlik tashkiloti, Turkiya hamkorlik va muvofiqlashtirish agentligi va Britaniya kengashi bilan hamkorlik yo‘lga qo‘yilgan.</p>
            <p>Xalqaro hamkorlik bo‘yicha amaldagi me’yoriy-huquqiy baza 23 ta me’yoriy-huquqiy hujjatlar (memorandum, maqsad xatlari, deklaratsiya, kelushuv, bayonnoma va shartnoma) mavjud.</p>
            <p>Qurolli kuchlar Akademiyasi 32 ta oliy harbiy ta’lim muassasalari va horijiy davlatlarning ilmiy markazlari bilan xamkorlik qilmoqda (Belarus, Buyuk Britaniya, Germaniya, Italiya, Hindiston, Qozog‘iston, Xitoy Xalq Respublikasi, Qirg‘iziston, Koreya Respublikasi, Pokiston, Rossiya, AQSh, Tojikiston, Turkiya, Fransiya.)</p>

            <p>Akademiyada 12 ta halqaro ilmiy konferensiya tashkil etilgan.</p>
            <p>Tajriba almashish va ilg‘or uslubiyotlarni o‘rganish hamda professor-o‘qtuvchilar tarkibini malakasini oshirish maqsadida 2017-yildan 2021-yilga qadar Qurolli kuchlar Akademiyasi delegatsiyalarini xorijiy davlatlar (Belarus, Buyuk Britaniya, Germaniya, Italiya, Hindiston, Qozog‘iston, Xitoy Xalq Respublikasi, Qirg‘iziston, Koreya Respublikasi, Pokiston, Rossiya, AQSh, Turkiya, Fransiya) oliy harbiy ta’lim muassasasi va Ilmiy-tadqiqot institutlariga tashrif buyurishi yo‘lga qo‘yilgan.</p>
            <br/>

            <h4 className='text-center'>AKADEMIYA RAHBARIYATI</h4>
            <br/>
            <p>Qurolli Kuchlar Akademiyasi boshlig‘i</p>
            <p>polkovnik Babur Ahmadjanovich SHAYAKUBOV</p>
            <br/>
            <p>Akademiya boshlig‘ining birinchi o‘rinbosari</p>
            <p>polkovnik Muxsinali Maxamadaliyevich XONSAIDOV</p>
            <br/>
            <p>Akademiya boshlig‘ining o‘quv ishlar bo‘yicha o‘rinbosari</p>
            <p>polkovnik Akmaljon Rasulovich QODIROV</p>
            <br/>
            <p>Akademiya boshlig‘ining innovatsiya va ilmiy ishlar bo‘yicha o‘rinbosari</p>
            <p>polkovnik Rustamjon Sadriddinovich SAMAROV</p>
            <br/>
            <p>Akademiya boshlig‘ining tarbiyaviy va mafkuraviy ishlar bo‘yicha o‘rinbosari</p>
            <p>polkovnik Alisher Ashrafovich ERGASHEV</p>
            <br/>
            <p>Akademiya boshlig‘ining moddiy-texnik ta’minot bo‘yicha o‘rinbosari</p>
            <p>polkovnik Abdulxamid Ilmiddinovich ALOBIDDINOV</p>
            <br/>
            <h4 className='text-center'>AKADEMIYA BILAN BOG‘LANISH</h4>
            <br/>
            <p>Manzil: O’zbekiston Respublikasi Toshkent shahar Mirzo-Ulug‘bek tumani</p>
            <p>Parkent ko‘chasi 2-uy. Mo‘ljal: “Pushkin” metro bekati.</p>
            <p>Telefon (71)289-97-25, pochta manzili: academy@mil.uz.</p>
            <p>Mas’ul shaxs mayor Begmatov Dilshod Dilmuratovich  12-47, (71)289-97-25</p>
            <br/>






        </div>
    );
}

export default Akademiya;