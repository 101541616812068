import React, {useEffect, useRef, useState} from 'react';
import "./Batafsil.scss"
import eyeIcon from "./assets/eye-icon.png"
import axios from "axios";
import HTMLReactParser from "html-react-parser";
import Landing from "../Landing/Landing";
import Loading from "../Loading/Loading";
import {useSelector} from "react-redux";
import AuthUser from "../Auth/AuthUser";
import {Form, Input, Modal} from "antd";
import {Button} from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import {toast} from "react-toastify";
import {ReloadOutlined} from "@ant-design/icons";

function Batafsil(props) {
    const {http} = AuthUser()
    const [data, setData] = useState({})
    // const [language, setLanguage] = useState()
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState();
    const [captchaCode, setCaptchaCode] = useState();
    const [captchaImage, setCaptchaImage] = useState('');
    const [name, setName] = useState('')
    const [comment, setComment] = useState('')
    const [email, setEmail] = useState('')
    const [modalComment, setModalComment] = useState(false)
    const [captchaText, setCaptchaText] = useState()
    const [captchaToken, setCaptchaToken] = useState(null)

    const {fontSize} = useSelector(state => state.special)
    const {language} = useSelector(state => state.language)
    // console.log(language)


    // console.log(props.match.params.id)
    useEffect(() => {
        window.scrollTo(0, 0); // Sahifa yuqoriga o'tkazish
        // setLanguage(localStorage.getItem('language'))
        // console.log(language)

        http.get(`user/post/content/${props.match.params.id}`, {
            params: {
                lang: language
            }
        })
            .then(res => {
                // console.log(res.data.object.commentList)
                setData(res.data.object)
                setComments(res.data.object.commentList)
                setLoading(false)
            }).catch(e => {
            setLoading(false)
            e.error("Bog'lanishda xatolik!")
        })
    }, [props.match.params.id, language]);

    const onFinish = (event, comment) => {
        // console.log("ishladi")
        http.get(`user/captcha/generate`)
            .then((res) => {
                // console.log(res)
                setCaptchaImage(res.data.object.captchaImage);
                setCaptchaCode(res.data.object.captchaCode);
            })
            .catch((e) => {
                // console.log(e)
            })


        // let obj = {
        //     ...comment,
        //     postInfoId: `${props.match.params.id}`
        // };
        //
        // http.post(`user/post/comment`, obj)
        //     .then((res) => {
        //         // console.log(res)
        //         // formRef.current && formRef.current.reset();
        //     }).catch((e) => {
        //     // console.log(e)
        // })


        // dispatch(addStudent(obj, closeAddModalFunction));
        // formRef.current && formRef.current.reset();
        // console.log(obj)
    };

    const onNewImage = (event, comment) => {
        // console.log("ishladi")
        http.delete(`user/captcha/delete/${captchaCode}`)
            .then((res) => {
                // console.log(res)
                setCaptchaImage(res.data.object.captchaImage);
                setCaptchaCode(res.data.object.captchaCode);
            })
            .catch((e) => {
                // console.log(e)
            })
    };


    if (captchaToken !== null) {
        const handleOk2 = () => {
            http.post(`user/post/comment`, {
                username: name,
                commentText: comment,
                email: email,
                postInfoId: props.match.params.id,
                captchaToken: captchaToken
                // captchaDto:{
                //     captchaCode: captchaCode,
                //     captchaText: captchaText,
                // }
            })
                .then((res) => {
                    toast.success(res.data.message)
                    setCaptchaToken(null)
                    setCaptchaImage("")
                    formRef.current.resetFields();
                    formRef.current && formRef.current.reset();
                }).catch((e) => {
                toast.error(e?.response?.data.message)
                setCaptchaImage("")
                setCaptchaToken(null)
                // console.log(e)
            })
        }
        handleOk2()
    }

    const handleOk = () => {
        // console.log("ishladi")
        http.post(`user/captcha/check`, {
            captchaCode: captchaCode,
            captchaText: captchaText,
        })
            .then((res) => {
                // toast.success(res.data.message)
                if (res.data?.success === true) {
                    setCaptchaToken(res.data.object)
                }

                // setCaptchaImage("")
                // formRef.current.resetFields();
                // formRef.current && formRef.current.reset();
            }).catch((e) => {
            toast.error(e.response.data.message)
            setCaptchaImage("")
            // console.log(e)
        })
    }
    const formRef = useRef()
    const handleCancel = () => {
        setCaptchaImage("")
    }


    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    const [imageStyle, setImageStyle] = useState({});

    const {addStyle1} = useSelector(state => state.special)
    const {addStyle2} = useSelector(state => state.special)
    const {addStyle3} = useSelector(state => state.special)
    const {addStyle} = useSelector(state => state.special)
    useEffect(() => {
        if (addStyle1) {
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#9DD1FF",
                color: "black",
                width: "100%",
                filter: 'grayscale(50%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
        if (addStyle2) {
            setImageStyle({
                ...imageStyle,
                color: "white",
                backgroundColor: "#464646",
                width: "100%",
                filter: 'grayscale(70%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
        if (addStyle3) {
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '0';
            }
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#1c1c1c",
                color: "white",
                width: "100%",
                filter: 'grayscale(100%)',
                // Add additional styles or modifications here
            });
        }
        if (addStyle) {
            setImageStyle({
                color: "black"
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
    }, [addStyle1, addStyle2, addStyle3, addStyle])


    if (loading) {
        return (
            <Loading/>
        )
    }
    const customStyle = {
        // color: 'blue',
        fontSize: `${16 + fontSize}px`,
        // Boshqa stil tarkibini qo'shishingiz mumkin
    };


    return (
        <div style={imageStyle}>
            <div className="container PageBatafsil">
                <div className="newDate">
                    <img src={require('./assets/calendar.jpg')} alt=""/>
                    <p>{data?.publicationTime}</p>
                    <div className="numberOfReads">
                        <img src={eyeIcon} alt="" className="blue-eye"/>
                        <p>{data?.totalViews}</p>
                    </div>
                </div>
                <h2 style={{fontSize: `${24 + Number(fontSize)}px`,}}>{data?.title}</h2>
                <div className="content" style={{fontSize: `${24 + Number(fontSize)}px`}}>
                    {HTMLReactParser(data.content || "", {
                        replace: (domNode) => {
                            if (domNode.name === 'p') {
                                // Iltimos, biz "customStyle" stilini qo'shgan holatda ishlatamiz
                                domNode.attribs.style = `color: ${customStyle.color}; font-size: ${customStyle.fontSize};`;
                            }
                            return domNode;
                        },
                    })}

                    {/*{HTMLReactParser(data.content || "")}*/}
                </div>
                {
                    captchaImage && (
                        <Modal
                            title="Tekshirish"
                            open={captchaImage}
                            width={300}
                            onOk={handleOk}
                            // confirmLoading={confirmLoading}
                            onCancel={handleCancel}
                            // okText={"Jo'natish"}
                            // onCancelText={"Qaytish"}
                            footer={[
                                <Button onClick={onNewImage}
                                        style={{
                                            background: "#FF6C37",
                                            marginRight: "5px",
                                            fontSize: "12px",
                                            padding: "5px",
                                            borderColor: "#FF6C37",
                                            color: "white"
                                        }}
                                >
                                    <ReloadOutlined style={{fontSize: '15px', padding: "3px"}}/>
                                </Button>,
                                <Button
                                    onClick={handleOk}
                                    style={{
                                        background: "#0F285B",
                                        borderColor: "#0F285B",
                                        fontSize: "12px",
                                        padding: "5px",
                                        color: "white"
                                    }}
                                >
                                    Jo'natish
                                </Button>,
                            ]}
                        >
                            <img src={captchaImage} width={"100%"} alt=""/>
                            <Input
                                onChange={({target}) => setCaptchaText(target.value)}
                                placeholder={'Tepadagi yozuvni kiriting'}
                            />
                        </Modal>
                    )
                }

                <div>
                    <h5>Izoh qoldiring</h5>
                    <div>
                        <div>
                            <Form
                                ref={formRef}
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    maxWidth: 800,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={<span style={{fontSize: '16px', fontWeight: "600"}}>Ismingiz</span>}
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ismingizni kiriting',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={({target}) => setName(target.value)}
                                        placeholder={'Ismingizni kiriting'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<span style={{fontSize: '16px', fontWeight: "600"}}>Izoh</span>}
                                    name="comment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Izohni kiriting',
                                        },
                                    ]}
                                >
                                    {/*<Input*/}
                                    {/*    onChange={({target}) => setComment(target.value)}*/}
                                    {/*    placeholder={'Izoh kiriting'}*/}
                                    {/*/>*/}
                                    <TextArea
                                        placeholder="Izohni kiriting" allowClear
                                        onChange={({target}) => setComment(target.value)}/>
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label={<span style={{fontSize: '16px', fontWeight: "600"}}>E-mail</span>}
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Bu yerga E-mail kiriting!',
                                        },
                                        {
                                            required: true,
                                            message: 'Emailni kiriting',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={({target}) => setEmail(target.value)}
                                        placeholder={'Email kiriting'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        width: '100%',
                                        // border:'1px solid red',
                                        display: "flex",
                                        justifyContent: 'flex-end'
                                    }}
                                    wrapperCol={{
                                        // offset: 8,
                                        // span: 16,
                                    }}
                                >
                                    <Button type="primary"
                                            htmlType="submit"
                                            style={{background: "#0F285B", borderColor: "#0F285B", color: "white"}}
                                            size={"small"}>
                                        Izohni yuborish
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                    <hr/>

                    <h5 className="text-center">Izohlar</h5>
                    {
                        comments?.map((item, index) => (
                            <div className='comment'>
                                <div className='comment-title'>
                                    <p>{item.username}</p>
                                    <p>{item.time}</p>
                                </div>
                                <div className='comment-text'>
                                    <p className='m-0'>{item.commentText}</p>
                                </div>
                                <br/>

                            </div>
                        ))
                    }
                </div>
            </div>


        </div>


    );
}

export default Batafsil;