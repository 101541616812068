import React, {useState} from 'react';
import Uzbekistan from '@svg-maps/uzbekistan'
import {SVGMap} from 'react-svg-map'
import 'react-svg-map/lib/index.css'
import './UzbekistonMap.scss'
import {CheckboxSVGMap } from "react-svg-map"


function UzbekistonMap(props) {
    const [stateName, setStateName] = useState();
    const [info, setInfo] = useState({
        id: "1",
        nomi: "Tashkent_shaxar",
        nomi1: "Tashkent shaxar",
        tel: "071-283-83-58; 071-256-15-21"
    });
    const [pointedLocation, setPointedLocation] = useState(null)


    const mapInfo = [
        {
            id: "1",
            nomi: "Andijan",
            nomi1: "Andijon viloyati",
            tel: "074-228-45-02; 228-46-93",

        },
        {
            id: "2",
            nomi: "Bukhara",
            nomi1: "Buxoro viloyati",
            tel: "065-223-20-17"

        },
        {
            id: "3",
            nomi: "Fergana",
            nomi1: "Farg'ona viloyati",
            tel: "073-244-42-47; 073-244-42-43"

        },
        {
            id: "4",
            nomi: "Jizzakh",
            nomi1: "Jizzah shaxar",
            tel: "072-223-80-26"
        },
        {
            id: "5",
            nomi: "Namangan",
            nomi1: "Namangan viloyati",
            tel: "069-234-41-10"
        },
        {
            id: "6",
            nomi: "Navoiy",
            nomi1: "Navoiy viloyati",
            tel: "079-224-79-81; 079-224-89-71"
        },
        {
            id: "7",
            nomi: "Qashqadaryo",
            nomi1: "Qashqadaryo viloyati",
            tel: "079-224-79-81; 079-224-89-71"
        },
        {
            id: "8",
            nomi: "Karakalpakstan",
            nomi1: "Qoraqalpog‘iston Respublikasi",
            tel: "061-222-24-51; 222-89-32"
        },
        {
            id: "9",
            nomi: "Samarqand",
            nomi1: "Samarqand viloyati",
            tel: "066-233-53-15"
        },
        {
            id: "10",
            nomi: "Sirdaryo",
            nomi1: "Sirdaryo viloyati",
            tel: "067-225-10-53"
        },
        {
            id: "11",
            nomi: "Surxondaryo",
            nomi1: "Surxondaryo viloyati",
            tel: "076-224-44-71"
        },
        {
            id: "12",
            nomi: "Tashkent",
            nomi1: "Tashkent shaxar",
            tel: "071-283-83-58; 071-256-15-21"
        },
        {
            id: "13",
            nomi: "Tashkent_vil",
            nomi1: "Tashkent viloyati",
            tel: "071-283-84-38"
        },
        {
            id: "14",
            nomi: "Xorazm",
            nomi1: "Xorazm viloyati",
            tel: "062-225-40-95; 225-37-66"
        },
    ];
    const getLocationName = (event) =>{
        return event.target.attributes.name.value;
    }
    const handleLocationMove = (event) => {
        const pointedLocation = getLocationName(event)
        setPointedLocation(pointedLocation)

    }

    const onLocationClick = (event) => {
        mapInfo.map((item, index)=>(
            item.nomi === event.target.getAttribute("name") ? setInfo(item) : "zdg",
            event.target.getAttribute("d").slice(0, 5)=== "m 705" ? setInfo({
                id: "13",
                nomi: "Tashkent_vil",
                nomi1: "Tashkent viloyati",
                tel: "071-283-84-38"
            }) : ""
        ))
    };


    return (

        <div className="uzbekistan-map container mt-5">
            <h2 className="title">Hududiy Mudofaa ishlar bo'limi</h2>
            <div className="row">
                <div className="col-md-9">
                    <SVGMap
                        // onLocationMouseOver={handleLocationMove}
                        className="mapuzb" onLocationClick={onLocationClick} map={Uzbekistan} />
                </div>
                <div className="col-md-3 d-flex align-items-start my-5">
                        <div className="card w-100">
                            <div className="card-header">
                                {/*<h5 key={info.id}>{info.nomi1}</h5>*/}
                                <h5 >{info.nomi1}</h5>
                            </div>
                            <div className="card-body">

                                {/*<h5 key={info.id}>{info.nomi1=== "Tashkent shaxar" ? "Shaxar MIB": "Viloyat MIB"} </h5>*/}
                                <h5>{info.nomi1=== "Tashkent shaxar" ? "Shaxar MIB": "Viloyat MIB"} </h5>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-1">Tel: </h5>
                                    <p> {info.tel}</p>
                                </div>
                            </div>

                        </div>

                </div>

            </div>
        </div>
)
    ;
}

export default UzbekistonMap;