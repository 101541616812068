import React from 'react';
import "./Bushish.scss"
import {Link} from "react-router-dom";

function Bushish(props) {
    return (
        <div className="vacancies">
            <div className="container">
                <p className="mt-2" style={{color: "#989898"}}>Bosh sahifa > Bo'sh ish o'rinlari</p>
                <h4 className="fw-bold">BO`SH ISH O`RINLARI</h4>
                <div className="row">
                    <div className="col-md-6">
                        <Link to="bush-ish-galaba">
                            <div className="vacanciesBox">
                                <h4>
                                    “G‘alaba bog‘i” yodgorlik majmuasi vakant lavozimlar ro‘yxatini e’lon qildi
                                </h4>
                            </div>
                        </Link>

                    </div>
                    <div className="col-md-6">
                        <Link to="mv-bush-ish">
                            <div className="vacanciesBox2">
                                <h4>
                                    Bo'sh ish o'rinlari
                                </h4>
                            </div>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Bushish;