import React from 'react';
import "./OchiqMalumot.scss"
import {Link} from "react-router-dom";

function OchiqMalumot(props) {
    return (
        <div className="openData mt-5 mb-5">
            <div className="container">
                <div className="row">

                    <div className="col-md-4 mb-3">
                        <Link to="teg-mur" style={{textDecoration:"none"}}>
                            <div className="card shadow">
                                <div className="card-body">
                                    <h6 >Tegishli murojaatlar boʻyicha masʼul...</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Link to="jam-ken-yig" style={{textDecoration:"none"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                    <h6 >Jamoatchilik kengashi yig‘ilishida ko‘rib...</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Link to="jam-ken" style={{textDecoration:"none"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                    <h6 >Jamoatchilik kengashi</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Link to="mv-murojat" style={{textDecoration:"none"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                    <h6 >Mudofaa vazirligiga 2023-yilning dastlabki...</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Link to="mv-markaziy" style={{textDecoration:"none"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                    <h6 >Mudofaa vazirligi markaziy apparati...</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Link to="vil-mib" style={{textDecoration:"none"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                    <h6>Viloyatlar Mudofaa ishlari boshqarmalari...</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Link to="rahbaryat-ochiq" style={{textDecoration:"none"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                    <h6>Rahbariyat</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Link to="oz-res-mv" style={{textDecoration:"none"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                    <h6>O‘zbekiston Respublikasi Mudofaa vazirligi...</h6>
                                    <p style={{color: "rgba(62,62,63,0.66)", fontSize:"14px"}}>09.09.2023</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default OchiqMalumot;