export const Ru= {
    HELLO: 'Привет, мир',
    SEARCH: '...Lorem ipsum dolor sit amet.',
    ABOUT_THE_MINISTRY: 'O министерстве',
    NEWS: 'Новости',
    LEGISLATION: 'Законодательство',
    OPEN_DATA: 'Открытые данные',
    CONTACT: "Контакт",
    DEFENSE: "ОБОРОНЫ",
    MINISTRY: "МИНИСТЕРСТВО",
    WELCOME: "добро пожаловать на официальный сайт",
    LATEST_NEWS: "Последние новости",
    POPULAR_POSTS: "Самые читаемые",
    READ_MORE: "ПРОСМОТРЕТ БОЛЬШЕЕ",
    MORE: "Подробно...",
    VIDEOS: "Видео",


    MANAGEMENT: "Руководство",
    FUNCTION_MINISTRY:"История Вооружённых Сил",
    HISTORY_ARMED: "Основные функции и задачи Министерства обороны",
    VACANCIES: "Ваканции"


}