import React, {useEffect, useState} from 'react';
import "./YoutubeVideos.scss"
import ReactPlayer from "react-player";
import {getText} from "../Translation";
import {useDispatch, useSelector} from "react-redux";
import {getVideos, getVideosAll, getYoutubeVideo} from "../../redux/actions/youtubeAction";

function YoutubeVideos(props) {


    const dispatch = useDispatch();
    // const API = "AIzaSyAFNtyUOCQsCmuRn2q000TcJJmaLE10UqI";
    const API = "AIzaSyAI-jHysNzJuClReHYhpvR3xSyOs3wiDqQ";
    // const API = "AIzaSyDyWzNobYbEFCWcZtke-wjFUta23pBysf8";
    const channelId = "UCf1wcRodR_vlHKWVH8n8pvA";

    var fetchurl = `https://www.googleapis.com/youtube/v3/search?key=${API}&channelId=${channelId}&part=snippet,id&order=date&maxResults=10`;
    const [allvideos, setAllvideos] = useState([]);
    const [video, setVideo] = useState({});

    const {youtubeGet} = useSelector(state => state.youtube)
    const {allVideos} = useSelector(state => state.youtube)
    const {videos} = useSelector(state => state.youtube)


    // console.log(allVideos)
    // console.log(videos)
    const getYoutubeVideos = ()=>{
        fetch(fetchurl).then((response) => response.json()).then((resJson) => {
            const result = resJson?.items.map(doc => ({
                ...doc,
                Videolink: "https://www.youtube.com/embed/" + doc.id.videoId
            }));
            setAllvideos(result);
            setVideo(result[0])
            dispatch(getVideosAll(result));
            dispatch(getVideos(result[0]));


        }).catch((err) => {
            console.log(err.message)
        })
    }
    useEffect(() => {
        if(youtubeGet === true){
            getYoutubeVideos()
        }
        setAllvideos(allVideos)
        setVideo(videos)

    }, []);

    // console.log(video);
    const [isPlaying, setIsPlaying] = useState(false); // Video o'ynash holati

    const playVideo = () => {
        setIsPlaying(!isPlaying); // Bu qator qo'shildi
    };



    return (
        <div className="Youtube-video">
            <div className="container ">
                <div className="d-flex align-items-center mt-1 mb-3">
                    <div className="text-title"><h4>{getText('VIDEOS')}</h4></div>
                    <div className="div-title flex-grow-1"></div>
                </div>
                <div className="row box" style={{boxSizing: "border-box", height: "100%"}}>
                    <div className="col-md-8" style={{boxSizing: "border-box"}}>
                        <ReactPlayer // Bu qator qo'shildi
                            width="100%"
                            height="92%"
                            url={video.Videolink}
                            playing={isPlaying}
                            controls
                        />

                        <div className="d-flex youtube-play" style={{backgroundColor: "#4952A3"}}>
                            {
                                isPlaying ? <button className="btn ms-1" style={{background:"#4952A3", border: "none"}} onClick={playVideo}><img src={require("./assets/kindpng_5528527.png")}  alt="" style={{height: "20px", width: "15px"}}/></button> :
                                    <button className="btn  ms-1" style={{background:"#4952A3", border: "none"}} onClick={playVideo}><img src={require("./assets/kindpng_2451557.png")} alt="" style={{height: "20px", width: "15px"}}/></button>
                            }
                            <p style={{
                                backgroundColor: "#4952A3",
                                margin: "2px",
                                padding: "5px 10px",
                                color: "white",
                                fontSize: "15px"
                            }}>{video.snippet?.title.replace(/&#39;/g, "'").replace(/&quot;/g, '"')}</p>

                        </div>

                        {/*<iframe width="100%"*/}
                        {/*        height="92%"*/}
                        {/*        src={video.Videolink}*/}
                        {/*        title="YouTube video player" frameBorder="0"*/}
                        {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                        {/*        allowFullScreen*/}

                        {/*/>*/}
                        {/*<p style={{*/}
                        {/*    backgroundColor: "#4952A3",*/}
                        {/*    margin: "2px",*/}
                        {/*    padding: "5px 10px",*/}
                        {/*    color: "white",*/}
                        {/*    fontSize: "15px"*/}
                        {/*}}>{video.snippet?.title.replace(/&#39;/g, "'").replace(/&quot;/g, '"')}</p>*/}

                    </div>

                    <div className="col-md-4">
                        <div className="list-Video" style={{height: "550px"}}>
                            {
                                allvideos?.map((item, index) => (
                                    <span key={index} className="d-flex title" onClick={() => setVideo(item)}>
                                <img src={item.snippet?.thumbnails.high.url} alt=""/>
                                <p>{item.snippet?.title.replace(/&#39;/g, "'").replace(/&quot;/g, '"')}</p>
                            </span>


                                ))
                            }

                        </div>
                    </div>


                </div>

            </div>

        </div>


    );
}

export default YoutubeVideos;