import React from 'react';

function HaqidaHm(props) {
    return (
        <div className='container'>
            <h4 className='text-center fw-bold'>Haqida</h4>
            <br/>

            <p>1995-yilda O‘zbekiston Respublikasi Qurolli Kuchlari Akademiyasi tashkil etilishi bilan ta’lim muassasasi tarkibida ilmiy-tadqiqot ishlari bilan sho‘g‘ullanuvchi  ilmiy bo‘lim sifatida faoliyat ko‘rsatgan. O‘zbekiston Respublikasi Prezidentining 2010-yil 4-avgustdagi “O‘zbekiston Respublikasi Qurolli Kuchlari Akademiyasini isloh qilish chora-tadbirlari to‘g‘risida”gi PQ-1384-son Farmonida belgilangan vazifalarni amalga oshirish doirasida ilmiy-tadqiqot bo‘limi Harbiy ilmiy tadqiqotlar markaziga o‘zgartirildi.</p>

            <p>Keyinchalik, O‘zbekiston Respublikasi Prezidenti Administratsiyasining 2019-yil 12-fevraldagi topshirig‘iga binoan, shuningdek, O‘zbekiston Respublikasi Mudofaa vazirining 22-fevraldagi 141-son buyrug‘i talablari asosida, 2019-yildan Akademiyaning Harbiy ilmiy tadqiqotlar markazi O‘zbekiston Respublikasi Qurolli Kuchlari Akademiyasi ilmiy-tadqiqot institutiga aylantirildi.</p>
            <p>Institut faoliyatini tartibga solish va tashkil etish maqsadida O‘zbekiston Respublikasi Qurolli Kuchlari akademiyasi boshlig‘ining buyrug‘i bilan ilmiy-tadqiqot instituti to‘g‘risidagi nizom ishlab chiqildi va tasdiqlandi. 2019-yil 1-iyuldan institut yangi tarkibda o‘z faoliyatini boshladi.</p>
            <p>Mazkur Nizomga muvofiq, institut davlatning harbiy xavfsizligini ta’minlash, Qurolli Kuchlarni yanada rivojlantirish va ulardan foydalanish sohasida qabul qilingan qarorlarni ilmiy asoslash maqsadida harbiy san’at nazariyasi va amaliyoti qoidalari, shuningdek, harbiy ilmiy ishlarni (asarlarini) ishlab chiqish va nazariy tadqiqotlar natijalari va amaliy ishlanmalarni qo‘shinlarning kundalik faoliyatiga integratsiya qilish bo‘yicha kompleks tavsiyalar berish, ilmiy-tadqiqot ishlarini olib borishga mo‘ljallangan ilmiy-tadqiqot muassasasi hisoblanadi.</p>
            <p>O‘zbekiston Respublikasi Prezidentining 2022-yil 1-dekabrdagi “O‘zbekiston Respublikasi Qurolli Kuchlari tizimida harbiy ilm-fanni rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ-29-sonli Qaroriga asosan QK Akademiyasining Ilmiy-tadqiqotlar instituti negizida O‘zbekiston Respublikasi Qurolli Kuchlari Harbiy meros va zamonaviy tadqiqotlar instituti shakllantirildi.</p>
            <br/>
            <h4>UMUMIY MA’LUMOT</h4>
            <br/>
            <p>O‘zbekiston Respublikasi Qurolli Kuchlari Harbiy meros va zamonaviy tadqiqotlar instituti yuridik shaxs maqomiga ega bo‘lmagan mustaqil ilmiy tashkiloti hisoblanadi va O‘zbekiston Respublikasi Mudofaa vaziriga bo‘ysunadi.</p>
            <p>Institut o‘z faoliyatini belgilangan tartibda respublika va xorijiy davlatlarning yetakchi oliy, oliy harbiy ta’lim muassasalari, ilmiy- tadqiqot markazlari va boshqa ilmiy muassasalar bilan o‘zaro hamkorlikda amalga oshiradi.</p>
            <p>Institut O‘zbekiston Respublikasi Qurolli Kuchlari Akademiyasi hududida Toshkent shahri,  Mirzo-Ulug‘bek tumani, Parkent ko‘chasi, 2-uy (4-o‘quv binosi 1-qavatida) manzili bo‘yicha joylashtiriladi hamda Akademiyaning o‘quv va moddiy-texnika bazasidan bepul foydalanadi.</p>
            <p>Institut o‘z faoliyati jarayonida O‘zbekiston Respublikasi Konstitutsiya va qonunlariga, O‘zbekiston Respublikasi Prezidentining farmonlari, qarorlari va farmoyishlariga, Vazirlar Mahkamasining qarorlari va farmoyishlariga, O‘zbekiston Respublikasi Mudofaa vazirligining buyruq va direktivalari hamda boshqa milliy qonunchilik hujjatlariga amal qiladi.</p>
            <p>Institut O‘zbekiston Respublikasi Mudofaa vazirligi va Qurolli Kuchlarning boshqa tarkibiy bo‘linmalari buyurtmasiga asosan muammolarni ilmiy o‘rganish, tadqiqotlar olib borish va ular asosida muammolarning optimal yechimi bo‘yicha ilmiy-asoslangan takliflarni ishlab chiqish, mazkur tadqiqotlar natijalarini taqdim etish va O‘zbekiston Respublikasi Qurolli Kuchlari faoliyatiga tatbiq qilishda ishtirok etadi.</p>
            <br/>
            <h4>Institut faoliyatining asosiy prinsiplari:</h4>
            <br/>
            <p>“Taraqqiyot yo‘lida tadqiqot”;</p>
            <p>“Ilmiy asoslash va innovatsion yechimlarni topish”;</p>
            <p>“O‘tmish – hozirgi zamon – istiqbolli kelajak”;</p>
            <p>“Buyurtmachi – ijrochi – iste’molchi”.</p>
            <br/>

        </div>
    );
}

export default HaqidaHm;