export const En={
    HELLO:'Hello world',
    SEARCH:'...search',
    ABOUT_THE_MINISTRY: 'About the Ministry',
    NEWS: 'News',
    LEGISLATION: 'Legislation',
    OPEN_DATA: 'Open data',
    CONTACT: "Contact",
    DEFENSE: "DEFENSE",
    MINISTRY: "MINISTRY OF",
    WELCOME: "welcome to the official website",
    LATEST_NEWS: "Latest news",
    POPULAR_POSTS: "Popular posts",
    READ_MORE: "READ MORE",
    MORE: "More...",
    VIDEOS: "Videos",


    MANAGEMENT: "Management",
    FUNCTION_MINISTRY:"Functions and tasks of the Ministry of Defense",
    HISTORY_ARMED: "History of the Armed Forces",
    VACANCIES: "Vacancies"

};