// import React, { Component } from "react";
import Slider from "react-slick";
import './CarouselHospital.scss'
import React from "react";
import Price from "./assets/Price.pdf"


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block",  position: "absolute",
                right: "0px",
                top: "-15px",
                // border: "1px solid black",
                color:"black",
                zIndex: "1"

            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", position: "absolute", left: "95%", top:"-15px", color:"black",  zIndex: "1",}}
            onClick={onClick}
        />
    );
}
function SamplePrevArrow1(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", position: "absolute", left: "90%", top:"-15px", color:"black",  zIndex: "1",}}
            onClick={onClick}
        />
    );
}
function SamplePrevArrow2(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", position: "absolute", left: "85%", top:"-15px", color:"black",  zIndex: "1",}}
            onClick={onClick}
        />
    );
}

function Fade() {
        const settings = {
            dots: true,
            // fade: true,
            infinite: true,
            // speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3000,
            // centerPadding: "60px",
            cssEase: 'linear',
            // initialSlide: 0,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            appendDots: dots => (
                <div>
                    <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
            ),
            customPaging: i => (
                <div className="activeColor"
                    style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50px",
                        border: "1px #747474 solid",
                        position: "absolute",
                        top: "10px",
                    }}
                >

                </div>
            ),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        prevArrow: <SamplePrevArrow1 />,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        prevArrow: <SamplePrevArrow2 />,
                        dots: false
                    }
                }
            ]
        };
        return (
            <div className="container imgCorousel" style={{position: "relative"}}>

                <div className="d-flex align-items-center my-3">
                    <div className="text-title2"><h4>MUDOFAA VAZIRLIGI MARKAZIY HARBIY GOSPITALIDA KO’RSATILADIDIGAN TIBBIY XIZMATLAR</h4></div>
                    <div className="div-title2 flex-grow-1">
                    </div>
                </div>
                <Slider {...settings} style={{position: "relative"}} >
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="card" >
                            <img className="card-img-top" src={require('./assets/rasm1.jpg')} />
                                <div className="card-body">
                                    <a href={Price} target="._blank"  className="btn btnInfo btn-sm w-100">BATAFSIL</a>
                                </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
}
export default Fade;