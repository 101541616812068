import React, { Component } from "react";
import Slider from "react-slick";
import "./FooterYangilik.scss"

export default class FooterYangilik extends Component {
    render() {
        const settings = {
            className: "slider variable-width",
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear",
            variableWidth: true
        };
        return (
            <div className="newFootCarousel">
                <div className="container">
                    <div className="row align-items-center">
                        {/*<div className="col-2">*/}
                        {/*    <div className="newLeft">*/}
                        {/*        <p>YANGILIKLAR</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-12 ">
                            <div className="newText">
                                <Slider {...settings}>
                                    <div className=" d-flex me-3" style={{ width: 'auto' }}>
                                        <p className="me-3 mb-0 newDate">JANUBIY G'ARBIY MAXSUS HARBIY OKRUGI</p>
                                    </div>
                                    <div className="d-flex me-3 " style={{ width: 'auto' }}>
                                        <p className="me-3 mb-0 newDate">MARKAZIY HARBIY OKRUG</p>
                                    </div>
                                    <div className="d-flex me-3" style={{ width: 'auto' }}>
                                        <p className="me-3 mb-0 newDate">E'LONLAR</p>
                                    </div>
                                    <div className="d-flex me-3" style={{ width: 'auto' }}>
                                        <p className="me-3 mb-0 newDate">YOSHLAR SIYOSATI</p>
                                    </div>
                                    <div className="d-flex me-3" style={{ width: 'auto' }}>
                                        <p className="me-3 mb-0 newDate">ARMIYA SPORTI</p>
                                    </div>
                                    <div className="d-flex me-3" style={{ width: 'auto' }}>
                                        <p className="me-3 mb-0 newDate">HARBIY HAMKORLIK</p>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}