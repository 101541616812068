import React from 'react';
import Slider from "react-slick";
import {useSelector} from "react-redux";
import './TestMode.scss'

function TestMode(props) {
    const {fontSize} = useSelector(state => state.special)
    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        variableWidth: true
    };
    return (

        <div className="test-mode" >
            <div className="container-fluid" >
                <div className="row align-items-center" >
                    {/*<div className="col-2">*/}
                    {/*    <div className="newLeft">*/}
                    {/*        <p>YANGILIKLAR</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-12 " >
                        <div className="newText" >
                            <Slider {...settings}>
                                {/*{*/}
                                {/*    postList?.map((value, index)=>(*/}
                                {/*        <div key={index} className=" d-flex me-3" style={{ width: 'auto'}}>*/}
                                {/*            <p className="me-3 mb-0 newDate" style={{fontSize:`${16 + Number(fontSize) }px`}}>{value.publicationTime}</p>*/}
                                {/*            <p className="m-0 " style={{fontSize:`${16 + Number(fontSize) }px`}}>{value.title}</p>*/}
                                {/*        </div>*/}
                                {/*    ))*/}
                                {/*}*/}

                                <div className="d-flex me-3 " style={{ width: 'auto' }}>
                                    {/*<p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    <p className="m-0" >SAYT TEST REJIMDA ISHLAMOQDA</p>
                                </div>
                                <div className="d-flex me-3" style={{ width: 'auto' }}>
                                    {/*<p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    <p className="m-0" ></p>
                                </div>
                                <div className="d-flex me-3" style={{ width: 'auto' }}>
                                    {/*<p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    <p className="m-0" ></p>
                                </div>
                                <div className="d-flex me-3" style={{ width: 'auto' }}>
                                    {/*<p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    <p className="m-0" ></p>
                                </div>
                                <div className="d-flex me-3" style={{ width: 'auto' }}>
                                    {/*<p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    <p className="m-0" ></p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TestMode;