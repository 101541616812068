import React, {useEffect, useState} from 'react';
import "./CardsNews.scss"
import {Link} from "react-router-dom";
import axios from "axios";
import {Pagination} from "@mui/material";
import {useSelector} from "react-redux";
import AuthUser from "../Auth/AuthUser";
import {getText} from "../Translation";
import {PATH_NAME_AUTH} from "../../tools/constant";

function CardsNews(props) {
    const {http} = AuthUser()
    const [postList, setPostList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, settotalPages] = useState(0)
    const [newsName, setNewsName] = useState('')
    const [numberOdPages, setNumberOfPages] = useState(10)
    const {fontSize} = useSelector(state => state.special)
    const {language} = useSelector(state => state.language)



    useEffect(() => {
        window.scrollTo(0, 0); // Sahifa yuqoriga o'tkazish
        // console.log(language)
        http.get('user/post/list',{
            params:{
                size: 8,
                page: currentPage,
                lang: language,
                postTypeId: 0
            }
        })
            .then(res => {
                // console.log(res.data.object.postComponentList.content)
                // console.log(res.data.object.postTypeName)
                setNewsName(res.data.object.postTypeName)
                setPostList(res.data.object.postComponentList.content)
                setNumberOfPages(res.data.object.pageable.pageSize)
                settotalPages(res.data.object.totalPages)
            }).catch(e => {
            // e.error("Bog'lanishda xatolik!")
            console.log(e.message)
        })
    }, [currentPage, language])

    const handleChangePage = (event, newPage)=>{
        setCurrentPage(newPage-1)
    }


    return (
        <div className="container">
            <div className="cardsNews">
                <h2 className="fw-bold mt-2">{newsName}</h2>
                <div className='cardNews'>
                    <div className="row">
                        {
                            postList?.map((value, index)=>(
                                <div key={index} className="col-md-3 my-2">
                                    <div className="card" style={{width: "100%"}}>
                                        <img className="card-img-top" src={value ? `${PATH_NAME_AUTH}${value.pathPhoto}` : null} alt="Card image"
                                             style={{width: "100%"}}/>
                                        <div className="card-body">
                                        <span className="cardDate">
                                            <img  src={require("./assets/calendar.jpg")} alt=""/>
                                            <p>{value.publicationTime}</p>
                                        </span>
                                            <div className="newCardText">
                                                <p style={{fontSize:`${15 + Number(fontSize) }px`,  lineHeight:`${19 + Number(fontSize) }px`}} className="card-text">{value.title.length > 80 ? value.title.slice(0,80)+'...' : value.title}</p>
                                                <Link to={`/batafsil/${value.postId}`} className="btn">{getText('MORE')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end my-4">
                <Pagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage+1}
                    onChange={handleChangePage}
                />
            </div>



        </div>
    );
}

export default CardsNews;