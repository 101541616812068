import React, { Component } from "react";
import Slider from "react-slick";
import "./NewCarousel.scss"
import {useSelector} from "react-redux";

function NewCorousel({postList, props}) {
    const {fontSize} = useSelector(state => state.special)
        const settings = {
            className: "slider variable-width",
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 5000,
            autoplaySpeed: 2000,
            cssEase: "linear",
            variableWidth: true
        };
        return (
            <div className="newCarousel" >
                <div className="container-fluid" >
                    <div className="row align-items-center" >
                        {/*<div className="col-2">*/}
                        {/*    <div className="newLeft">*/}
                        {/*        <p>YANGILIKLAR</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-12 " >
                            <div className="newText" >
                                <Slider {...settings}>
                                    {
                                        postList?.map((value, index)=>(
                                            <div key={index} className=" d-flex me-3" style={{ width: 'auto'}}>
                                                <p className="me-3 mb-0 newDate" style={{fontSize:`${16 + Number(fontSize) }px`}}>{value.publicationTime}</p>
                                                <p className="m-0 " style={{fontSize:`${16 + Number(fontSize) }px`}}>{value.title}</p>
                                            </div>
                                        ))
                                    }

                                    {/*<div className="d-flex me-3 " style={{ width: 'auto' }}>*/}
                                    {/*    <p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    {/*    <p className="m-0">Maktablarda yangi harbiy-vatanparvarlik sinf xonalari foydalanishga topshirildi</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="d-flex me-3" style={{ width: 'auto' }}>*/}
                                    {/*    <p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    {/*    <p className="m-0">Qurolli Kuchlar akademiyasida bayram tantanasi</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="d-flex me-3" style={{ width: 'auto' }}>*/}
                                    {/*    <p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    {/*    <p className="m-0">Aloqa va axborot texnologiyalari mutaxassislarini tayyorlash davr talabi</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="d-flex me-3" style={{ width: 'auto' }}>*/}
                                    {/*    <p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    {/*    <p className="m-0">Mudofaa vazirligida Konstitutsiya kuni munosib nishonlandi</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="d-flex me-3" style={{ width: 'auto' }}>*/}
                                    {/*    <p className="me-3 mb-0 newDate">08.12.2022</p>*/}
                                    {/*    <p className="m-0">Mudofaa vazirligida Konstitutsiya kuni munosib nishonlandi</p>*/}
                                    {/*</div>*/}
                                </Slider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
}
export default NewCorousel;