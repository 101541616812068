import {
    ADD_STYLE,
    ADD_STYLE1, ADD_STYLE2, ADD_STYLE3,
    FONT_SIZE, LOADING_TRUE
} from "../actionTypes/actionTypes";

const initialState = {

    fontSize: 1,
    addStyle1: false,
    addStyle2: false,
    addStyle3: false,
    addStyle: false,
}


export const specialReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case LOADING_TRUE:
            return {
                ...state,
                loading: true
            };
        case FONT_SIZE:
            return {
                ...state,
                fontSize: payload
            };
        case ADD_STYLE1:
            return {
                ...state,
                addStyle1: true,
                addStyle2: false,
                addStyle3: false,
                addStyle: false,
            };
        case ADD_STYLE2:
            return {
                ...state,
                addStyle2: true,
                addStyle1: false,
                addStyle3: false,
                addStyle: false,
            };
        case ADD_STYLE3:
            return {
                ...state,
                addStyle3: true,
                addStyle1: false,
                addStyle2: false,
                addStyle: false,
            };
        case ADD_STYLE:
            return {
                ...state,
                addStyle1: false,
                addStyle2: false,
                addStyle3: false,
                addStyle: true
            };

        default:
            return state

    }
};