import React from 'react';
import "./Loading.scss"
import logo from "../Landing/assets/Logo.png"

function Loading(props) {
    return (
        <div className="loading container">
            <img src={logo} alt=""/>
        </div>
    );
}

export default Loading;