import React from 'react';
import flafMV from './flag-MV-png.png'

function MvBushIsh(props) {
    return (
        <div className="container">
            <div className="col-8 mt-3">
                <h2>Bo`sh ish o`rinlari</h2>
                <img src={flafMV} width="100%" alt="img"/>
                <br/>
                <br/>
                <p>Mudofaa vazirligiga qarashli Toshkent shahrida joylashgan harbiy qismlarning biriga malakali
                    mutaxassis fuqarolar quyidagi bo‘sh lavozimlarga tanlov asosida ishga qabul qilinadi. </p>
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>T/r</th>
                            <th>Lavozim nomlanishi</th>
                            <th>Lavozim okladi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2</td>
                            <td>Ta’mirlovchi chilangar</td>
                            <td>1 702 000</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Avtomashina bo‘yoqchisi</td>
                            <td>1 702 000</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Elektrogazpayvandchi</td>
                            <td>1 617 000</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Tokar</td>
                            <td>1 674 000</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Frezalovchi</td>
                            <td>1 702 000</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Ichki yonuv dvigatellari mashinisti</td>
                            <td>1 702 000</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Metall konstruktsiyalarini yig‘ish bo‘yicha chilangan</td>
                            <td>1 617 000</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Vukanizatorchi</td>
                            <td>1 702 000</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Akkumulyatorchi</td>
                            <td>1 617 000</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Avtomobil haydovchisi</td>
                            <td>1 875 000</td>
                        </tr>
                    </tbody>

                </table>
                <br/>
                <br/>
                <br/>
                <p><b>Manzil:</b> Toshkent shahri Sergeli tumani Nilufar ko‘chasi 115 uy, indeks – 100155.22418</p>
                <br/>
                <p>Bog‘lanish uchun telefon: <b>(95) 515-71-91</b></p>


            </div>
        </div>
    );
}

export default MvBushIsh;