import React, {useEffect} from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import './HarbiyMeros.scss'
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import Box from "@mui/material/Box";
import RahbariyatHm from "./RahibariyatHM/RahbariyatHM";
import HaqidaHm from "./HaqidaHM/HaqidaHM";
import VazifalariHm from "./VazifalariHM/VazifalariHM";
import BoglanishHM from "./BoglanishHM/BoglanishHM";

function HarbiyMeros(props) {
    const [value, setValue] = React.useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(()=>{
        window.scrollTo(0, 0); // Sahifa yuqoriga o'tkazish
    },[])
    return (
        <div className='harbiy-meros'>
            <div className='container mt-3 '>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="icon label tabs example"
                    textColor="black"
                >
                    {/*<Tab icon={<HomeOutlinedIcon />} label="Bosh Sahifa" iconPosition="start" />*/}
                    <Tab icon={<PersonOutlineOutlinedIcon />} label="Rahbariyat" iconPosition="start" />
                    <Tab icon={<InfoOutlinedIcon />} label="Haqida" iconPosition="start" />
                    <Tab icon={<AssignmentOutlinedIcon />} label="Vazifalari" iconPosition="start" />
                    <Tab icon={<ContactPhoneOutlinedIcon />} label="Bog'lanish" iconPosition="start" />
                </Tabs>

            </div>
            <div>
                {/*<TabPanel value={value} index={0}>*/}
                {/*    <Boshsahifa/>*/}
                {/*</TabPanel>*/}
                <TabPanel value={value} index={0}>
                    <RahbariyatHm/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <HaqidaHm/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <VazifalariHm/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <BoglanishHM/>
                </TabPanel>

            </div>
        </div>
    );
}

export default HarbiyMeros;