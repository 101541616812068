import {En} from "./En";
import {Uz} from "./Uz";
import {Ru} from "./Ru";



export function getText(word){
    if (localStorage.getItem('language') === "en"){
        return En[word];
    }else if(localStorage.getItem('language') === "ru"){
        return Ru[word];
    }else return Uz[word];
}