import React from 'react';

function VazifalariHm(props) {
    return (
        <div className='container'>
            <br/>
            <h4 className='text-center fw-bold'>VAZIFALAR</h4>
            <br/>
            <p>O‘zbekiston Respublikasi Qurolli Kuchlari Harbiy meros va zamonaviy tadqiqotlar instituti mamlaktimizda harbiy ilm-fanning istiqbolli yo‘nalishlarini rivojlantirish uchun ta’sis etilgan bo‘lib, milliy harbiy san’atimizning tarixi bo‘yicha tizimli fundamental va amaliy tadqiqotlar o‘tkazish, harbiy sohadagi xalqaro, mintaqaviy va milliy xavfsizlikka nisbatan zamonaviy xavf va tahdidlarni o‘rganish, milliy Qurolli Kuchlar va harbiy san’atni rivojlantirishning asosiy yo‘nalishlarini ilmiy asoslash, shuningdek, malakali harbiy-ilmiy kadrlarni tayyorlashga mo‘ljallangan.</p>
            <p>Milliy harbiy san’at tarixiga bag‘ishlangan, buyuk ajdodlarimizning boy merosi, ularning boshqaruv va sarkardalik mahorati, strategiya va jang taktikasi bo‘yicha fundamental va amaliy tadqiqotlar o‘tkazish, Jaloliddin Manguberdi, Temur Malik, Amir Temur, Shohrux Mirzo, Mirzo Ulug‘bek, Zahiriddin Muhammad Bobur va boshqa tarixiy sarkardalarimiz qahramonliklarini tizimli taxdil qilish, shuningdek, milliy harbiy san’atimiz namunalarini keng ommalashtirish;</p>
            <p>Xalqaro, mintaqaviy va milliy xavfsizlikka nisbatan harbiy, harbiy-siyosiy va harbiy-iqtisodiy sohadagi xavf va taxdidlarni tadqiq qilish, mamlakatnikg harbiy mudofaasini ta’minlashdagi tizimli muammolarni o‘rganish va ularning optimal yechimini topish hamda davlat mudofaa qobiliyatini oshirish borasida qabul qilinadigan qarorlarni ilmiy asoslash va metodologik qo‘llab-quvvatlash;</p>
            <p>harbiy san’atni rivojlantirishning asosiy yo‘nalishlari (harbiy strategiya, operativ san’at, taktika), zamonaviy urushlar va harbiy nizolar xususiyatlari, jangovar harakatlarni amalga oshirish nazariyasi va amaliyoti, xorijiy mamlakatlarning armiyani isloh qilish va qo‘llash tajribasi, qo‘shinlar va qurol-aslahalarni boshqarish tizimlari, shuningdek, jangovar harakatlarni har tomonlama ta’minlashni kompleks ravishda tadqiq etish;</p>
            <p>harbiy-ilmiy tadqiqotlar zamonaviy metodologiyasini har tomonlama taxdil qilish, mudofaa va harbiy xavfsizlik sohasiga ilg‘or axborot-kommunikatsiya texnologiyalarini joriy etishga yo‘naltirilgan innovatsion goyalar, texnologiyalar va ishlanmalarni tatbiq etish, shuningdek, respublika va xorijiy yetakchi tadqiqot muassasalari bilan hamkorlikda ilmiy izlanishlarning ustuvor yo‘nalishlarini shakllantirish;</p>
            <p>zamonaviy talablar va xorijiy davlatlarning ilg‘or tajribasini inobatga olgan holda, mamlakatning oliy harbiy ta’lim muassasalarida harbiy-ilmiy faoliyatni ommalashtirish va takomillashtirish maqsadida harbiy-ilmiy tadqiqotlar asoslari va metodologiyasi bo‘yicha ilmiy izlanishlar olib borish.</p>
            <br/>
            <h4>Institutning funksiyalari:</h4>
            <br/>
            <p>Buyuk tarixiy davlat arboblari va sarkardalarning harbiy merosi va boy tajribasini, milliy harbiy san’atning rivojlanish tarixini, sarkardalarimizning strategiya va taktikadagi saboqlarini, shuningdek, jang san’ati va qahramonliklarini chuqur o‘rganish hamda ilmiy taxdil qilish;</p>
            <p>qo‘shin turlarining rivoji yo‘lidagi muammolar va istiqbolli yo‘nalishlarni har tomonlama o‘rganish, shuningdek, mamlakatning harbiy xavfsizligiga xatarlarni inobatga olgan holda Qurolli Kuchlar faoliyati uchun ilmiy asoslangan taklif va tavsiyalarni ishlab chiqish hamda tatbiq etishda ishtirok etish;

            </p>
            <p>zamonaviy jang va harbiy mojarolarni olib borish strategiyasi va taktikasini o‘rganish hamda tahlil qilish, jang olib borish va qo‘shinlarning maxsus harakatini o‘tkazish taktikasini takomillashtirish borasida tadqiqotlar olib borish, taklif va tavsiyalarni ishlab chiqish, shuningdek, mamlakatning hududiy mudofaasi, shaharlar va aholi punktlarining xavfsizligini ta’minlash, favqulodda vaziyatlarni bartaraf etishda (kamaytirishda) milliy Qurolli Kuchlar tarkibiga kiruvchi kuch tuzilmalarning hamkorlikdagi rejalarini amalga oshirish;</p>
            <p>harbiy san’atning rivojlanish tendensiyalarini, zamonaviy qurol-aslaha hamda texnikalarni qo‘llash yo‘llari va usullarini, shuningdek, noqonuniy qurolli tuzilmalar, xususiy harbiy kompaniyalar, mahalliy aholining zamonaviy jang va harbiy mojarolardagi ishtirokini tadqiq etish;</p>
            <p>harbiy ta’lim muassasalari kursant va tinglovchilari harbiy kasbni egallashlari uchun ularning individual-psixologik xususiyatlarini, shuningdek, harbiy jamoalarda ijtimoiy-psixologik muhitni o‘rganish, uni sog‘lomlashtirish yuzasidan tadqiqotlar olib borish, tegishli taklif va tavsiyalarni ishlab chiqish hamda tatbiq etish;</p>
            <p>harbiy ta’lim muassasalari professor-o‘qituvchilarining shaxsiy va kasbiy rivojlanishini monitoring qilish, ularning harbiy ta’lim jarayoniga, ijtimoiy-kasbiy muhitga samarali moslashuvini ta’minlash hamda ijtimoiy so‘rovlar va psixodiagnostika o‘tkazish orqali lavozimlarga tavsiya etilayotgan nomzodlarning pedagogik faoliyatga layoqati yuzasidan asosli xulosa berish;</p>
            <p>O‘zbekiston Respublikasi Qurolli Kuchlarini har tomonlama rivojlantirish va qo‘shinlardagi mavjud tizimli (shu jumladan, istiqbolda yuzaga kelishi mumkin bo‘lgan) muammolar yechimiga qaratilgan, davlat tomonidan moliyalashtiriladigan ilmiy ishlanma va loyihalar (grantlar)ni ishlab chiqish, ularga rahbarlik qilish hamda tatbiq qilishda ishtirok etish;</p>
            <p>zamonaviy talablarni inobatga olgan holda ilmiy tadqiqotlar metodologiyasi asoslarini o‘rganish va harbiy-ilmiy faoliyat borasidagi xorijiy va milliy ilg‘or tajribalarni ommalashtirish;</p>
            <p>mamlakatimizning va xorijiy davlatlarning tadqiqot muassasalari bilan hamkorlikda harbiy-ilmiy tadqiqotlar va innovatsion ishlanmalarning istiqbolli yo‘nalishlarini belgilash va shakllantirish;</p>
            <p>milliy va xorijiy ilmiy-tadqiqot markazlarida Institut ilmiy xodimlari va mutaxassislarining malaka oshirishi xamda amaliyot o‘tashini, shuningdek, Qurolli Kuchlar boshqaruv organlaridagi yuqori rahbarlik lavozimlari uchun tanlangan nomzodlarning Institutda qisqa muddatli amaliyotini tashkillashtirish.</p>
        </div>
    );
}

export default VazifalariHm;