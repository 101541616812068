import React from 'react';
import './KursatiladiganXizmat.scss'
import CarouselHospital from "../../CarouselHospital/CarouselHospital";

function KursatiladiganXizmat(props) {
    return (
        <div className='kursatiladiganXiz'>
            <div className='container'>
                <h2>Xizmat narxi</h2>
                <CarouselHospital/>

            </div>
        </div>
    );
}

export default KursatiladiganXizmat;