import React from 'react';
import './GospitalTarixi.scss'
import image1 from './images/MHKG-1920x1081.png'
import image2 from './images/gospitalTarix.jpg'
import image3 from './images/DSC_0682.JPG'
import image4 from './images/DSC_0953.JPG'
import image5 from './images/DSC_0585.JPG'
import image6 from './images/DSC_0549.JPG'
import image7 from './images/DSC_0541.JPG'

function GospitalTarixi(props) {
    return (
        <div className='gospitalTarixi'>
            <div className='container'>
                <h2>MHKG TARIXI QISQA SATRLARDA</h2>
                <div className='oldImages'>
                    <img src={image1} alt=""/>
                    <img src={image2} alt=""/>
                </div>
                <h2>MHKG HOZIRGI KUNDA</h2>
                <div className='newImages'>
                    <img src={image3} alt=""/>
                    <img src={image4} alt=""/>
                    <img src={image5} alt=""/>
                    <img src={image6} alt=""/>
                    <img src={image7} alt=""/>

                </div>


            </div>

        </div>
    );
}

export default GospitalTarixi;