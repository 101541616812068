import React from 'react';
import "./Qonunchilik.scss"

function Qonunchilik(props) {
    return (
        <div className="legislation">
            <div className="container">
                <h3>Qonunchilik</h3>
                <h4>O‘zbekiston Respublikasi Qonunlari</h4>
                <ol>
                    <li><a href="https://lex.uz/docs/23629" target="new">MUDOFAA TO‘G‘RISIDA</a></li>
                    <li><a href="https://lex.uz/docs/3495885" target="new">O‘ZBEKISTON RESPUBLIKASINING MUDOFAA DOKTRINASI TO‘G‘RISIDA </a></li>
                    <li><a href="https://lex.uz/docs/78717" target="new">UMUMIY HARBIY MAJBURIYAT VA HARBIY XIZMAT TO‘G‘RISIDA</a></li>
                    <li><a href="https://lex.uz/docs/33419" target="new">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI REZERVIDAGI XIZMAT TO‘G‘RISIDA </a></li>
                    <li><a href="https://lex.uz/docs/4680944" target="new">DAVLAT BOJI TO‘G‘RISIDA </a></li>
                    <li><a href="https://lex.uz/docs/4766082" target="new">AHOLINI RO‘YXATGA OLISH TO‘G‘RISIDA </a></li>
                    <li><a href="https://lex.uz/docs/4761984" target="new">O‘ZBEKISTON RESPUBLIKASINING FUQAROLIGI TO‘G‘RISIDA </a></li>
                </ol>

                <h4>O‘zbekiston Respublikasi prezident qarorlari</h4>
                <ol>
                    <li><a href="https://lex.uz/docs/4513434" target="new">O‘ZBEKISTON RESPUBLIKASI FUQAROLARINING HARBIY XIZMATNI O‘TASh TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA </a></li>
                    <li><a href="https://lex.uz/docs/4664436" target="new">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARIDA XIZMAT MAJBURIYATLARINI BAJARISH CHOG‘IDA TAN JAROHATI OLGAN HARBIY XIZMATCHILARGA (XODIMLARGA) YOKI HALOK BO‘LGAN (VAFOT ETGAN) HARBIY XIZMATCHILARNING (XODIMLARNING) OILA AʹZOLARIGA BIR MARTALIK NAFAQA TAYINLASH VA TO‘LASH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA </a></li>
                    <li><a href="https://lex.uz/docs/1354563" target="new">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI HARBIY XIZMATCHILARINI UY-JOY BILAN TAʹMINLASH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                    <li><a href="https://lex.uz/docs/4844615" target="new">BOJXONA MAʹMURIYATCHILIGINI ISLOH ETISH VA O‘ZBEKISTON RESPUBLIKASI DAVLAT BOJXONA XIZMATI ORGANLARI FAOLIYATINI TAKOMILLASHTIRISH TO‘G‘RISIDA </a></li>
                    <li><a href="#">«G‘ALABA BOG‘I» YODGORLIK MAJMUASI FAOLIYATINI TASHKIL ETISH ChORA-TADBIRLARI TO‘G‘RISIDA</a></li>
                    <li><a href="#">O‘SMIRLARNI HARBIY-VATANPARVARLIK RUHIDA TARBIYALASA XAMDA O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCALARI VA DAVLAT XIZMATI UChUN KADRLAR ZAXIRASINI TAYYORLASH TIZIMINI TAKOMILLASHTIRISHGA OID QO‘ShIMCHA CHORA-TADBIRLAR TO‘G‘RISIDA </a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARIDA TINCHLIK VA URUSH DAVRIDA TIBBIY KO‘RIKDAN O‘TKAZISH TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA </a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI HARBIY XIZMATCHILARINING MAʹNAVIY-MAʹRIFIY SAVIYASINI OSHIRISH TIZIMINI TUBDAN TAKOMILLASHTIRISH TO‘G‘RISIDA</a></li>
                    <li><a href="#">HARBIY TIBBIYOT SOHASIDA KADRLARNI TAYYORLASHNING SIFAT JIHATIDAN YANGI TIZIMINI TASHKIL ETISH CHORA-TADBIRLARI TO‘G‘RISIDA</a></li>
                </ol>

                <div className="row ">
                    <div className="col-md-8 ">
                        <h4>O‘zbekiston Respublikasi Vazirlar Mahkamasining qarorlari</h4>
                        <ol>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARINING HARBIY XIZMATCHILARIGA TURAR JOY SOTIB OLISH (QURISH) UCHUN UZOQ MUDDATLI IMTIYOZLI IPOTEKA KREDITLARI HAMDA YAKKA TARTIBDA UY-JOY QURISH UCHUN ER UCHASTKALARI BERISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI FUQAROLARINING SAFARBARLIK CHAQIRUVI REZERVIDAGI XIZMATINI TASHKIL ETISH CHORA-TADBIRLARI TO‘G‘RISIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI SAFIDA MUDDATLI HARBIY XIZMATNI O‘TAB, HARBIY QISM QO‘MONDONLIGINING TAVSIYANOMALARIGA EGA BO‘LGAN FUQAROLARNI OLIY TAʹLIM MUASSASALARIGA QABUL QILISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI MUDOFAA VAZIRLIGIDA ARMIYA SPORTINI YANADA RIVOJLANTIRISH VA OMMALASHTIRISH CHORA-TADBIRLARI TO‘G‘RISIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASIDA DOIMIY VA VAQTINCHA PROPISKA QILISH TARTIBINI YANADA SODDALASHTIRISH CHORA-TADBIRLARI TO‘G‘RISIDA</a></li>
                            <li><a href="#">YOSHLARNI VATANPARVARLIK RUHIDA TARBIYALASH ISHLARI SAMARADORLIGINI YANADA OSHIRISH CHORA-TADBIRLARI TO‘G‘RISIDA</a></li>
                            <li><a href="#"> O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARINING HARBIY XIZMATCHILARIGA TURAR JOYNI IJARAGA OLGANLIK (IJARADA TURGANLIK) UCHUN HAR OYLIK PUL KOMPENSATSIYASI TO‘LASH TARTIBI TO‘G‘RISIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARINING «MAʹNAVIY-MAʹRIFIY ISHLAR AʹLOCHISI» KO‘KRAK NISHONINI TAʹSIS ETISH TO‘G‘RISIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI HARBIYLARI VA UNING TARKIBIGA KIRUVCHI VAZIRLIK HAMDA IDORALARNING XODIMLARI FARZANDLARINING KVOTA ASOSIDA RESPUBLIKA OLIY TAʹLIM MUASSASALARIGA O‘QISHGA KIRISHI UCHUN TAVSIYANOMALAR BERISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI HARBIY TIBBIYOT AKADEMIYASI VA O‘ZBEKISTON RESPUBLIKASI MUDOFAA VAZIRLIGI AXBOROT-KOMMUNIKATSIYA TEXNOLOGIYALARI VA ALOQA HARBIY INSTITUTINING ILMIY-TADQIQOT FAOLIYATINI RIVOJLANTIRISH JAMG‘ARMALARI TO‘G‘RISIDAGI NIZOMLARNI TASDIQLASH HAQIDA</a></li>
                            <li><a href="#">O‘ZBEKISTON RESPUBLIKASI MUDOFAA VAZIRLIGI QO‘SHINLARIDA XOTIN-QIZLAR VA HARBIY XIZMATCHILARNING OILA A’ZOLARI BILAN ISHLASH TIZIMINING SAMARADORLIGINI OSHIRISH CHORA-TADBIRLARI TO‘G‘RISIDA</a></li>
                        </ol>
                    </div>
                    <div className="col-md-4">
                        <h4>O‘zbekiston Respublikasi Mudofaa vazirining buyrug‘i</h4>
                        <ol>
                            <li>
                                <a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARI AVIATSIYASINING UCHUVCHILAR TARKIBINI TIBBIY KO‘RIKDAN O‘TKAZISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA </a>
                            </li>
                            <li>
                                <a href="#">O‘ZBEKISTON RESPUBLIKASI MUDOFAA VAZIRLIGI QO‘SHINLARIGA KONTRAKT BO‘YICHA HARBIY XIZMATGA NOMZODLARNI SARALASH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA </a>
                            </li>
                        </ol>
                    </div>

                </div>

                <h4>Davlat boshqaruv organlari qo‘shma qarorlari</h4>
                <ol>
                    <li><a href="#">MUDDATLI HARBIY XIZMATNI O‘TAYOTGAN HARBIY XIZMATCHILARGA O‘ZBEKISTON RESPUBLIKASI OLIY TAʹLIM MUASSASALARIGA O‘QISHGA KIRISH UCHUN TAVSIYANOMALAR BERISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI MILLIY GVARDIYASI HARBIY-TEXNIK INSTITUTIGA O‘QISHGA QABUL QILISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI QUROLLI KUCHLARIDA TINCHLIK VA URUSH DAVRIDA TIBBIY KO‘RIKDAN O‘TKAZISHNI TASHKIL ETISH VA AMALGA OSHIRISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI OLIY TAʹLIM MUASSASALARINING HARBIY TAYYORGARLIK O‘QUV BO‘LINMALARIDA YAKUNIY DAVLAT ATTESTATSIYASINI TASHKIL QILISH VA O‘TKAZISH BO‘YICHA YO‘RIQNOMANI TASDIQLASH HAQIDA</a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI OLIY TAʹLIM MUASSASALARIGA «CHAQIRIQQACHA HARBIY TAʹLIM» YO‘NALISHI BO‘YICHA QABUL QILISHDA KASBIY (IJODIY) IMTIHON O‘TKAZISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI OLIY TAʹLIM MUASSASALARIDA REZERVDAGI VA ZAXIRADAGI OFITSERLARNI TAYYORLASH DASTURLARI BO‘YICHA O‘QITISH UCHUN TALABALARNI SARALASH TANLOVINI O‘TKAZISH TARTIBI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA</a></li>
                    <li><a href="#">O‘ZBEKISTON RESPUBLIKASI NOTARIAL PALATASI VA O‘ZBEKISTON RESPUBLIKASI MUFDOFAA VAZIRLIGI O`RTASIDA HAMKORLIK TO‘G‘RISIDA MEMORANDUM</a></li>
                </ol>

            </div>


        </div>
    );
}

export default Qonunchilik;