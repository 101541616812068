import {GET_VIDEOS, GET_VIDEOS_ALL, LOADING_TRUE, YOUTUBE_GET} from "../actionTypes/actionTypes";

const initialState = {

    youtubeGet: true,
    allVideos: [],
    videos: {}
}


export const youtubeReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case YOUTUBE_GET:
            return {
                ...state,
                youtubeGet: false
            };
        case GET_VIDEOS_ALL:
            return {
                ...state,
                allVideos: payload
            };
        case GET_VIDEOS:
            return {
                ...state,
                videos: payload
            };

        default:
            return state

    }
};