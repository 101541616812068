import React from 'react';
import normatov from './Normatov.jpg'

function RahbariyatHm(props) {
    return (
        <div className='container'>
            <h3 className='text-center fw-bold'>Rahbariyat</h3>
            <h4>O‘zbekiston Respublikasi Qurolli Kuchlari Harbiy meros va zamonaviy tadqiqotlar instituti boshlig‘i</h4>
                <div className="row">
                    <div className=" col-md-4">
                        <div>
                            <img width="100%" src={normatov} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-4 ">
                        <div className='d-flex flex-column justify-content-between h-100'>
                            <p className='mt-3'><b>General-leytenant</b> Normatov Shavkat Tursunpulatovich.</p>
                            <p><b>Qabul vaqti:</b> Har kuni soat 09:00 dan 18:00 gacha, Shanba kuni soat 09:00 dan 13:00 gacha.</p>

                        </div>
                    </div>


                </div>
        </div>
    );
}

export default RahbariyatHm;