import React, {useEffect, useState} from 'react';
import "./Networks.scss"
import facebook from './../Navbar/assets/facebook.png'
import odnoklasniki from './../Navbar/assets/Odnoklassniki.png'
import instagram from './../Navbar/assets/instagram.png'
import telegram from './../Navbar/assets/telegramlogo.png'
import youtube from './../Navbar/assets/youtube.png'
import googleplay from './../Navbar/assets/googleplay.png'
import twitter from './../Navbar/assets/twitter.png'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SizeTest from "../Navbar/SizeTest";
import {Button, ClickAwayListener} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {addStyle, addStyle1, addStyle2, addStyle3} from "../../redux/actions/spacialAction";
import {useDispatch} from "react-redux";

function Networks(props) {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);


    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(!open);
    };
    useEffect(()=>{

    },[])
    let size = `${localStorage.getItem('fontSize')}`

    const addStyle11 = ()=>{
        dispatch(addStyle1(true))
        localStorage.setItem('addStyle11', 'addStyle11')
    }
    const addStyle22 = ()=>{
        dispatch(addStyle2(true))
        localStorage.setItem('addStyle11', 'addStyle22')
    }
    const addStyle33 = ()=>{
        dispatch(addStyle3(true))
        localStorage.setItem('addStyle11', 'addStyle33')
    }
    const addStyle44 = ()=>{
        dispatch(addStyle(false))
        localStorage.setItem('addStyle11', 'addStyle44')
    }

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip placement="right" {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
            // border: "1px solid #717171",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            borderRadius: "7px",
            color: "black",
            boxShadow: '4px 4px 8px 0px rgba(34, 60, 80, 0.2)',
            // border: "1px solid #717171",
        },
    }));

    const networks = [
        {
            pathName: "https://www.facebook.com/mudofaavazirligi",
            src: facebook,
            background: "#1E73BE",
            name: "Facebook"
        },
        {
            pathName: "https://ok.ru/mudofaavazirligi",
            src: odnoklasniki,
            background: "#F7931E",
            name: "Odnaklasniki"
        },
        {
            pathName: "https://www.instagram.com/mudofaavazirligi/",
            src: instagram,
            background: "#E878E8",
            name: "Instagram"
        },
        {
            pathName: "https://t.me/mudofaavazirligi",
            src: telegram,
            background: "#1E73BE",
            name: "Telegram"
        },
        {
            pathName: "https://www.youtube.com/@UzArmiya",
            src: youtube,
            background: "red",
            name: "Youtube"
        },
        {
            pathName: "https://play.google.com/store/apps/details?id=uz.pdp.mudofaa",
            src: googleplay,
            background: "#36C825",
            name: "GooglePlay"
        },
        {
            pathName: "https://twitter.com/i/flow/login?redirect_after_login=%2Fmudofaarasmiy",
            src: twitter,
            background: "#00BDE2",
            name: "Twitter"
        },
    ]

    const iconStyle = {
        color: 'white', // Oq rangni belgilash
    };
    return (
        <div className="Networks">
            {networks.map((item, index)=>(
                <BootstrapTooltip  placement="left" title={<p style={{fontSize:"16px", margin:"0"}}>{item.name ? item.name : ""}</p>}>

                <div key={index} className="netBox" style={{background: item.background}} >
                        <a href={item.pathName} target="_blank">
                            <img src={item.src} alt=""/>
                        </a>
                </div>
                </BootstrapTooltip>
            ))}
            <div className="netBox2">
                <BootstrapTooltip placement="left" title={<p style={{fontSize:"16px", margin:"0"}}>Maxsus imkoniyatlar</p>}>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div>
                            <BootstrapTooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <div className="maxsusImkoniyat">
                                        <p>Turi</p>
                                        <hr/>
                                        <span>
                                            <div onClick={()=>addStyle44()} style={{backgroundColor: "#0c63e4"}}>A</div>
                                            <div onClick={()=>addStyle11()} style={{backgroundColor: "#597193"}}>A</div>
                                            <div onClick={()=>addStyle22()} style={{backgroundColor: "#7a7a7a"}}>A</div>
                                            <div onClick={()=>addStyle33()} style={{backgroundColor: "#151515"}}>A</div>
                                        </span>
                                        <hr/>
                                        <br/>
                                        <SizeTest />
                                    </div>
                                }
                            >
                                <Button  onClick={handleTooltipOpen}>
                                    <VisibilityIcon
                                        style={iconStyle}
                                    />
                                </Button>
                            </BootstrapTooltip>
                        </div>
                    </ClickAwayListener>
                </BootstrapTooltip>

            </div>
        </div>
    );
}

export default Networks;