import React, {useEffect} from 'react';

function TibbiyotAkademiyasi(props) {
    useEffect(()=>{
        window.scrollTo(0, 0); // Sahifa yuqoriga o'tkazish
    },[])
    return (
        <div className='container'>
            <br/>
            <h4 className='text-center'>QUROLLI KUCHLAR HARBIY TIBBIYOT AKADEMIYASI</h4>
            <br/>
            <p><b>O‘zbekiston Respublikasi Qurolli Kuchlari Harbiy tibbiyot akademiyasi</b>oshkent tibbiyot akademiyasi huzuridagi Harbiy-tibbiyot fakulteti negizida O‘zbekiston Respublikasi Prezidentining 2020-yil 22-oktabrdagi “Harbiy tibbiyot sohasidagi kadrlarni tayyorlashning sifat jihatdan yangi tizimini tashkil etish chora-tadbirlari to‘g‘risida” PQ–4870 – son qaroriga muvofiq tashkil etilgan.</p>
            <p>Akademiya asosiy vazifalari quyidagilardan iborat:</p>
            <p>O‘zbekiston Respublikasi Qurolli Kuchlari tarkibiga kiruvchi vazirliklar va idoralar uchun harbiy tibbiyot sohasida zamonaviy talablarga javob beradigan kadrlarni tayyorlash, qayta tayyorlash va malakasini oshirish;</p>
            <p>tibbiyot sohasida tor mutaxassislarni, ilmiy-pedagogik kadrlarni hamda zaxira va rezervdagi tibbiyot xizmati ofitserlarini tayyorlash;</p>
            <p>ta’lim jarayoniga xalqaro ta’lim standartlariga asoslangan ilg‘or pedagogik va innovatsion tibbiyot texnologiyalari, zamonaviy o‘quv dasturlari va o‘quv-uslubiy metodlarni keng joriy etish;</p>
            <p>harbiy tibbiyot amaliyotida mavjud muammolarning yechimiga qaratilgan fundamental, amaliy va innovatsion ilmiy-tadqiqot ishlarini amalga oshirish;</p>
            <p>milliy va xorijiy yetakchi oliy, oliy harbiy ta’lim hamda ilmiy-tadqiqot muassasalari bilan o‘zaro hamkorlikda ta’lim jarayonini rivojlantirish, sog‘liqni saqlash sohasida ilmiy tadqiqotlarni o‘tkazish;</p>
            <p>ta’lim oluvchilarni insonparvarlik va yuksak ma’naviy g‘oyalar asosida, vatanparvarlik ruhida tarbiyalash.</p>
            <br/>
            <h4 className='text-center'>Hozirgi kunda Akademiyada quyidagi kafedralar faoliyat yuritib kelmoqda:</h4>
            <p>tibbiyot xizmatining tuzilishi va taktikasi;</p>
            <p>jangovar jarrohlik patalogiyalari;</p>
            <p>harbiy terapiya va toksikologiya;</p>
            <p>harbiy gigiena;</p>
            <p>harbiy epidemiologiya va biologik havfsizlik.</p>
            <p>Akademiyada quyidagi ta’lim yo‘nalishlari va mutaxassisliklar bo‘yicha kadrlar tayyorlanadi:</p>
        </div>
    );
}

export default TibbiyotAkademiyasi;