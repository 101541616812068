export const Uz={
    HELLO:'Salom dunyo',
    SEARCH:'...Lorem ipsum dolor sit amet.',
    ABOUT_THE_MINISTRY: 'Vazirlik haqida',
    NEWS: 'Yangiliklar',
    LEGISLATION: 'Qonunchilik',
    OPEN_DATA: 'Ochiq malumotlar',
    CONTACT: "Bog'lanish",
    DEFENSE: "VAZIRLIGI",
    MINISTRY: "MUDOFAA",
    WELCOME: "rasmiy veb saytiga xush kelibsiz",
    LATEST_NEWS: "So'ngi yangiliklar",
    POPULAR_POSTS: "Ko'p o'qilgan",
    READ_MORE: "KO'ROQ KO'RISH",
    MORE: "Batafsil...",
    VIDEOS: "Videolar",


    MANAGEMENT: "Rahbariyat",
    FUNCTION_MINISTRY:"Mudofaa vazirligi funksiya va vazifalari",
    HISTORY_ARMED: "Qurolli kuchlar tarixi",
    VACANCIES: "Bo`sh ish o`rinlari"


};