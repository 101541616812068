import React, {useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './Gospital.scss'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined'
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import Box from "@mui/material/Box";
import Boshsahifa from "./BoshSahifa/Boshsahifa";
import Rahbariyat from "./RahbariyatGos/Rahbariyat";
import GospitalTarixi from "./GospitalTarixi/GospitalTarixi";
import TibbiyBulimlar from "./TibbiyBulimlar/TibbiyBulimlar";
import KursatiladiganXizmat from "./KursatiladiganXizmat/KursatiladiganXizmat";

function Gospital(props) {
    const [value, setValue] = React.useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(()=>{
        window.scrollTo(0, 0); // Sahifa yuqoriga o'tkazish
    },[])

    return (
        <div className='gospital'>
            <div className='container mt-3 '>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="icon label tabs example"
                    textColor="black"
                >
                    <Tab icon={<HomeOutlinedIcon />} label="Bosh Sahifa" iconPosition="start" />
                    <Tab icon={<PersonOutlineOutlinedIcon />} label="Rahbariyat" iconPosition="start" />
                    <Tab icon={<WorkHistoryOutlinedIcon />} label="Gospita tarixi" iconPosition="start" />
                    <Tab icon={<MedicalServicesOutlinedIcon />} label="Tibbiy Bo'limlar" iconPosition="start" />
                    <Tab icon={<MedicalInformationOutlinedIcon />} label="Ko'rsatiladigan Xizmatlar" iconPosition="start" />
                </Tabs>

            </div>
            <div>
                <TabPanel value={value} index={0}>
                    <Boshsahifa/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Rahbariyat/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <GospitalTarixi/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <TibbiyBulimlar/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <KursatiladiganXizmat/>
                </TabPanel>

            </div>
        </div>
    );
}

export default Gospital;