import React from 'react';
import './TibbiyBulimlar.scss'
import LocalHotelOutlinedIcon from "@mui/icons-material/LocalHotelOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import AirlineSeatReclineExtraOutlinedIcon from "@mui/icons-material/AirlineSeatReclineExtraOutlined";
import AssistWalkerOutlinedIcon from "@mui/icons-material/AssistWalkerOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';

function TibbiyBulimlar(props) {
    return (
        <div className='tibbiyBulim'>
            <div className='headerText'>
                <div className='container'>
                    <h2>Tibbiy Bo'limlar</h2>
                    <p>153 YILLIK SIFATLI DARAJADAGI XIZMATLAR</p>
                </div>
            </div>
            <div className="maxsusXizmat">
                <div className='container'>
                    <div className='bulinTitle'>
                        <p>BIZNING XIZMATLAR</p>
                        <h3>Yuqori sifatli maxsus xizmatlar</h3>
                        <p>MHGK (Markaziy Harbiy klinik Gospitali) tashkil
                            etilganidan beri o'z bemorlariga ambulatoriya,
                            nevrologiya, laboratoriya, diagnostika va boshqa
                            xizmatlarni o'z ichiga olgan to'liq tibbiy yordam
                            ko'rsatib kelmoqda.</p>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className='yunalish'>
                                <AssignmentOutlinedIcon
                                    style={{fontSize:"50px", color:"#0095EB"}}
                                />
                                <h4>Qabul bo'limi</h4>
                                <p>Batafsil</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='yunalish'>
                                <Diversity1OutlinedIcon
                                    style={{fontSize:"50px", color:"#0095EB"}}
                                />
                                <h4>Abdominal xirurgiya bo'limi</h4>
                                <p>Batafsil</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='yunalish'>
                                <BiotechOutlinedIcon
                                    style={{fontSize:"50px", color:"#0095EB"}}
                                />
                                <h4>Yiringli xirurgiya bo'limi</h4>
                                <p>Batafsil</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='yunalish'>
                                <AirlineSeatReclineExtraOutlinedIcon
                                    style={{fontSize:"50px", color:"#0095EB"}}
                                />
                                <h4>Urologiya bo'limi</h4>
                                <p>Batafsil</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='yunalish'>
                                <AssistWalkerOutlinedIcon
                                    style={{fontSize:"50px", color:"#0095EB"}}
                                />
                                <h4>Torakol va angioxirurgiya bo'limi</h4>
                                <p>Batafsil</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='yunalish'>
                                <HearingOutlinedIcon
                                    style={{fontSize:"50px", color:"#0095EB"}}
                                />
                                <h4>Otorinolaringologiya bo'limi</h4>
                                <p>Batafsil</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default TibbiyBulimlar;