import React from 'react';
import './Rahbariyat.scss'
import image1 from './images/DSC_0504.JPG'
import image2 from './images/DSC_0729.JPG'
import image3 from './images/DSC_0376.JPG'
import image4 from './images/DSC_0328.JPG'
import image5 from './images/DSC_0929.JPG'

function Rahbariyat(props) {
    return (
        <div className='rahbariyat'>
            <div className='container'>
                <h2 className='text-center fw-bold'>Markaziy Harbiy Klinikasi rahbariyati</h2>
                <div className="row cardRax">
                    <div className="col-md-6">
                        <div className='raxImg'>
                            <img src={image1} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='raxText'>
                            <span>
                            <p>Markaziy Harbiy Klinik Gospital Boshlig'i tibbiy xizmat polkovnigi</p>
                            <h4>Rahimqulov Hudoyqul Hakimovich</h4>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card cardInfo">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='raxCard'>
                                    <p>
                                        O'zbekiston Respubliksida xizmat korsatgan tibbiyot hodimi,
                                        "Sodiq xizmatlari uchun" medali sohibi, O'zbekiston Respubliksi
                                        sog'liqni saqlash a'lochisi, oliy toifali shifokor,
                                        37 yillik ish faoliyatiga ega
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='raxCardQabul'>
                                    <span>
                                        <b>Qabul vaqtlari:</b>
                                        <b>Haftaning</b>
                                        <p>Chorshanba</p>
                                        <p>15:00 dan 16:00 gacha</p>
                                        <p>Xizmat telefoni: 71-262-18-38</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="row cardRax">*/}
                {/*    <div className="col-md-6">*/}
                {/*        <div className='raxImg'>*/}
                {/*            <img src={image1} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-6">*/}
                {/*        <div className='raxText'>*/}
                {/*            <span>*/}
                {/*            <p>MHKG Boshlig'ining o'rinbosari - shtab boshlig'i tibbiy xizmat podpolkovnigi</p>*/}
                {/*            <h4>Omonov Ulug'bek Berdiyorovich</h4>*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="card cardInfo">*/}
                {/*    <div className="card-body">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-6">*/}
                {/*                <div className='raxCard'>*/}
                {/*                    <p>*/}
                {/*                        Birinchi toifali shifokor, 25 yillik ish faoliyatiga ega*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-6">*/}
                {/*                <div className='raxCardQabul'>*/}
                {/*                    <span>*/}
                {/*                        <b>Qabul vaqtlari:</b>*/}
                {/*                        <b>Haftaning</b>*/}
                {/*                        <p>Chorshanba</p>*/}
                {/*                        <p>14:00 dan 17:00 gacha</p>*/}
                {/*                        <p>Xizmat telefoni: 71-262-18-38</p>*/}
                {/*                    </span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="row cardRax">
                    {/*<div className="col-md-6">*/}
                    {/*    <div className='raxImg'>*/}
                    {/*        <img src={image1} alt=""/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-md-6">*/}
                    {/*    <div className='raxText'>*/}
                    {/*        <span>*/}
                    {/*        <p>MHKG Boshlig'ining o'rinbosari -Bosh shifokor*/}
                    {/*            tibbiy xizmat polkovnigi</p>*/}
                    {/*        <h4>Masharipov Baxtiyor Jabbarbergenovich</h4>*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*<div className="card cardInfo">*/}
                {/*    <div className="card-body">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-6">*/}
                {/*                <div className='raxCard'>*/}
                {/*                    <p>*/}
                {/*                        oliy toifali shifokor, O'zbekiston Respublikasida pulmonologlar assosiaslarining*/}
                {/*                        a'zosi, 25 yillik ish faoliyatiga ega*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-6">*/}
                {/*                <div className='raxCardQabul'>*/}
                {/*                    <span>*/}
                {/*                        <b>Qabul vaqtlari:</b>*/}
                {/*                        <b>Haftaning</b>*/}
                {/*                        <p>Chorshanba</p>*/}
                {/*                        <p>15:00 dan 16:00 gacha</p>*/}
                {/*                        <p>Xizmat telefoni: 71-262-18-38</p>*/}
                {/*                    </span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="row cardRax">
                    <div className="col-md-6">
                        <div className='raxImg'>
                            <img src={image2} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='raxText'>
                            <span>
                            <p>MHKG Boshlig'ining o'rinbosari -Bosh xirurg
                                tibbiy xizmat polkovnigi</p>
                            <h4>Rasulov Ulug'bek Abdugafurovich</h4>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card cardInfo">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='raxCard'>
                                    <p>
                                        "Mehnat shuhrati" va Salomatlik Il-darajali ordenlari sohibi,
                                        O'zbekiston Respublikasi sog'liqni saqlash a'lochisi,
                                        falsafa fanlari doktori, oliy toifali shifokor,
                                        O'zbekiston Respublikasida flebologlar va shoshilinch jarrohlik assosiasiyalari
                                        a'zosi,
                                        32 yillik ish faoliyatiga ega
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='raxCardQabul'>
                                    <span>
                                        <b>Qabul vaqtlari:</b>
                                        <b>Haftaning</b>
                                        <p>Chorshanba</p>
                                        <p>11:00 dan 17:00 gacha</p>
                                        <p>Xizmat telefoni: 71-262-18-38</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row cardRax">
                    <div className="col-md-6">
                        <div className='raxImg'>
                            <img src={image3} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='raxText'>
                            <span>
                            <p>MHKG Boshlig'ining o'rinbosari -Bosh terapevt
tibbiy                          xizmat polkovnigi</p>
                            <h4>Shakarov Anvar Daliyevich</h4>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card cardInfo">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='raxCard'>
                                    <p>
                                        Salomatlik II-darajali
                                        va "Shon-sharaf" ordenlari sohibi, O'zbekiston
                                        Respublikasi sogʻliqni saqlash a'lochisi, oliy
                                        toifali shifokor, 25 yillik ish faoliyatiga ega
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='raxCardQabul'>
                                    <span>
                                        <b>Qabul vaqtlari:</b>
                                        <b>Haftaning</b>
                                        <p>Chorshanba</p>
                                        <p>11:00 dan 17:00 gacha</p>
                                        <p>Xizmat telefoni: 71-262-18-38</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row cardRax">
                    <div className="col-md-6">
                        <div className='raxImg'>
                            <img src={image4} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='raxText'>
                            <span>
                            <p>MHKG Boshlig'ining o'rinbosari -Bosh infeksionist
                                tibbiy xizmat polkovnigi</p>
                            <h4>Azimov Abduvohid Vahobjonovich</h4>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card cardInfo">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='raxCard'>
                                    <p>
                                        tibbiy xizmat polkovnigi, Salomatlik l-darajali va
                                        "Do'stlik” ordenlari sohibi, O'zbekiston Respublikasi
                                        sog'liqni saqlash a'lochisi, oliy toifali shifokor,
                                        Yevropa jigar kasalliklarining tahlil qilish va Rossiya
                                        gastroenterologlar assosiaslarining a'zosi, 30 yillik ish faoliyatiga ega
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='raxCardQabul'>
                                    <span>
                                        <b>Qabul vaqtlari:</b>
                                        <b>Haftaning</b>
                                        <p>Chorshanba</p>
                                        <p>11:00 dan 17:00 gacha</p>
                                        <p>Xizmat telefoni: 71-262-18-38</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row cardRax">
                    <div className="col-md-6">
                        <div className='raxImg'>
                            <img src={image5} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='raxText'>
                            <span>
                            <p>MHKG Boshlig'ining tarbiyaviy va mafkuraviy ishlar
                                bo'yicha o'rinbosari podpolkovnigi</p>
                            <h4>Bozorov Abduhovid Toshpo'latovich</h4>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card cardInfo">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='raxCard'>
                                    <p>

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='raxCardQabul'>
                                    <span>
                                        <b>Qabul vaqtlari:</b>
                                        <b>Haftaning</b>
                                        <p>Chorshanba</p>
                                        <p>11:00 dan 17:00 gacha</p>
                                        <p>Xizmat telefoni: 71-262-18-38</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Rahbariyat;