import {FONT_SIZE, GET_VIDEOS, GET_VIDEOS_ALL, YOUTUBE_GET} from "../actionTypes/actionTypes";

export const getYoutubeVideo = (newValue) => (dispatch) => {
    dispatch({
        type: YOUTUBE_GET,
        payload:newValue
    });
};
export const getVideosAll = (newValue) => (dispatch) => {
    dispatch({
        type: GET_VIDEOS_ALL,
        payload:newValue
    });
};
export const getVideos = (newValue) => (dispatch) => {
    dispatch({
        type: GET_VIDEOS,
        payload:newValue
    });
};