import React from 'react';
import {getText} from "../Translation";

function Qktarixi(props) {
    return (
        <div className="history">
            <div className="container">
                {/*<p className="mt-2" style={{color: "#989898"}}>Bosh sahifa > Qurolli kuchlar tarixi</p>*/}
                <h3 className="mt-5 mb-5">Qurolli kuchlar tarixi</h3>
            </div>

        </div>
    );
}

export default Qktarixi;