import React from 'react';

function MVmarkaziyAp(props) {
    return (
        <div className='container'>
            <br/>
            <h3>Mudofaa vazirligi markaziy apparati tovar (ish, xizmat)larni markazlashtirilgan xaridini amalga oshirish uchun eng yaxshi takliflarni tanlash maqsadida, Ta’minot departamenti Xarid qilish boshqarmasi doimiy faoliyat yurituvchi komissiya (tanlov komissiyasi) tarkibi tayinlangan.</h3>
            <br/>
            <h4>Mudofaa vazirining  2023-yil 4-yanvardagi  2-sonli Buyrugʻi</h4>
            <p>“Mudofaa vazirligi markaziy apparati Taʼminot departamenti xarid qilish boshqarmasi tovar (ish, xizmatlar)ni markazlashtirilgan xaridlari boʻyicha doimiy faoliyat yurituvchi komissisiyasi tarkibini tasdiqlash toʻgʻrisida”</p>
            <br/>
            <h4>Oʻzbekiston Respublikasi Mudofaa Vazirligi Taʼminot Departamenti harid qilish boshqarmasi doimiy faoliyat yurituvchi komissisiyasi tarkibi</h4>
            <h4 className='text-center'>ROʻYXATI</h4>

            <table className="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">T/r</th>
                    <th scope="col">Xarbiy unvoni</th>
                    <th scope="col">Familiyasi ismi, otasining ismi</th>
                    <th scope="col">Egallab turgan lavozimi</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>polkovnik</td>
                    <td>XALIKOV

                        Xasan Djaxangirovich</td>
                    <td>OʻR MV MA TD xarid qilish boshqarmasi boshligʻi</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>podpolkovnik</td>
                    <td>ABDURAYEMOV

                        Shuxratjon Abdusattarovich</td>
                    <td>OʻR MV MA TD xarid qilish boshqarmasi  AMKX va QXMHQ boʻlimi boshligʻi</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>podpolkovnik</td>
                    <td >VAXIDOV

                        Alisher Baxtiyorovich</td>
                    <td>OʻR MV MA TD xarid qilish boshqarmasi moddiy texnik taʼminot mulklarini xarid qilish boʻlimi boshligʻining oʻrinbosari</td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td >podpolkovnik</td>
                    <td >MEXRIDDINOV

                        Olimjon Norbutayevich</td>
                    <td>OʻR MV MA TD xarid qilish boshqarmasi bojxona boʻlinmasi boshligʻir</td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td >podpolkovnik</td>
                    <td >FOZILOV

                        Javxarbek Abdusalomovich</td>
                    <td>OʻR MV MA TD xarid qilish boshqarmasi realizatsiya boʻlimi boshligʻi</td>
                </tr>
                <tr>
                    <th scope="row">6</th>
                    <td>mayor</td>
                    <td>AKRAMOV

                        Xurshiddin Sunatovich</td>
                    <td>OʻR MV MA TD xarid qilish boshqarmasi  JTM va MMM xarid qilish boʻlimi boshligʻi</td>
                </tr>
                </tbody>
            </table>
            <br/>
        </div>
    );
}

export default MVmarkaziyAp;