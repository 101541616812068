import React from 'react';

function MVgaMurojat(props) {
    return (
        <div className='container'>
            <br/>
            <h4>Mudofaa vazirligiga 2023-yilning dastlabki to‘rt oyi davomida jismoniy va yuridik shaxslardan tushgan murojaatlar hisoboti</h4>
            <br/>
            <p>2023-yilning yanvar-aprel oylarida Mudofaa vazirligida harbiy xizmatchilar va ularning oila aʻzolari, jismoniy va yuridik shaxslarning yozma hamda ogʻzaki murojaatlarida koʻtarilgan muammolarni hal etish borasida belgilangan tartibda tegishli tadbirlarni amalga oshirdi. 2023-yilning 1-mayigacha jismoniy va yuridik shaxslardan jami <b>1711 ta</b> murojaatlar kelib tushgan. Ulardan:</p>
            <ul>
                <li>Oʻzbekiston Respublikasi Prezidentining Virtual qabulxonasi orqali – <b>911 ta</b>;</li>
                <li>Oʻzbekiston Respublikasi Mudofaa vazirligi qabulxonasi orqali – <b>446 ta</b>;</li>
                <li>Bosh prokuratura orqali – <b>22 ta</b>;</li>
                <li><b>332 ta</b> ogʻzaki murojaatlar.</li>
            </ul>
            <p>Shuningdek, <b>162 ta</b> murojaatlar taalluqliligi yuzasidan tegishli idoralar va hokimiyatlarga yuborildi. Qolgan <b>1549 ta</b> murojaat Mudofaa vazirligi markaziy apparati bosh boshqarma, boshqarma va harbiy okruglar rahbariyati tomonidan koʻrib chiqildi.</p>
            <p>Kelib tushgan ariza va murojaatlarning <b>769 tasi</b> qanoatlantirilgan, <b>855 tasiga</b> huquqiy maslahat berilgan, <b>87 tasi</b> hozirgi kunda oʻrganilmoqda.</p>
            <p>2023-yil 1-maygacha jismoniy va yuridik shaxslar murojaatlarining:</p>
            <ul>
                <li><b>684 tasi</b> – Kadrlar bosh boshqarmasi (harbiy xizmatchilarni oilaviy sharoitiga koʻra xizmat joyini koʻchirish, kontrakt boʻyicha harbiy xizmatga kirish, harbiy xizmatni tiklash);</li>
                <li><b>312 tasi</b> – Uy-joydan foydalanish boshqarmasi (uy-joyni xususiylashtirish va uy joy bilan taʻminlash);</li>
                <li><b>147 tasi</b> – Tashkiliy-safarbarlik bosh boshqarmasi (muddatli harbiy xizmatga chaqirish, SChR masalasi);</li>
                <li><b>123 tasi</b> – Tarbiyaviy va mafkuraviy ishlar bosh boshqarmasi (harbiy xizmatchilarning olilaviy masalalari);</li>
                <li><b>119 tasi</b> – Moliya-iqtisod bosh boshqarmasi (ish va xizmat haqqi, nafaqa va ijtimoiy taʻminot masalalari);</li>
                <li><b>37 tasi</b> – Tibbiy taʻminot boshqarmasi;</li>
                <li><b>30 tasi</b> – Harbiy kadrlarni tayyorlash boshqarmasi ;</li>
                <li><b>259 tasi</b> – boshqa boʻlim va boshqarmalar faoliyatiga tegishli boʻlgan.</li>
            </ul>
            <br/>
            <p><b><i>Mudofaa vazirligi Axborot va ommaviy kommunikatsiyalar departamenti</i></b></p>
        </div>
    );
}

export default MVgaMurojat;