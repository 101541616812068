import React, {useEffect, useState} from 'react';
import Landing from "../components/Landing/Landing";
import SongiYangilik from "../components/Songiyangilik/SongiYangilik";
import Fade from "../components/CarouselHospital/CarouselHospital";
import FoydaliMalumotlar from "../components/FoydaliMalumotlar/FoydaliMalumotlar";
import NewCorousel from "../components/NewCoruosel/NewCorousel";
import UzbekistonMap from "../components/mapUzb/UzbekistonMap";
import YoutubeVideos from "../components/YoutubeVideos/YoutubeVideos";
import axios from "axios";
import Loading from "../components/Loading/Loading";
import {useSelector} from "react-redux";
import Navbar from "../components/Navbar/Navbar";
import AuthUser from "../components/Auth/AuthUser";
import TestMode from "../components/TestMode/TestMode";

function PageLanding(props) {
    const {http} = AuthUser()
    const [postList, setPostList] = useState([])
    const [loading, setLoading] = useState(false);

    const {language} = useSelector(state => state.language);

    useEffect(() => {

        window.scrollTo(0, 0); // Sahifa yuqoriga o'tkazish
        http.get('user/post/list',{
            params:{
                size: 8,
                page: 0,
                lang: language,
                postTypeId: 0


            }
        })
            .then(res => {
                setPostList(res.data.object.postComponentList.content)
                setLoading(false)
            }).catch(e => {
            e.error("Bog'lanishda xatolik!")
            setLoading(false)
        })
    }, [language]);
    const [imageStyle, setImageStyle] = useState({

    });

    const {addStyle1} = useSelector(state => state.special)
    const {addStyle2} = useSelector(state => state.special)
    const {addStyle3} = useSelector(state => state.special)
    const {addStyle} = useSelector(state => state.special)
    useEffect(() => {
        if (addStyle1) {
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#9DD1FF",
                color:"black",
                width: "100%",
                filter: 'grayscale(50%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
        if (addStyle2) {
            setImageStyle({
                ...imageStyle,
                color:"white",
                backgroundColor: "#464646",
                width: "100%",
                filter: 'grayscale(70%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
        if (addStyle3) {
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#1c1c1c",
                color:"white",
                width: "100%",
                filter: 'grayscale(100%)',
                // Add additional styles or modifications here
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '0';
            }
        }
        if (addStyle){
            setImageStyle({
                color: "black"
            });
            let images = document.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.opacity = '1';
            }
        }
    }, [addStyle1, addStyle2, addStyle3, addStyle]);




    if(loading){
        return (
            <Loading/>
        )
    }

    return (
        <div style={imageStyle}>
            <NewCorousel postList={postList}/>
            <TestMode/>
            <Landing/>
            <SongiYangilik/>
            <YoutubeVideos/>
            <Fade/>
            <UzbekistonMap/>
            <FoydaliMalumotlar/>
        </div>
    );
}

export default PageLanding;