import React from 'react';
import imgGalaba from './1619607258.476.png'

function GalabaBogi(props) {
    return (
        <div className="container">
            <div className="col-8 mt-3">
                <h2>“G‘alaba bog‘i” yodgorlik majmuasi vakant lavozimlar ro‘yxatini e’lon qildi</h2>
                <img src={imgGalaba} alt=""/>
                <br/>
                <br/>
                <b>Mudofaa vazirligi huzuridagi “G‘alaba bog‘i” yodgorlik majmuasining bo‘sh (vakant) lavozimlari ro‘yxati:</b>
                <p><b>Joylashgan hudud —</b>Toshkent shahri Olmazor tumani, “G‘alaba bog‘i” yodgorlik majmuasi;</p>
                <br/>
                <p><b>Vakanat lavozim —</b>tashrif buyuruvchilar bilan ekskursiya o‘tkazish bo‘limi katta texnigi — katta yo‘riqchi;</p>
                <br/>
                <p><b>Talab etiladigan ma’lumot va mutaxassislik</b>oliy ma’lumot;</p>
                <br/>
                <p><b>Talab etiladi malaka hamda ish staji (boshqa talablar) —</b>30 yoshgacha bo‘lgan, jismonan sog‘lom va harbiy xizmat talablariga javob beradigan, davlat tili, rus hamda ingliz tillarida mukammal so‘zlasha oladigan, ekskursiya o‘tkazishda erkin muloqotga kirishish qobiliyatiga ega, Microsoft Office (Word, Excel, Power Point) dasturlarida amaliy bilimlarga va nostandart holatlarda tezkor ravishda mantiqiy fikrlash qobiliyatiga ega.</p>
                <br/>
                <p><b>Lavozim uchun belgilangan o‘rtacha ish haqi miqdori —</b>5,5 million so‘m (hamda qo‘shimcha moddiy rag‘batlantirish);</p>
                <br/>
                <p><b>Bog‘lanish uchun telefon raqam —</b>(94) 148-17-05.</p>
            </div>

        </div>
    );
}

export default GalabaBogi;