import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import AuthUser from "../components/Auth/AuthUser";
import {Pagination} from "@mui/material";
import {getText} from "../components/Translation";
import {PATH_NAME_AUTH} from "../tools/constant";

function JanubiyGarbiy(props) {
    const {http} = AuthUser()
    const [imageStyle, setImageStyle] = useState({

    });

    const [currentPage, setCurrentPage] = useState(0)
    const [postList, setPostList] = useState([])
    const [totalPages, settotalPages] = useState(0)
    const [newsName, setNewsName] = useState()

    const {addStyle1} = useSelector(state => state.special)
    const {addStyle2} = useSelector(state => state.special)
    const {addStyle3} = useSelector(state => state.special)
    const {addStyle} = useSelector(state => state.special)
    const {fontSize} = useSelector(state => state.special)
    const {language} = useSelector(state => state.language)

    useEffect(() => {
        // console.log(props.match)
        http.get(`user/post/list`,{
            params:{
                size: 8,
                page: currentPage,
                lang: language,
                postTypeId: props.match.params.id


            }
        }).then((res)=>{
            setPostList(res.data.object.postComponentList.content)
            setNewsName(res.data.object.postTypeName)
            settotalPages(res.data.object.totalPages)
            // console.log(res)
        }).catch((e)=>{
            // console.log(e.message)
        })

        if (addStyle1) {
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#9DD1FF",
                color:"black",
                width: "100%",
                filter: 'grayscale(50%)',
                // Add additional styles or modifications here
            });
        }
        if (addStyle2) {
            setImageStyle({
                ...imageStyle,
                color:"white",
                backgroundColor: "#464646",
                width: "100%",
                filter: 'grayscale(70%)',
                // Add additional styles or modifications here
            });
        }
        if (addStyle3) {
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#1c1c1c",
                color:"white",
                width: "100%",
                filter: 'grayscale(100%)',
                // Add additional styles or modifications here
            });
        }
        if (addStyle){
            setImageStyle({
                color: "black"
            });
        }
    }, [addStyle1, addStyle2, addStyle3, addStyle, props.match.params.id, language]);

    const handleChangePage = (event, newPage)=>{
        setCurrentPage(newPage-1)
    }


    return (
        <div style={imageStyle}>
            <div className="container" >
                <div className="cardsNews">
                    <h1>{newsName}</h1>
                    <div className='cardNews'>
                        <div className="row">
                            {
                                postList?.map((value, index)=>(
                                    <div key={index} className="col-md-3 my-2">
                                        <div className="card" style={{width: "100%"}}>
                                            <img className="card-img-top" src={value ? `${PATH_NAME_AUTH}${value.pathPhoto}` : null} alt="Card image"
                                                 style={{width: "100%"}}/>
                                            <div className="card-body">
                                        <span className="cardDate">
                                            <img  src={require("./assets/calendar.jpg")} alt=""/>
                                            <p>{value.publicationTime}</p>
                                        </span>
                                                <div className="newCardText">
                                                    <p style={{fontSize:`${15 + Number(fontSize) }px`,  lineHeight:`${19 + Number(fontSize) }px`}} className="card-text">{value.title.length > 80 ? value.title.slice(0,80)+'...' : value.title}</p>
                                                    <Link to={`/batafsil/${value.postId}`} className="btn">{getText('MORE')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-08-04_20-06-48.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-12-10_19-00-05.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/wsefdqwefdwedfe-1920x1161.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/srgsfsdfgdsfg-696x421.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Nukusda maxsus amaliy o‘quv mashg‘ulotlari o‘tkazildi</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-12-10_19-00-04-2-696x420.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-12-16_12-10-15.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Mudofaa vazirligida Konstitutsiya kuni munosib nishonlandi</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/srgsfsdfgdsfg-696x421.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-12-10_19-00-04-2-696x420.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-12-16_12-10-15.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-08-04_20-06-48.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/photo_2022-12-10_19-00-05.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 my-2">*/}
                            {/*    <div className="card" style={{width: "100%"}}>*/}
                            {/*        <img className="card-img-top" src={require("./assets/wsefdqwefdwedfe-1920x1161.jpg")} alt="Card image"*/}
                            {/*             style={{width: "100%"}}/>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <span className="cardDate">*/}
                            {/*                <img src={require("./assets/calendar.jpg")} alt=""/>*/}
                            {/*                <p>13.12.2022</p>*/}
                            {/*            </span>*/}
                            {/*            <div className="newCardText">*/}
                            {/*                <p className="card-text">Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</p>*/}
                            {/*                <Link to={`/batafsil/${1}`} className="btn">Batafsil...</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>



                <div className="d-flex justify-content-end my-4">
                    <Pagination
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        page={currentPage+1}
                        onChange={handleChangePage}
                    />
                </div>

            </div>

        </div>
    );
}

export default JanubiyGarbiy;