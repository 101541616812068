import './Navbar.scss';
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {getText} from "../Translation";
import {useDispatch, useSelector} from "react-redux";
import {UPDATE_LANGUAGE} from "../../redux/actionTypes/actionTypes";
import markaz from "./assets/markaz.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Button, ClickAwayListener} from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import SizeTest from "./SizeTest";
import {addStyle, addStyle1, addStyle2, addStyle3} from "../../redux/actions/spacialAction";
import AuthUser from "../Auth/AuthUser";


function Navbar() {

    const currentDate = new Date()
    const {http} = AuthUser()
    const [show_menu, setShoeMenu] = useState(false);
    const [newsType, setNewsType] = useState();
    let oy;
    switch (new Date().getMonth() + 1) {
        case 1:
            oy = "Yanvar";
            break;
        case 2:
            oy = "Fevral";
            break;
        case 3:
            oy = "Mart";
            break;
        case 4:
            oy = "Aprel";
            break;
        case 5:
            oy = "May";
            break;
        case 6:
            oy = "Iyun";
            break;
        case 7:
            oy = "Iyul";
        case 8:
            oy = "Avgust";
        case 9:
            oy = "Sentyabr";
        case 10:
            oy = "Oktyabr";
        case 11:
            oy = "Noyabr";
        case 12:
            oy = "Dekabr";
    }
    let day;

    switch (new Date().getDay()) {
        case 1:
            day = "Dushanba";
            break;
        case 2:
            day = "Seshanba";
            break;
        case 3:
            day = "Chorshanba";
            break;
        case 4:
            day = "Payshanba";
            break;
        case 5:
            day = "Juma";
            break;
        case 6:
            day = "Shanba";
            break;
        case 0:
            day = "Yakshanba";
    }
    const options = {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'};

    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') === null ? localStorage.getItem('language') : 'uz');

    const dispatch = useDispatch();
    const {fontSize} = useSelector(state => state.special)
    // const {language} = useSelector(state => state.language)

    // const {fontSize} = useSelector(state => state.special)
    // console.log(fontSize)


    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        dispatch({
            type: UPDATE_LANGUAGE,
            payload: event.target.value
        })

    };

    if (selectedLanguage === 'en') {
        localStorage.setItem('language', 'en')
    } else if (selectedLanguage === 'ru') {
        localStorage.setItem('language', 'ru')
    } else if (selectedLanguage === 'uz') {
        localStorage.setItem('language', 'uz')
    }

    const [imageStyle, setImageStyle] = useState({

    });

    const {addStyle1} = useSelector(state => state.special)
    const {addStyle2} = useSelector(state => state.special)
    const {addStyle3} = useSelector(state => state.special)
    const {addStyle} = useSelector(state => state.special)
    const {language} = useSelector(state => state.language)
    useEffect(() => {
        http.get('user/postType/list',{
            params:{
                lang: language,
            }
        }).then((res)=>{
            // console.log(res.data.object)
            setNewsType(res.data.object)
        }).catch((e)=>{
            // console.log(e.message)
        })


        if (addStyle1) {
            setImageStyle({
                backgroundColor: "#9DD1FF",
                color:"black",
                // Add additional styles or modifications here
            });
        }
        if (addStyle2) {
            setImageStyle({
                color:"white",
                backgroundColor: "#464646",
                // zIndex: '1',
                // position: "sticky",
                // top: '0'
                // Add additional styles or modifications here
            });
        }
        if (addStyle3) {
            setImageStyle({
                backgroundColor: "#1c1c1c",
                color:"white",
                // Add additional styles or modifications here
            });
        }
        if (addStyle){
            setImageStyle({
                color: "black",
            });
        }
    }, [addStyle1, addStyle2, addStyle3, addStyle, language]);


    return (
        <div style={imageStyle} >
            <div className="header-top" >
                <div className="navTopLeft" >
                    <img src={require('./assets/watch.png')} alt=""/>
                    {

                        localStorage.getItem('language')==="en" ? <p>{`${currentDate.toLocaleDateString('Eng', options)}`}</p> :
                            localStorage.getItem('language')==="ru" ? <p>{`${currentDate.toLocaleDateString('Ru', options)}`}</p> :
                                localStorage.getItem('language')==="uz" ? <p>{`${day} ${new Date().getDate()}-${oy} ${new Date().getFullYear()}`}</p> :
                                    <p>{`${day} ${new Date().getDate()}-${oy} ${new Date().getFullYear()}`}</p>

                    }

                    {/*<p>{`${day} ${new Date().getDate()}-${oy} ${new Date().getFullYear()}`}</p>*/}
                    {/*<p>{`${currentDate.toLocaleDateString('Eng', options)}`}</p>*/}
                    {/*<p>{`${currentDate.toLocaleDateString('Ru', options)}`}</p>*/}
                    {/*<p>{`${currentDate.toLocaleDateString('uz-Cyrl-UZ', options)}`}</p>*/}
                </div>
                <div className="navTopImg">
                    <a href="https://www.facebook.com/mudofaavazirligi"><img src={require('./assets/facebook.png')} alt=""/></a>
                    <a href="https://www.instagram.com/mudofaavazirligi/"><img src={require('./assets/instagram2.png')} alt=""/></a>
                    <a href="#"> <img src={require('./assets/mail.png')} alt=""/></a>
                    <a href="https://t.me/mudofaavazirligi"><img src={require('./assets/telegramlogo.png')} alt=""/></a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fmudofaarasmiy"><img src={require('./assets/twitter.png')} alt=""/></a>
                    <a href="https://www.youtube.com/@UzArmiya"><img src={require('./assets/youtube.png')} alt=""/></a>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-light" >
                <div className="container-fluid" style={imageStyle}>
                    <div className='navLogo'>
                        {/*<Link className="navbar-brand" to={'/'}><img  src={require('./images/logo.png')} alt=""/></Link>*/}
                        <Link className="navbar-brand" to={'/'}>
                            <img src={markaz} alt=""/>
                            <span>
                            <h2>{getText('MINISTRY')}</h2>
                            <h2>{getText('DEFENSE')}</h2>
                            </span>
                        </Link>
                    </div>
                    <button className="navbar-toggler" onClick={() => setShoeMenu(false)} type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <img src={require("./assets/menu1.png")} alt=""/>
                        {/*<span className="navbar-toggler-icon"/>*/}
                    </button>
                    <div style={imageStyle}  className={`${show_menu ? "collapse navbar-collapse d-none" : "collapse navbar-collapse"}`}
                         id="navbarSupportedContent">
                        <ul  className="navbar-nav me-auto mb-2 mb-lg-0" >
                            <li  className="nav-item dropdown">
                                <Link style={{fontSize:`${18 + Number(fontSize) }px`, lineHeight:`${12 + Number(fontSize) }px`}} className="nav-link dropdown-toggle" to="/vazirlik-haqida" role="button"
                                   aria-expanded="false">
                                    {getText('ABOUT_THE_MINISTRY')}

                                </Link>
                                <ul className="dropdown-menu" style={imageStyle}>
                                    <li><Link style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" to="/rahbaryat">{getText('MANAGEMENT')}</Link></li>
                                    <li><Link style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" to="/qurolli-kuchlar-tarixi">{getText('FUNCTION_MINISTRY')}</Link></li>
                                    <li><Link style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" to="/mvfunksiya">{getText('HISTORY_ARMED')}</Link></li>
                                    <li><Link style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" to="/boshish">{getText('VACANCIES')}</Link></li>
                                </ul>
                            </li>
                            {/*<li className="nav-item dropdown active">*/}
                            <li className="nav-item dropdown">
                                <Link style={{fontSize:`${18 + Number(fontSize) }px`, lineHeight:`${12 + Number(fontSize) }px`}} className="nav-link dropdown-toggle" to={'/yangiliklar'} role="button"
                                      aria-expanded="false">
                                    {getText('NEWS')}
                                </Link>
                                <ul className="dropdown-menu" style={imageStyle}>
                                    {
                                        newsType?.map((item, index)=>(
                                            <li><Link style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" to={`/newsType/${item.postTypeId}`}
                                                      onClick={() => setShoeMenu(true)}>{item.name}</Link></li>
                                        ))
                                    }
                                    {/*<li><Link className="dropdown-item" to={'/janubiygarbiy'}*/}
                                    {/*          onClick={() => setShoeMenu(true)}>Janibiy-g'arbiy maxsus harbiy okrug</Link></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Markaziy harbiy okrug</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Sharqiy harbiy okrug</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Shimoli-g'arbiy harbiy okrug</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Toshkent harbiy okrug TOQQQ va HHMQvaHHK</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Yoshlar siyosati</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Xalqaro Harbiy hamkorlik</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">E'lonlar</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Autsorser xizmati</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Armiya sporti</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Axborot dayjesti</a></li>*/}
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link style={{fontSize:`${18 + Number(fontSize) }px`, lineHeight:`${12 + Number(fontSize) }px`}} className="nav-link dropdown-toggle" to="/qonunchilik" role="button"
                                   aria-expanded="false">
                                    {getText('LEGISLATION')}
                                </Link>
                                <ul className="dropdown-menu" style={imageStyle}>
                                    <li><a style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" href="#">Mavjud normativ-huquqiy hujjatlar</a></li>
                                    <li><a style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" href="#">O'z kuchuni yo'qotgan normativ-huquqiy hujjatlar.</a></li>
                                    <li><a style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" href="#">Normativ-huquqiy hujjatlar muhokamasi.</a></li>
                                    <li><a style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" href="#">Qabul qilinayotgan normativ-huquqiy hujjatlar loyihalari</a></li>
                                    {/*<li><a className="dropdown-item" href="#" target="new">Davlat xizmatlari</a></li>*/}
                                    <li><a style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" href="https://my.gov.uz/oz/service/243" target="new">Harbiy
                                        ro'yhatdan chiqarish</a></li>
                                    <li><a style={{fontSize:`${18 + Number(fontSize) }px`, }} onClick={() => setShoeMenu(true)} className="dropdown-item" href="https://my.gov.uz/oz/service/242" target="new">Harbiy ro'yhatga qo'yish</a></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link style={{fontSize:`${18 + Number(fontSize) }px`, lineHeight:`${12 + Number(fontSize) }px`}} className="nav-link " to="/ochiq-malumot" role="button"
                                   aria-expanded="false">
                                    {getText('OPEN_DATA')}
                                </Link>
                                {/*<ul className="dropdown-menu">*/}
                                {/*    <li><a className="dropdown-item" href="#">Action</a></li>*/}
                                {/*    <li><a className="dropdown-item" href="#">Another action</a></li>*/}
                                {/*    <li><a className="dropdown-item" href="#">Something else here</a></li>*/}
                                {/*</ul>*/}
                            </li>
                            <li className="nav-item dropdown">
                                <Link style={{fontSize:`${18 + Number(fontSize) }px`, lineHeight:`${12 + Number(fontSize) }px`}} className="nav-link dropdown-toggle" to="/boglanish" role="button"
                                   aria-expanded="false">
                                    {getText('CONTACT')}
                                </Link>
                                <ul className="dropdown-menu" style={imageStyle}>
                                    <li><Link style={{fontSize:`${18 + Number(fontSize) }px`,}} onClick={() => setShoeMenu(true)} className="dropdown-item" to="/boglanishTel">Mudofaa vazirligi bilan bog'lanish</Link></li>
                                    <li><Link style={{fontSize:`${18 + Number(fontSize) }px`,}} onClick={() => setShoeMenu(true)} className="dropdown-item" to="/boglanishTel">Axborot xizmati bilan bog'lanish</Link></li>
                                    <li><Link style={{fontSize:`${18 + Number(fontSize) }px`,}} onClick={() => setShoeMenu(true)} className="dropdown-item" to="/boglanishTel">Viloyat MIB bilan bog'lanish</Link></li>
                                </ul>
                            </li>
                        </ul>
                        <div className="navRight">
                            <select className="form-select" defaultValue={localStorage.getItem('language')} value={selectedLanguage} onChange={handleLanguageChange}>
                                <option value="uz"><a href="#">O'zbekcha</a></option>
                                <option value="en"><a href="#">English</a></option>
                                <option value="ru"><a href="#">Русский</a></option>
                            </select>

                        </div>
                    </div>
                </div>
            </nav>

        </div>
    );
}

export default Navbar;


