import React from 'react';
import './FoydaliMalumotlar.scss'
import {Link} from "react-router-dom";
import huquqiyImg from './assets/Huquqiy-axborot_flayer.pdf'

function FoydaliMalumotlar(props) {
    return (
        <div className="container">
            <div className="row">
                {/*Foydali malumorlar qismi boshlanishi*/}
                <div className="col-md-9 foydaliMal">
                    <div className="d-flex align-items-center">
                        <div className="text-title"><h4>FOYDALI MA’LUMOTLAR</h4></div>
                        <div className="div-title flex-grow-1"></div>
                    </div>
                    <div className="row my-2">
                        <div className="col-lg-6 my-2">
                            <Link to="/korrupsiyaga-qarshi">
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">KORRUPSIYAGA QARSHI KURASHISH</h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm1.png')} alt=""/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-6 my-2">
                            <Link to="/tibbiyot-akademiyasi">
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">HARBIY TIBBIYOT AKADEMIYASI </h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm2.png')} alt=""/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-6 my-2">
                            <Link to="/akademiya">
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">QUROLLI KUCHLAR AKADEMIYASI</h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm3.png')} alt=""/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-6 my-2">
                            <Link to="/gospital">
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">MARKAZIY HARBIY KLINIK GOSPITALI</h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm4.png')} alt=""/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-6 my-2">
                            <a href="https://harbiymeros.uz/uz/" target="new">
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">HARBIY MEROS VA ZAMONAVIY TADQIQOTLAR
                                                INSTITUTI</h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm5.png')} alt=""/>
                                    </div>
                                </div>
                            </a>
                            {/*<Link to="/harbiy-meros">*/}
                            {/*    <div className="card cardBottom">*/}
                            {/*        <div className="d-flex">*/}
                            {/*            <div className="card-body 100 d-flex justify-content-center align-items-center">*/}
                            {/*                <h6 className="text-center">HARBIY MEROS VA ZAMONAVIY TADQIQOTLAR*/}
                            {/*                    INSTITUTI</h6>*/}
                            {/*            </div>*/}
                            {/*            <img width="50%" src={require('./assets/rasm55.png')} alt=""/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Link>*/}
                        </div>
                        <div className="col-lg-6 my-2">
                            <a href="#">
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">IJTIMOIY HIMOYA</h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm6.png')} alt=""/>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 my-2">
                            <a href={huquqiyImg} target='_blank'>
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">HUQUQIY MASLAXATXONA</h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm7.png')} alt=""/>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 my-2">
                            <Link to="/jam-ken">
                                <div className="card cardBottom">
                                    <div className="d-flex">
                                        <div className="card-body 100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-center">JAMOATCHILIK KENGASHI</h6>
                                        </div>
                                        <img width="50%" src={require('./assets/rasm8.png')} alt=""/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
                {/*Foydali malumorlar qismi tugashi*/}

                <div className="col-md-3 foydaliHavola">
                    <div className="d-flex align-items-center">
                        <div className="text-title"><h4>FOYDALI HAVOLALAR</h4></div>
                        <div className="div-title flex-grow-1"></div>
                    </div>

                    <div className="foydaliCards">
                        <a href="https://president.uz/oz">
                            <div className="card mt-3">
                                <div className="row g-0">
                                    <div className="col-md-3 imgHavola">
                                        <img src={require('./assets/img1.png')} alt=""/>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="card-body h-100">
                                            <h6 className="text-center">O'ZBEKISTON RESPUBLIKASI PREZIDENTINING RASMIY
                                                VEB
                                                SAYTI</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://data.gov.uz/uz">
                            <div className="card my-2">
                                <div className="row g-0">
                                    <div className="col-md-3 imgHavola">
                                        <img src={require('./assets/img2.png')} alt=""/>
                                    </div>
                                    <div className="col-md-9 ">
                                        <div className="card-body h-100">
                                            <h6 className="text-center">OCHIQ MA'LUMOTLAR TO'PLAMI</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://prokuratura.uz/#/">
                            <div className="card my-2">
                                <div className="row g-0">
                                    <div className="col-md-3 imgHavola">
                                        <img src={require('./assets/img3.png')} alt=""/>
                                    </div>
                                    <div className="col-md-9 ">
                                        <div className="card-body h-100">
                                            <h6 className="text-center">O'ZBEKISTON PRAKRATURASI</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://huquqiyportal.uz/">
                            <div className="card my-2">
                                <div className="row g-0">
                                    <div className="col-md-3 imgHavola">
                                        <img src={require('./assets/img4.png')} alt=""/>
                                    </div>
                                    <div className="col-md-9 ">
                                        <div className="card-body h-100">
                                            <h6 className="text-center">O'ZBEKISTON RESPUBLIKASI MILLIY HUQUQIY INTERNET
                                                PORTALI</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://parliament.gov.uz/">
                            <div className="card my-2">
                                <div className="row g-0">
                                    <div className="col-md-3 imgHavola">
                                        <img src={require('./assets/img5.png')} alt=""/>
                                    </div>
                                    <div className="col-md-9 ">
                                        <div className="card-body h-100">
                                            <h6 className="text-center">O'ZBEKISTON RESPUBLIKASI QONUNCHILIK
                                                PALATASI</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://prokuratura.uz/#/">
                            <div className="card my-2">
                                <div className="row g-0">
                                    <div className="col-md-3 imgHavola">
                                        <img src={require('./assets/img6.png')} alt=""/>
                                    </div>
                                    <div className="col-md-9 ">
                                        <div className="card-body h-100">
                                            <h6 className="text-center">O'ZBEKISTON PRAKRATURASI</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://data.gov.uz/uz">
                            <div className="card my-2">
                                <div className="row g-0">
                                    <div className="col-md-3 imgHavola">
                                        <img src={require('./assets/img2.png')} alt=""/>
                                    </div>
                                    <div className="col-md-9 ">
                                        <div className="card-body h-100">
                                            <h6 className="text-center">OCHIQ MA'LUMOTLAR TO'PLAMI</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FoydaliMalumotlar;