import React, {useEffect, useState} from 'react';
import './SongiYangilik.scss'
import {getText} from "../Translation";
import {useSelector} from "react-redux";
import rasm1 from "./assets/photo_2022-08-04_20-06-48.jpg"
import rasm2 from "./assets/Rasm1.jpg"
import rasm3 from "./assets/rasm2.jpg"
import {Link} from "react-router-dom";
import axios from "axios";
import AuthUser from "../Auth/AuthUser";
import {PATH_NAME_AUTH} from "../../tools/constant";
import eyeIcon from "../Batafsil/assets/eye-icon.png";

function SongiYangilik(props) {
    const {http} = AuthUser()
    const [postList, setPostList] = useState([])
    const [popularPosts, setPopularPosts] = useState([])
    const {fontSize} = useSelector(state => state.special)
    const {language} = useSelector(state => state.language);

    const {addStyle2} = useSelector(state => state.special)
    const {addStyle3} = useSelector(state => state.special)
    const {addStyle1} = useSelector(state => state.special)
    const {addStyle} = useSelector(state => state.special)


    const getPopularPost = ()=>{
        http.get('user/post/topView',{
            params:{
                lang: language,
            }
        }).then(res => {
            setPopularPosts(res.data.object.postComponentList.content)
            // console.log(res.data.object.postComponentList.content)
        }).catch(e => {
            e.error("Bog'lanishda xatolik!")
        })
    }


    useEffect(() => {

        http.get('user/post/list',{
            params:{
                size: 8,
                page: 0,
                lang: language,
                postTypeId: 0


            }
        }).then(res => {
                setPostList(res.data.object.postComponentList.content)
            // console.log(res.data.object.postComponentList.content)
            }).catch(e => {
            e.error("Bog'lanishda xatolik!")
        })
        getPopularPost()
    }, [addStyle3, addStyle2, addStyle1, addStyle, language])

    return (
        <div className="container songi-yangilik">
            <div className="row">

                {/*So'ngi yangiliklar qismi boshlanishi*/}

                <div className="col-lg-9">
                    <div className="d-flex align-items-center">
                        <div className="text-title" style={{border:  `2px solid ${addStyle3 || addStyle2 ? "white" :  "#0f1b63"}` }}>
                            <h4 style={{color: `${addStyle3 || addStyle2 ? "white" :  "black"}` }}>{getText('LATEST_NEWS')}</h4></div>
                        <div className="div-title flex-grow-1" style={{border:  `1px solid ${addStyle3 || addStyle2 ? "white" :  "#0f1b63"}` }}></div>
                    </div>
                    <div className="row">
                        {/*<div className="col-md-12 cardBig">*/}
                        {/*    <Link to={`/batafsil/${1}`} className="no-decoration">*/}
                        {/*    <div className="row my-3 g-0 cardTop">*/}
                        {/*        <div className="col-lg-6">*/}
                        {/*            <div className="songiImg">*/}
                        {/*                <img height="100%" src={rasm1}*/}
                        {/*                     className="w-100"*/}
                        {/*                     alt=""/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-6">*/}
                        {/*            <div className="card-body p-4 h-100 d-flex flex-column justify-content-between">*/}
                        {/*                <h5>Toshkent harbiy okrugi</h5>*/}
                        {/*                <h6>Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</h6>*/}
                        {/*                <p>Mudofaa vazirligi Markaziy apparatida Oʻzbekiston Respublikasi Konstitutsiyasining 30*/}
                        {/*                    yilligiga bagʻishlab barcha harbiy okrug, markazga boʻysunuvchi harbiy qism va*/}
                        {/*                    muassasalar bilan onlayn aloqa oʻrnatilgan holda “Konstitutsiya – bosh qomusimiz”*/}
                        {/*                    mavzusida maʼnaviy-maʼrifiy tadbir oʻtkazildi.</p>*/}
                        {/*                <div className="d-flex justify-content-between align-items-center">*/}
                        {/*                    <a href="">{getText("READ_MORE")}</a>*/}
                        {/*                    <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*        </Link>*/}
                        {/*</div>*/}
                        {
                            postList?.map((value, index)=>(

                                <div key={index} className="col-md-6">

                                    <div className="cardBottom my-2">

                                        <Link to={`/batafsil/${value.postId}`} className="no-decoration">
                                            <div className="row ">
                                                <div className="col-md-5 p-0 songiImg">
                                                    <img className="w-100 " src={value ? `${PATH_NAME_AUTH}${value.pathPhoto}` : null} alt=""/>
                                                </div>
                                                <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">
                                                    <h6 style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`, color: `${addStyle3 || addStyle2 ? "white" :  "black"}` }}>{value.title}</h6>
                                                    {/*<p className="cardDate">08.12.2022</p>*/}
                                                    <span className="d-flex  align-items-center ">
                                                        <div className='d-flex align-items-center gap-1'>
                                                            <img src={require('./assets/calendar.jpg')} alt=""/>
                                                            <p>{value.publicationTime}</p>
                                                        </div>
                                                        <div className='d-flex align-items-center gap-1'>
                                                            <img height="20px" src={eyeIcon} alt="" className="blue-eye"/>
                                                            <p>{value?.totalViews}</p>
                                                        </div>


                                                    </span>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>

                                </div>
                            ))
                        }


                        {/*<div className="col-md-6">*/}
                        {/*    <div className="cardBottom my-2">*/}
                        {/*        <div className="row ">*/}
                        {/*            <div className="col-md-5 p-0 songiImg">*/}
                        {/*                <img className="w-100 " src={rasm3} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">*/}
                        {/*                <h6>Toshkent harbiy okrugi</h6>*/}
                        {/*                <p className="titleText">Markaziy harbiy okrugda mutaxassislarning kasb bayrami*/}
                        {/*                    nishonlandi</p>*/}
                        {/*                <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="cardBottom my-2">*/}
                        {/*        <div className="row ">*/}
                        {/*            <div className="col-md-5 p-0 songiImg">*/}
                        {/*                <img className="w-100 " src={rasm1} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">*/}
                        {/*                <h6>Toshkent harbiy okrugi</h6>*/}
                        {/*                <p className="titleText">Markaziy harbiy okrugda mutaxassislarning kasb bayrami*/}
                        {/*                    nishonlandi</p>*/}
                        {/*                <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="cardBottom my-2">*/}
                        {/*        <div className="row ">*/}
                        {/*            <div className="col-md-5 p-0 songiImg">*/}
                        {/*                <img className="w-100 " src={rasm2} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">*/}
                        {/*                <h6>Toshkent harbiy okrugi</h6>*/}
                        {/*                <p className="titleText">Markaziy harbiy okrugda mutaxassislarning kasb bayrami*/}
                        {/*                    nishonlandi</p>*/}
                        {/*                <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="cardBottom my-2">*/}
                        {/*        <div className="row ">*/}
                        {/*            <div className="col-md-5 p-0 songiImg">*/}
                        {/*                <img className="w-100 " src={rasm3} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">*/}
                        {/*                <h6>Toshkent harbiy okrugi</h6>*/}
                        {/*                <p className="titleText">Markaziy harbiy okrugda mutaxassislarning kasb bayrami*/}
                        {/*                    nishonlandi</p>*/}
                        {/*                <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="cardBottom my-2">*/}
                        {/*        <div className="row ">*/}
                        {/*            <div className="col-md-5 p-0 songiImg">*/}
                        {/*                <img className="w-100 " src={rasm1} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">*/}
                        {/*                <h6>Toshkent harbiy okrugi</h6>*/}
                        {/*                <p className="titleText">Markaziy harbiy okrugda mutaxassislarning kasb bayrami*/}
                        {/*                    nishonlandi</p>*/}
                        {/*                <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="cardBottom my-2">*/}
                        {/*        <div className="row ">*/}
                        {/*            <div className="col-md-5 p-0 songiImg">*/}
                        {/*                <img className="w-100 " src={rasm2} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">*/}
                        {/*                <h6>Toshkent harbiy okrugi</h6>*/}
                        {/*                <p className="titleText">Markaziy harbiy okrugda mutaxassislarning kasb bayrami*/}
                        {/*                    nishonlandi</p>*/}
                        {/*                <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="cardBottom my-2">*/}
                        {/*        <div className="row ">*/}
                        {/*            <div className="col-md-5 p-0 songiImg">*/}
                        {/*                <img className="w-100 " src={rasm3} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-7 p-2 card-body d-flex flex-column justify-content-around cardBottomText">*/}
                        {/*                <h6>Toshkent harbiy okrugi</h6>*/}
                        {/*                <p className="titleText">Markaziy harbiy okrugda mutaxassislarning kasb bayrami*/}
                        {/*                    nishonlandi</p>*/}
                        {/*                <span className="d-flex">*/}
                        {/*            <img src={require('./images/calendar.jpg')} alt=""/>*/}
                        {/*            <p>13.12.2022</p>*/}
                        {/*        </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <Link to={'/yangiliklar'} className="btnAll my-2 "
                              style={{backgroundColor: `${addStyle3 || addStyle2 || addStyle === false || addStyle1 === false ? "#0f1b63" :  "white"}` }}
                        >{getText("READ_MORE")}</Link>





                    </div>

                </div>
                {/*So'ngi yangiliklar qismi tugashi*/}











                {/* Ko'p oqilgan qismi boshlanishi*/}

                <div className="col-lg-3 h-auto lot">
                    <div className="d-flex align-items-center ">
                        <div className="text-title2" style={{border:  `2px solid ${addStyle3 || addStyle2 ? "white" :  "#0f1b63"}` }}>
                            <h4 style={{color: `${addStyle3 || addStyle2 ? "white" :  "black"}` }}>{getText("POPULAR_POSTS")}</h4></div>
                        <div className="div-title2 flex-grow-1" style={{border:  `1px solid ${addStyle3 || addStyle2 ? "white" :  "#0f1b63"}` }}>
                        </div>
                    </div>

                    <div className="card my-3">
                        <div className="card-body ">
                            {
                                popularPosts?.map((item, index)=>(
                                    <div className="readLot">
                                        <Link to={`/batafsil/${item.postId}`}>
                                            <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{item.title}</p>
                                            <span className="d-flex align-items-center">
                                                <div className='d-flex align-items-center gap-1'>
                                                    <img src={require('./assets/calendar.jpg')} alt=""/>
                                                    <p>{item.publicationTime}</p>
                                                </div>
                                                <div className='d-flex align-items-center gap-1'>
                                                   <img height="20px" src={eyeIcon} alt="" className="blue-eye"/>
                                                   <p>{item?.totalViews}</p>
                                                   </div>
                                            </span>
                                        </Link>
                                        <hr/>
                                    </div>
                                ))
                            }
                            {/*<div className="readLot">*/}
                            {/*    <Link to={`/batafsil/${postList[0]?.postId}`}>*/}
                            {/*        <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{postList[0]?.title}</p>*/}
                            {/*        <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>{postList[0]?.publicationTime}</p>*/}
                            {/*    </span>*/}
                            {/*    </Link>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <Link to={`/batafsil/${postList[1]?.postId}`}>*/}
                            {/*        <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{postList[1]?.title}</p>*/}
                            {/*        <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>{postList[1]?.publicationTime}</p>*/}
                            {/*    </span>*/}
                            {/*    </Link>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <Link to={`/batafsil/${postList[2]?.postId}`}>*/}
                            {/*        <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{postList[2]?.title}</p>*/}
                            {/*        <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>{postList[2]?.publicationTime}</p>*/}
                            {/*    </span>*/}
                            {/*    </Link>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <Link to={`/batafsil/${postList[3]?.postId}`}>*/}
                            {/*        <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{postList[3]?.title}</p>*/}
                            {/*        <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>{postList[3]?.publicationTime}</p>*/}
                            {/*    </span>*/}
                            {/*    </Link>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <Link to={`/batafsil/${postList[4]?.postId}`}>*/}
                            {/*        <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{postList[4]?.title}</p>*/}
                            {/*        <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>{postList[4]?.publicationTime}</p>*/}
                            {/*    </span>*/}
                            {/*    </Link>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <Link to={`/batafsil/${postList[5]?.postId}`}>*/}
                            {/*        <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{postList[5]?.title}</p>*/}
                            {/*        <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>{postList[5]?.publicationTime}</p>*/}
                            {/*    </span>*/}
                            {/*    </Link>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <Link to={`/batafsil/${postList[6]?.postId}`}>*/}
                            {/*        <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>{postList[6]?.title}</p>*/}
                            {/*        <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>{postList[6]?.publicationTime}</p>*/}
                            {/*    </span>*/}
                            {/*    </Link>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>Termizda armiya o‘yinlari sport musobaqasiga start berildi.</p>*/}
                            {/*    <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>13.12.2022</p>*/}
                            {/*    </span>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>Gripp va oʻtkir respirator kasalliklardan himoyalanish choralari</p>*/}
                            {/*    <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>13.12.2022</p>*/}
                            {/*    </span>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                            {/*<div className="readLot">*/}
                            {/*    <p style={{fontSize:`${15 + Number(fontSize) }px`, lineHeight:`${19 + Number(fontSize) }px`}}>Markaziy harbiy okrugda mutaxassislarning kasb bayrami nishonlandi</p>*/}
                            {/*    <span className="d-flex">*/}
                            {/*        <img src={require('./assets/calendar.jpg')} alt=""/>*/}
                            {/*        <p>13.12.2022</p>*/}
                            {/*    </span>*/}
                            {/*    <hr/>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                </div>

                {/* Ko'p oqilgan qismi tugashi*/}

            </div>
        </div>
    );
}

export default SongiYangilik;