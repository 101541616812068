import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import {useDispatch, useSelector} from "react-redux";
import {editFontSize} from "../../redux/actions/spacialAction";
import {useEffect} from "react";

export default function SizeTest() {
    const [valueSize, setValueSize] = React.useState(0);

    const {fontSize} = useSelector(state => state.special)
    // console.log(fontSize)

    const handleChange = (event, newValue) => {
        dispatch(editFontSize(newValue))

        if (typeof newValue === 'number') {
            // setValueSize(newValue);
            localStorage.setItem('fontSize', newValue)
        }
    };

    const dispatch=useDispatch()

    let value = `${localStorage.getItem('fontSize')}`


    return (
        <Box>
            <Typography id="non-linear-slider" gutterBottom>
                Shrift o'lchami: {fontSize}
            </Typography>
            <Slider
                value={fontSize}
                min={0}
                step={3}
                max={15}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
            />
        </Box>
    );
}