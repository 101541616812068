import React from 'react';

function Rahbariyat(props) {
    return (
        <div className="container">
            <h3 className="mt-3 mb-2">Rahbariyat</h3>
            <h5>Mudofaa vaziri</h5>
            <p>general-leytenant</p>
            <h5>KURBANOV Baxodir Nizomovich</h5>
            <p><b>Qabul kunlari:</b> har oyning ikkinchi seshanba kuni, soat 17:00 dan 18:00 gacha.</p>
            <br/>
            <br/>
            <br/>
            <h5>Mudofaa vazirining birinchi o‘rinbosari – Qurolli Kuchlar Bosh shtabi boshlig‘i</h5>
            <p>general-mayor</p>
            <h5>XALMUXAMEDOV Shuhrat G‘ayratjonovich </h5>
            <p><b>Qabul kunlari:</b> har oyning ikkinchi seshanba kuni, soat 10:00 dan 18:00 gacha.</p>
            <br/>
            <br/>
            <br/>
            <h5>Mudofaa vazirining tarbiyaviy va mafkuraviy ishlar bo‘yicha o‘rinbosari</h5>
            <p>general-mayor</p>
            <h5>KARSHIYEV Hamdam Aliqulovich </h5>
            <p><b>Qabul kunlari:</b> har oyning uchinchi chorshanbasi, soat 10:00 dan 13:00 gacha.</p>
            <br/>
            <br/>
            <br/>
            <h5>Mudofaa vazirining qurollanish va qurollantirish bo‘yicha o‘rinbosari</h5>
            <p>polkovnik</p>
            <h5>NORBOYEV Alisher To‘xtayevich</h5>
            <p><b>Qabul kunlari:</b> har oyning to‘rtinchi payshanba kuni, soat 10:00 dan 18:00 gacha.</p>
            <br/>
            <br/>
            <br/>
            <h5>Mudofaa vazirining jangovar tayyorgarlik bo‘yicha o‘rinbosari</h5>
            <p>polkovnik</p>
            <h5>TURSUNOV Kadirjon Abdipattaevich</h5>
            <p><b>Qabul kunlari:</b> har oyning to`rtinchi shanbasi soat 10:00 dan 18:00 gacha.</p>
            <br/>
            <br/>
            <br/>
            <h5>Mudofaa vazirining moddiy-texnik ta’minot bo‘yicha o‘rinbosari</h5>
            <p>polkovnik</p>
            <h5>BOZORBOYEV Zoirjon Gulimmatovich</h5>
            <p><b>Qabul kunlari:</b> har oyning birinchi payshanba kuni, soat 10:00 dan 13:00 gacha.</p>
            <br/>
            <br/>
            <br/>
            <h5>Mudofaa vazirining o‘rinbosari – Havo hujumidan mudofaa qo‘shinlari va Harbiy havo kuchlari qo‘mondoni</h5>
            <p>general-mayor</p>
            <h5>BURXANOV Ahmad Jamolovich</h5>
            <p><b>Qabul kunlari:</b> har oyning ikkinchi chorshanbasi, soat 10:00 dan 18:00 gacha.</p>
            <br/>
            <br/>
            <br/>
            <h5>Mudofaa vazirligi Toifalangan ob’ektlarni qo‘riqlash qo‘shinlari qo‘mondoni</h5>
            <p>polkovnik</p>
            <h5>YАKUBOV Jur’at Amangeldievich</h5>
            <p><b>Qabul kunlari:</b> har oyning ikkinchi va uchinchi chorshanbasi, soat 10:00 dan 13:00 gacha.</p>
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default Rahbariyat;