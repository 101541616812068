import React from 'react';
import img404 from './page-not-found-erro-404.jpg'
import i404img from './404-error-page-not-found.jpg'

function NotFound(props) {
    return (
        <div className="container" style={{width:'100%', height:"80vh"}}>
            <img style={{width:"100%", height:"100%"}} src={i404img} alt=""/>
        </div>
    );
}

export default NotFound;