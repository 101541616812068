import React, {useEffect, useState} from 'react';
import "./Footer.scss"
import FooterYangilik from "./FooterYangilik";
import {useSelector} from "react-redux";

function Footer(props) {

    const [imageStyle, setImageStyle] = useState({

    });

    const {addStyle1} = useSelector(state => state.special)
    const {addStyle2} = useSelector(state => state.special)
    const {addStyle3} = useSelector(state => state.special)
    const {addStyle} = useSelector(state => state.special)
    useEffect(() => {
        if (addStyle1) {
            setImageStyle({
                backgroundColor: "#9DD1FF",
                color:"black",
                // Add additional styles or modifications here
            });
        }
        if (addStyle2) {
            setImageStyle({
                color:"white",
                backgroundColor: "#464646",
                // Add additional styles or modifications here
            });
        }
        if (addStyle3) {
            setImageStyle({
                backgroundColor: "#1c1c1c",
                color:"white",
                // Add additional styles or modifications here
            });
        }
        if (addStyle){
            setImageStyle({
                color: "black"
            });
        }
    }, [addStyle1, addStyle2, addStyle3, addStyle]);
    return (
        <div className="footer" style={imageStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footNew my-3">
                            <FooterYangilik/>
                        </div>
                    </div>
                    <div className="col-md-4 fCard1">
                        <div className="fLogo">
                            <img src={require('./assets/markaz 5.png')} alt=""/>
                            <img src={require('./assets/MUDOFAA VAZIRLIGI.png')} alt=""/>
                        </div>
                        {/*<p>Saytda xatolik topsangiz, uni tanlang va Ctrl+Enter tugmasini bosing</p>*/}
                    </div>
                    <div className="col-md-4 fCard2">
                        <h2>XABAR</h2>
                        <a href="#">info@mudofaa.uz</a>
                        <a href="">press@mudofaa.uz</a>
                    </div>
                    <div className="col-md-4 fCard3">
                        {/*<form className="d-flex">*/}
                        {/*    <input className="form-control me-2" type="search" placeholder="Qidiruv" aria-label="Search"/>*/}
                        {/*        <button className="btn btn-primary" type="submit">Izlash</button>*/}
                        {/*</form>*/}
                        <div className="footMesImg">
                            <a href="">
                                <img src={require('./assets/telegramlogo.png')} alt=""/>
                            </a>
                            <a href="">
                                <img src={require('./assets/youtube.png')} alt=""/>
                            </a>
                            <a href="">
                                <img src={require('./assets/facebook.png')} alt=""/>
                            </a>
                            <a href="">
                                <img src={require('./assets/twitter.png')} alt=""/>
                            </a>
                            <a href="">
                                <img src={require('./assets/instagram.png')} alt=""/>
                            </a>

                        </div>
                        <div className="footAppImg">
                            <a href="#"><img src={require('./assets/app-store.png')} alt=""/></a>
                            <a href="#"><img src={require('./assets/google-play.png')} alt=""/></a>
                        </div>
                    </div>

                </div>
            </div>
            <div className="footerButtom">
                <div className="container">
                    <p>© {new Date().getFullYear()} O’zbekiston Respublikasi Mudofaa Vazirligining rasmiy veb-sayti</p>
                </div>


            </div>
        </div>
    );
}

export default Footer;