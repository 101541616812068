import React from 'react';

function BoglanishTel(props) {
    return (
        <div className="contactTel">
            <div className="container">
                <h2 className="mt-4 mb-4">Viloyatlar Mudofaa ishlari boshqarmalari bilan bog‘lanish</h2>
                <table className="table table-bordered mb-4 ">
                    <thead>
                    <tr>
                        <th scope="col">Viloyat MIB</th>
                        <th scope="col">Telefon</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Qashqadaryo viloyati</td>
                        <td>075-225-10-90</td>
                    </tr>
                    <tr>
                        <td>Surxondaryo viloyati</td>
                        <td>
                            076-224-44-71</td>
                    </tr>
                    <tr>
                        <td>Buxoro viloyati</td>
                        <td>065-223-20-17</td>
                    </tr>
                    <tr>
                        <td>Farg‘ona viloyati</td>
                        <td>073-244-42-47; 073-244-42-43</td>
                    </tr>
                    <tr>
                        <td>Andijon viloyati</td>
                        <td>074-228-45-02; 228-46-93</td>
                    </tr>
                    <tr>
                        <td>Namangan viloyati</td>
                        <td>
                            069-234-41-10</td>
                    </tr>
                    <tr>
                        <td>Navoiy viloyati</td>
                        <td>079-224-79-81; 079-224-89-71</td>
                    </tr>
                    <tr>
                        <td>Samarqand viloyati</td>
                        <td>066-233-53-15</td>
                    </tr>
                    <tr>
                        <td>Jizzax viloyati</td>
                        <td>072-223-80-26</td>
                    </tr>
                    <tr>
                        <td>Toshkent shahar</td>
                        <td>071-283-83-58; 071-256-15-21</td>
                    </tr>
                    <tr>
                        <td>Toshkent viloyati</td>
                        <td>071-283-84-38</td>
                    </tr>
                    <tr>
                        <td>Sirdaryo viloyati</td>
                        <td>067-225-10-53</td>
                    </tr>
                    <tr>
                        <td>Qoraqalpog‘iston Respublikasi</td>
                        <td>061-222-24-51; 222-89-32</td>
                    </tr>
                    <tr>
                        <td>Xorazm viloyati</td>
                        <td>062-225-40-95; 225-37-66</td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </div>
    );
}

export default BoglanishTel;