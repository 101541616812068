import React from 'react';
import mainImg from "../images/2-1920x1280.jpg";
import './Boshsahifa.scss'
import image from './images/image.jpg'
import image2 from './images/image2.jpg'
import LocalHotelOutlinedIcon from '@mui/icons-material/LocalHotelOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import AssistWalkerOutlinedIcon from '@mui/icons-material/AssistWalkerOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import AirlineSeatReclineExtraOutlinedIcon from '@mui/icons-material/AirlineSeatReclineExtraOutlined';


function Boshsahifa(props) {
    return (
        <div className='boshSahifa'>
            <div className='container'>
                <div className='section1'>
                    <span>
                        <h2>Tibbiyotdagi islohotlar inson qadri uchun</h2>
                        <h4>Sh.Mirziyoyev</h4>
                    </span>
                </div>
                <div className='boxsection1 row'>
                    <div className='col-md-4 my-2'>
                        <div className="card">
                            <div className="card-body">
                                <p>Gospitalda harbitlar, fuqorolar va chet ellik mehmonlarga kech tarmoqli, yuqori malakali, eng zamonaviy va to'laqonli tibbiy xizmat ko'rsatadi.</p>
                                <div>
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 my-2'>
                        <div className="card">
                            <div className="card-body">
                                <p>Bizda qisqa muddatda va aniq tashxislash uchun eng zamonaviy hamda yuqori texnologik tibbiy uskunalar va usullar mavjud.</p>
                                <div>
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 my-2'>
                        <div className="card">
                            <div className="card-body">
                                <p>Bizda tibbiy yordamning engil shaklidan to murakab jarrohlik amalyotlarigacha bajariladi. Bemorlar uchun barcha qulayliklarga ega shinam honalar mavjud.</p>
                                <div>
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className="row">
                        <div className="col-md-6">
                            <div className='section2Img' >
                                <img width="100%" src={image} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='sectoin2Text'>
                                <p>Markaziy harbiy klinik gospitali bilan Respublikamizdagi barcha ixtisoslashtirilgan ilmiy-amaliy tibbiyot markazlari hamda dunyodagi bir necha tibbiyoti rivojlangan davlatlarning yetakchi ilmiy-amaliy tibbiyot markazlari orasida hamkorlik yo'lga qo'yilgan.</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className='sectoin2Text'>
                                <p>Markaziy harbiy klinik gospitali bilan Respublikamizdagi barcha ixtisoslashtirilgan ilmiy-amaliy tibbiyot markazlari hamda dunyodagi bir necha tibbiyoti rivojlangan davlatlarning yetakchi ilmiy-amaliy tibbiyot markazlari orasida hamkorlik yo'lga qo'yilgan.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='section2Img' >
                                <img width="100%" src={image2} alt=""/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className='section3'>
                <div className="ddd">
                    <div className='box' id='box1'>
                        <div className='container'>
                            <div className='textBox'>
                                <h6>Taklif etiladigan</h6>
                                <h3>Yo'nalish va xizmatlar</h3>
                                <p>Istalgan usulda biz bilan bog'laning va yordami
                                    kerak bo'lgan shifokor bilan uchrashuvga
                                    Belgilangan vaqtda bizga tashrif buyuring</p>

                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className='yunalish'>
                                        <LocalHotelOutlinedIcon
                                        style={{fontSize:"50px", color:"#0095EB"}}
                                        />
                                        <h4>Statsionar</h4>
                                        <p>Batafsil</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='yunalish'>
                                        <LocalHospitalOutlinedIcon
                                        style={{fontSize:"50px", color:"#0095EB"}}
                                        />
                                        <h4>Poliklinika</h4>
                                        <p>Batafsil</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='yunalish'>
                                        <BiotechOutlinedIcon
                                        style={{fontSize:"50px", color:"#0095EB"}}
                                        />
                                        <h4>Labaratoriya</h4>
                                        <p>Batafsil</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='yunalish'>
                                        <AirlineSeatReclineExtraOutlinedIcon
                                        style={{fontSize:"50px", color:"#0095EB"}}
                                        />
                                        <h4>Nurli Diagnostika Markazi</h4>
                                        <p>Batafsil</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='yunalish'>
                                        <AssistWalkerOutlinedIcon
                                        style={{fontSize:"50px", color:"#0095EB"}}
                                        />
                                        <h4>Reablitatsiya</h4>
                                        <p>Batafsil</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='yunalish'>
                                        <ImportContactsOutlinedIcon
                                        style={{fontSize:"50px", color:"#0095EB"}}
                                        />
                                        <h4>Tibbiy Ta'lim</h4>
                                        <p>Batafsil</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className='box' id='box2'>
                    </div>
                    <div className='box' id='box3'>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Boshsahifa;