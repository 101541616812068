import React from 'react';

function BoglanishHM(props) {
    return (
        <div className='container'>
            <br/>
            <h4 className='fw-bold text-center'>Bog`lanish</h4>
            <br/>
            <p><b>Manzil:</b> 100023, Toshkent sh., Mirzo Ulug‘bek tumani, Parkent-2 ko‘chasi (O‘R QK Akademiyasining 4-o‘quv binosi 1-qavatida).</p>
            <p>Murojaat uchun</p>
            <p><b>Telefon:</b> (+998 71) 289-97-79 (+998 71) 289-97-47</p>
            <p><b>Faks:</b> (+998 71) 268-02-70</p>
            <p><b>E-mail:</b> merosinstit@umail.uz  merosinstit@mail.ru</p>
            <p><b>E-xat:</b> QK_Harbiy_meros_instituti@exat.mudofaa.uz</p>
            <p><b>Ish vaqti:</b> dushanba-juma – 9:00 dan 18:00 gacha, shanba kuni – soat 09:00 dan 13:00 gacha, tushlik – 13:00 dan 15:00 gacha, dam olish kuni – yakshanba.</p>
        </div>
    );
}

export default BoglanishHM;