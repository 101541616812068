import React from 'react';

function Jamoatchilik1(props) {
    return (
        <div className="container">
            <div className="col-8 mt-3">
                <br/>
                <h2>Jamoatchilik kengashi yig‘ilishida ko‘rib chiqilgan masalalar</h2>
                <br/>
                <p>Mudofaa vazirligi huzuridagi Jamoatchilik kengashining 2023-yil 10-avgust kuni o‘tkazilgan yig‘ilishi kun tartibiga muvofiq ko‘rib chiqilgan masalalar:</p>
                <br/>
                <p>1. Mudofaa vazirligi huzuridagi Jamoatchilik kengashining Reglamentini tasdiqlash;</p>
                <br/>
                <p>2. Mudofaa vazirligi huzuridagi Jamoatchilik kengashi hamda kengashning hududiy bo‘linmalari tomonidan 2023-yilning birinchi yarmida amalga oshirilgan ishlar tahlili va istiqboldagi vazifalar;</p>
                <br/>
                <p>3. O‘zbekiston Respublikasi Prezidentining 2023-yil 27-iyuldagi “O‘zbekiston Respublikasi davlat mustaqilligining o‘ttiz ikki yillik bayramiga tayyorgarlik ko‘rish va uni o‘tkazish to‘g‘risida”gi PQ-231-son Qaroriga muvofiq Jamoatchilik kengashi tomonidan ishlab chiqilgan Reja asosida ishlarni tashkillashtirish.</p>
                <br/>
                <br/>

            </div>
        </div>
    );
}

export default Jamoatchilik1;