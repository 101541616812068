import React from 'react';

function Boglanish(props) {
    return (
        <div className="contact container" >
            <div className="m-4" >
                <div className="card mb-4" style={{zIndex:"-11111"}}>
                    <div className="card-body shadow">
                        <div className="row">
                            <div className="col-md-6" >
                                <h3 className="mb-2 fw-bold">Bog‘lanish</h3>
                                <hr />

                                <p className="mb-2 mt-4">Manzil:100000, Toshkent shahri, Mirzo Ulug‘bek ko‘chasi, 100</p>
                                <p className="mb-2">Faks: (+99871) 262-98-68, 262-82-28</p>
                                <p className="mb-2">Tel: (+99871) 269-81-72, 269-81-50</p>
                                <p className="mb-2">Web-sayt: www.mudofaa.uz</p>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-end">
                                <button className="btn btn-warning me-5">
                                    <a style={{textDecoration: "none", color: "white"}} href="">Bog'lanish ma'lumotlari</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-body shadow">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="mb-2 fw-bold">Mudofaa vazirligi Markaziy davlat arxivi</h3>
                                <hr />

                                <p className="mb-2 mt-4">Email:markaziy_arxiv2023@mail.ru</p>
                                <p className="mb-2">Tel: (+99871) 216-10-41</p>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-end">
                                <button className="btn btn-warning me-5">
                                    <a style={{textDecoration: "none", color: "white"}} href="">Bog'lanish ma'lumotlari</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-body shadow">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="mb-2 fw-bold">Matbuot xizmati bilan bog‘lanish</h3>
                                <hr />

                                <p className="mb-2 mt-4">Mudofaa vazirligi Axborot va ommaviy kommunikatsiyalar departamenti Elektron ommaviy axborot vositalari va Web-resurslari bilan ishlash bo`limi</p>
                                <p className="mb-2">Mas`ul shaxs: Xo`jayev Muhammadjon Shuhrat o`g`li</p>
                                <p className="mb-2">Tel: (+99871) 260-38-10</p>
                                <p className="mb-2">E-mail: press@mudofaa.uz</p>
                                <p className="mb-2">Web-sayt: www.mudofaa.uz</p>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-end">
                                <button className="btn btn-warning me-5">
                                    <a style={{textDecoration: "none", color: "white"}} href="">Bog'lanish ma'lumotlari</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Boglanish;