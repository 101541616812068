import Navbar from "./components/Navbar/Navbar";

// bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
// react-toastify
import 'react-toastify/dist/ReactToastify.css';

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './App.css';
import PageLanding from "./pages/PageLanding";
import {Route, Switch} from "react-router-dom";
import News from "./pages/News";
import React, {useEffect, useState} from "react";
import JanubiyGarbiy from "./pages/JanubiyGarbiy";
import Footer from "./components/Footer/footer";
import Batafsil from "./components/Batafsil/Batafsil";
import Networks from "./components/Networks/Networks";
import Boglanish from "./components/Boglanish/Boglanish";
import BoglanishTel from "./components/Boglanish/BoglanishTel";
import Qonunchilik from "./components/Qonunchilik/Qonunchilik";
import OchiqMalumot from "./components/OchiqMalumot/OchiqMalumot";
import Vazirlikhaqida from "./components/VazirlikHaqida/Vazirlikhaqida";
import Rahbaryat from "./components/VazirlikHaqida/Rahbaryat";
import Qktarixi from "./components/VazirlikHaqida/Qktarixi";
import Mvfunksiya from "./components/VazirlikHaqida/Mvfunksiya";
import Bushish from "./components/VazirlikHaqida/Boshish/Bushish";
import GalabaBogi from "./components/VazirlikHaqida/Boshish/GalabaBogi";
import MvBushIsh from "./components/VazirlikHaqida/Boshish/MvBushIsh";
import TegishliMurojat from "./components/OchiqMalumot/Barchasi/TegishliMurojat";
import Jamoatchilik1 from "./components/OchiqMalumot/Barchasi/Jamoatchilik1";
import Jamoatchilik2 from "./components/OchiqMalumot/Barchasi/Jamoatchilik2";
import MVgaMurojat from "./components/OchiqMalumot/Barchasi/MVgaMurojat";
import MVmarkaziyAp from "./components/OchiqMalumot/Barchasi/MVmarkaziyAp";
import VilMudIsh from "./components/OchiqMalumot/Barchasi/VilMudIsh";
import Rahbariyat from "./components/OchiqMalumot/Barchasi/Rahbariyat";
import OzResMv from "./components/OchiqMalumot/Barchasi/OzResMV";
import NotFound from "./components/NotFound/NotFound";
import KorrupsiyagaQarshi from "./components/FoydaliMalumotlar/Bulimlar/KorrupsiyagaQarshi";
import Gospital from "./components/MHGospital/Gospital";
import Akademiya from "./components/FoydaliMalumotlar/Bulimlar/Akademiya/Akademiya";
import TibbiyotAkademiyasi from "./components/FoydaliMalumotlar/Bulimlar/TibbiyotAkademiyasi/TibbiyotAkademiyasi";
import HarbiyMeros from "./components/FoydaliMalumotlar/Bulimlar/HarbiyMeros/HarbiyMeros";
import {useDispatch, useSelector} from "react-redux";
import {getYoutubeVideo} from "./redux/actions/youtubeAction";
import {ToastContainer} from "react-toastify";



function App() {
    const dispatch = useDispatch();
    const [imageStyle, setImageStyle] = useState({});


    let addStyle111 = `${localStorage.getItem('addStyle11')}`

    const getYoutube = () => {
        dispatch(getYoutubeVideo(false));
    };

    useEffect(()=>{
        getYoutube()
        if (addStyle111 === 'addStyle11') {
            // console.log("1321321321321321")
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#9DD1FF",
                color:"black",
                width: "100%",
                filter: 'grayscale(50%)',
                // Add additional styles or modifications here
            });

        }
        if (addStyle111 === 'addStyle22') {
            // console.log("1321321321321321")
            setImageStyle({
                ...imageStyle,
                color:"white",
                backgroundColor: "#464646",
                width: "100%",
                filter: 'grayscale(70%)',
                // Add additional styles or modifications here
            });
        }
        if (addStyle111 === 'addStyle33') {
            // console.log("1321321321321321")
            setImageStyle({
                ...imageStyle,
                backgroundColor: "#1c1c1c",
                color:"white",
                width: "100%",
                filter: 'grayscale(100%)',
                // Add additional styles or modifications here
            });
        }
        if (addStyle111 === 'addStyle44'){
            // console.log("1321321321321321")
            setImageStyle({

            });
        }






    }, [localStorage.getItem("addStyle11")]);








  return (
      <>
          <div className="App">
              <div style={{position:"sticky", top:0, zIndex:1}}>
                  <Navbar/>
              </div>
              <Networks/>
              <Switch>
                  <Route exact path={'/'} component={PageLanding}/>
                  <Route exact path={'/vazirlik-haqida'} component={Vazirlikhaqida}/>
                  <Route exact path={'/rahbaryat'} component={Rahbaryat}/>
                  <Route exact path={'/qurolli-kuchlar-tarixi'} component={Qktarixi}/>
                  <Route exact path={'/mvfunksiya'} component={Mvfunksiya}/>
                  <Route exact path={'/boshish'} component={Bushish}/>
                  <Route exact path={'/yangiliklar'} component={News}/>
                  <Route exact path={'/newsType/:id'}  component={JanubiyGarbiy}/>
                  <Route exact path={'/batafsil/:id'} component={Batafsil}/>
                  <Route exact path={'/boglanish'} component={Boglanish}/>
                  <Route exact path={'/boglanishTel'} component={BoglanishTel}/>
                  <Route exact path={'/qonunchilik'} component={Qonunchilik}/>
                  <Route exact path={'/ochiq-malumot'} component={OchiqMalumot}/>
                  <Route exact path={'/bush-ish-galaba'} component={GalabaBogi}/>
                  <Route exact path={'/mv-bush-ish'} component={MvBushIsh}/>
                  <Route exact path={'/teg-mur'} component={TegishliMurojat}/>
                  <Route exact path={'/jam-ken-yig'} component={Jamoatchilik1}/>
                  <Route exact path={'/jam-ken'} component={Jamoatchilik2}/>
                  <Route exact path={'/mv-murojat'} component={MVgaMurojat}/>
                  <Route exact path={'/mv-markaziy'} component={MVmarkaziyAp}/>
                  <Route exact path={'/vil-mib'} component={VilMudIsh}/>
                  <Route exact path={'/rahbaryat-ochiq'} component={Rahbariyat}/>
                  <Route exact path={'/oz-res-mv'} component={OzResMv}/>
                  <Route exact path={'/akademiya'} component={Akademiya}/>
                  <Route exact path={'/korrupsiyaga-qarshi'} component={KorrupsiyagaQarshi}/>
                  <Route exact path={'/gospital'} component={Gospital}/>
                  <Route exact path={'/tibbiyot-akademiyasi'} component={TibbiyotAkademiyasi}/>
                  <Route exact path={'/harbiy-meros'} component={HarbiyMeros}/>

                  <Route exact path="*" component={NotFound}/>
              </Switch>

              <Footer/>
              <ToastContainer autoClose={2500}/>

          </div>
      </>
  );
}

export default App;
