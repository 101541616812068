import React, {useEffect, useState,} from 'react';
import './Landing.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';
import {getText} from "../Translation";

import Slider from "react-slick";
import {Link} from "react-router-dom";

import Typed from 'react-typed';
import {useSelector} from "react-redux";
import logo from "./assets/Logo.png"
import img1 from "./assets/srgsfsdfgdsfg-696x421.jpg"
import img2 from "./assets/wsefdqwefdwedfe-1920x1161.jpg"
import img3 from "./assets/photo_2022-12-10_19-00-04-2-696x420.jpg"
import img4 from "./assets/photo_2022-08-04_20-06-48.jpg"
import img5 from "./assets/photo_2022-12-10_19-00-05.jpg"
import img6 from "./assets/photo_2022-12-16_12-10-15.jpg"
import AuthUser from "../Auth/AuthUser";
import {PATH_NAME_AUTH} from "../../tools/constant";


function Landing({props}) {

    const {http} = AuthUser()
    const [postList, setPostList] = useState([])

    const {language} = useSelector(state => state.language);

    useEffect(()=> {
        AOS.init({
            duration: 3000,
            easing: 'ease-in-out',
            delay: 200,
            once: true
        });

        http.get('user/post/recommended',{
            params:{
                lang: language
            }
        })
            .then(res => {
                // console.log(res)
                setPostList(res.data.object.postComponentList.content)
            }).catch(e => {
            e.error("Bog'lanishda xatolik!")
            // console.log(e.message)
        })



    },[language])
    const settings = {
        dots: true,
        // fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const textLines = [
        `Assalomu alaykum!`, `mudofaa vazirligi <br> rasmiy veb saytiga <br> xush kelibsiz`,
    ];


    // console.log(lenguage)

    return (
        <div className="landing" style={{maxHeight: "90vh", boxSizing: "border-box"}}>
            <div className="container-fluid" >
                <div className="row">
                    <div className="offset-1 col-lg-4 box">
                        <div data-aos="fade-right" className="h-100">
                            <div className="landingLeft">
                                <div className="logoImg w-100">
                                    <img src={logo} alt=""/>
                                    <div>
                                        <h1>{getText('MINISTRY')} {getText('DEFENSE')}</h1>
                                        {/*<h1>MUDOFAA VAZIRLIGI</h1>*/}
                                        <h3>{getText("WELCOME")}</h3>
                                        {/*<h3><Typed strings={textLines} typeSpeed={150} loop={true} backDelay={3000} startDelay={1000} /></h3>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div data-aos="fade-left">
                            {/*<h2> Single Item</h2>*/}
                            <Slider {...settings}>
                                    { postList?.map((item, index)=>(

                                        <div key={index}>
                                            <div className="landingImg">
                                                <Link to={`/batafsil/${item.postId}`}>
                                                    <img src={item ? `${PATH_NAME_AUTH}${item.pathPhoto}` : null} alt="Card image" style={{maxHeight:"600px"}} className="w-100 rounded-3" />
                                                </Link>
                                                <div className="titleBox">
                                                    <div  className="title">
                                                        <h3>{item.title}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }


                                {/*<div>*/}
                                {/*        <div className="landingImg">*/}
                                {/*            <Link to={`/batafsil/${1}`}>*/}
                                {/*            <img src={img1} className="w-100 rounded-3" alt="..."/>*/}
                                {/*            </Link>*/}
                                {/*                <div className="titleBox">*/}
                                {/*                <div  className="title">*/}
                                {/*                    <h3>Nukusda maxsus amaliy o‘quv mashg‘ulotlari o‘tkazildi</h3>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}

                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <a href="">*/}
                                {/*        <div className="landingImg">*/}
                                {/*            <img src={img2} className="w-100 rounded-3" alt="..."/>*/}
                                {/*            <div className="titleBox">*/}
                                {/*                <div  className="title">*/}
                                {/*                    <h3>Nukus garnizonida korrupsiyaga qarshi kurashish boʻyicha targʻibot tadbiri oʻtkazildi.</h3>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <a href="">*/}
                                {/*        <div className="landingImg">*/}
                                {/*            <img src={img3} className="w-100 rounded-3" alt="..."/>*/}
                                {/*            <div className="titleBox">*/}
                                {/*                <div  className="title">*/}
                                {/*                    <h3>Tarbiyaviy va mafkuraviy ishlar va kadrlar organlarining kasb bayrami nishonlandi.</h3>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <a href="">*/}
                                {/*        <div className="landingImg">*/}
                                {/*            <img src={img4} className="w-100 rounded-3" alt="..."/>*/}
                                {/*            <div className="titleBox">*/}
                                {/*                <div  className="title">*/}
                                {/*                    <h3>Sharqiy harbiy okrugda batalyonlar sinovdan o‘tkazilmoqda</h3>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <a href="">*/}
                                {/*        <div className="landingImg">*/}
                                {/*            <img src={img5} className="w-100 rounded-3" alt="..."/>*/}
                                {/*            <div className="titleBox">*/}
                                {/*                <div  className="title">*/}
                                {/*                    <h3>Tarbiyaviy va mafkuraviy ishlar va kadrlar organlarining kasb bayrami nishonlandi.</h3>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <a href="">*/}
                                {/*        <div className="landingImg">*/}
                                {/*            <img src={img6} className="w-100 rounded-3" alt="..."/>*/}
                                {/*            <div className="titleBox">*/}
                                {/*                <div  className="title">*/}
                                {/*                    <h3>Tarbiyaviy va mafkuraviy ishlar va kadrlar organlarining kasb bayrami nishonlandi.</h3>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                            </Slider>
                        </div>
                    </div>


                    {/*<div className="col-lg-5">*/}
                    {/*    <div data-aos="fade-left">*/}
                    {/*        <div id="carouselExampleDark" className="carousel slide" data-bs-ride="carousel">*/}
                    {/*            <div className="carousel-inner">*/}
                    {/*                <div className="carousel-item active" data-bs-interval="5000">*/}
                    {/*                    <a href="#">*/}
                    {/*                        <img src={require('./images/srgsfsdfgdsfg-696x421.jpg')} className="d-block w-100 rounded-3" alt="..."/>*/}
                    {/*                        <div className="carousel-caption d-none d-md-block">*/}
                    {/*                            <div className="title">*/}
                    {/*                                <h5>Nukusda maxsus amaliy o‘quv mashg‘ulotlari o‘tkazildi</h5>*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}
                    {/*                <div className="carousel-item" data-bs-interval="5000">*/}
                    {/*                    <a href="#">*/}
                    {/*                        <img src={require('./images/wsefdqwefdwedfe-1920x1161.jpg')} className="d-block w-100 rounded-3" alt="..."/>*/}
                    {/*                        <div className="carousel-caption d-none d-md-block">*/}
                    {/*                            <div className="title">*/}
                    {/*                                <h5>Nukus garnizonida korrupsiyaga qarshi kurashish boʻyicha targʻibot tadbiri oʻtkazildi.</h5>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}
                    {/*                <div className="carousel-item" data-bs-interval="5000">*/}
                    {/*                    <a href="#">*/}
                    {/*                        <img src={require('./images/photo_2022-12-10_19-00-04-2-696x420.jpg')} className="d-block w-100 rounded-3" alt="..."/>*/}
                    {/*                        <div className="carousel-caption d-none d-md-block">*/}
                    {/*                            <div className="title">*/}
                    {/*                                <h5>Tarbiyaviy va mafkuraviy ishlar va kadrlar organlarining kasb bayrami nishonlandi.</h5>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"*/}
                    {/*                    data-bs-slide="prev">*/}
                    {/*                <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
                    {/*                <span className="visually-hidden">Previous</span>*/}
                    {/*            </button>*/}
                    {/*            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"*/}
                    {/*                    data-bs-slide="next">*/}
                    {/*                <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
                    {/*                <span className="visually-hidden">Next</span>*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}



                </div>



            </div>
        </div>

    );
}

export default Landing;