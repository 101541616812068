import React from 'react';
import {getText} from "../Translation";

function Vazirlikhaqida(props) {
    return (
        <div className="about">
            <div className="container">
                {/*<p className="mt-2" style={{color: "#989898"}}>Bosh sahifa > Vazirlik haqida</p>*/}
                <h3 className="mt-5 mb-5">{getText('ABOUT_THE_MINISTRY')}</h3>
            </div>

        </div>
    );
}

export default Vazirlikhaqida;