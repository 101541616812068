import axios from "axios";

export default function AuthUser(){
    const http = axios.create({
        // baseURL:"https://192.168.202.35:443/api/v1",
        // baseURL:"http://192.168.202.35:8088/api/v1",
        baseURL:"https://new.mudofaa.uz:8443/api/v1",
        // headers:{
        //     "Content-type" : "application/json",
        //     "Authorization" : `Bearer `+ localStorage.getItem('token')
        // }
    });

    return{
        http
    }
}