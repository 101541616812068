import React from 'react';
import korrupsiyaImg from '../assets/Korrupsiya.png'

function KorrupsiyagaQarshi(props) {
    return (
        <div className='container w-100 text-center'>
            <img style={{maxWidth: "100vh", }} className='w-100 my-3' src={korrupsiyaImg} alt=""/>

        </div>
    );
}

export default KorrupsiyagaQarshi;