import React from 'react';

function TegishliMurojat(props) {
    return (
        <div className='container'>
            <br/>
            <h3>Tegishli murojaatlar boʻyicha masʼul xodimlar</h3>
            <br/>
            <p>Oʻzbekiston Respublikasi mudofaa vazirining tegishli buyrugʻiga binoan uchuvchisiz uchish apparatlaridan foydalangan holda aerofotosuratga olish ishlarini bajarish uchun ruxsat olishga yuridik shaxslarning murojaatlarini va Mudofaa vazirligiga kelib tushgan aerofotosurat materiallarini nazorat koʻrigidan oʻtkazish masalalari boʻyicha hamda qurol-yarogʻ, harbiy texnika, harbiy mol-mulk va harbiy tarkibni Oʻzbekiston Respublikasi hududi orqali tranzit qilish uchun Mudofaa vazirligi tomonidan maxsus ruxsatnomalarni berishni tashkillashtiradigan masʼul xodimlarning maʼlumotlari.</p>
            <br/>
            <h4>Mudofaa vazirligi Harbiy harakatlar boshqarmasidan:</h4>
            <p>Turaxanov Elmurod Ermatbekovich</p>
            <p><b>Tel (71) 269-86-92;</b></p>
            <br/>
            <h4>Mudofaa vazirligi Harbiy harakatlar boshqarmasidan:</h4>
            <p>Mirzaqulov Behzod Baxtiyorovich</p>
            <p><b>Tel (71) 269-86-93;</b></p>
            <br/>
            <h4>O`zbekiston Respublikasi Qurolli Kuchlar Bosh shtabidan:</h4>
            <p>Nazarov Rustam Boliyevich</p>
            <p><b>Tel (71) 269-84-75;</b></p>
            <br/>
            <h4>O`zbekiston Respublikasi Qurolli Kuchlar Bosh shtabidan:</h4>
            <p>Mahmudov Sardor Hasanovich</p>
            <p><b>Tel (71) 269-84-76</b></p>
            <br/>
            <h4>O`zbekiston Respublikasi Mudofaa vazirligi HHM qo`shinlari va HHK qo`mondonligidan:</h4>
            <p>Ashurov Sobir Rahmatovich</p>
            <p><b>Tel (71) 289-98-34, (71) 289-98-37</b></p>
            <br/>
        </div>
    );
}

export default TegishliMurojat;