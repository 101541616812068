import React from 'react';

function Jamoatchilik2(props) {
    return (
        <div className="container">
            <div className="col-8">
                <h2>Jamoatchilik kengashi</h2>
                <br/>
                <div className="text-center">
                    <b>Mudofaa vazirligi huzuridagi Jamoatchilik kengashi
                        a’zolari haqidagi ma’lumotlar</b>
                    <br/>
                </div>

                <table className="table table-bordered mt-3">
                    <thead>
                    <tr>
                        <th>T/r</th>
                        <th>Familiyasi, ismi va otasining ismi</th>
                        <th>Jamoatchilik kengashi a’zosining ish joyi va lavozimi</th>
                        <th>Telefon raqamlari va elektron manzillari</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1.</td>
                        <td>Mirzaaliyev Iqboljon Mirzakarimovich</td>
                        <td>O‘zbekiston Respublikasi Oliy Majlisi Qonunchilik palatasi deputati, Jamoatchilik kengashi raisi</td>
                        <td>90- 349 -40-20</td>
                    </tr>
                    <tr>
                        <td>2.</td>
                        <td>
                            Turdiyev Sodiqjon</td>
                        <td>O‘zbekiston faxriylarining ijtimoiy faoliyatini qo‘llab-quvvatlash “Nuroniy” jamg‘armasi respublika boshqaruvi raisi, Jamoatchilik kengashi raisi o ‘rinbosari</td>
                        <td>98-137-05-04.</td>
                    </tr>
                    <tr>
                        <td>3.</td>
                        <td>
                            Burxanov Kutbidin Nukritdinovich</td>
                        <td>O‘zbekiston Respublikasi Oliy Majlisi Senatining Mudofaa va xavfsizlik masalalari qo‘mitasi raisi, Jamoatchilik kengashi raisi o ‘rinbosari</td>
                        <td>94-618-87-77</td>
                    </tr>
                    <tr>
                        <td>4.</td>
                        <td>Salixov Erkin Tagirovich</td>
                        <td>O‘zbekiston Respublikasi Oliy Majlisi Qonunchilik palatasining Mudofaa va xavfsizlik masalalari qo‘mitasi raisi</td>
                        <td>94-643-03-03</td>
                    </tr>
                    <tr>
                        <td>5.</td>
                        <td>Karimov Anvar Rasulovich</td>
                        <td>O‘zbekiston Respublikasi iqtisodiyot va moliya vazirining o‘rinbosari</td>
                        <td><a href="mailto:info@imv.uz">info@imv.uz</a></td>
                    </tr>
                    <tr>
                        <td>6.</td>
                        <td>Turdiboyeva Ra’no Eshovna</td>
                        <td>O‘zbekiston Respublikasi kambag‘allikni qisqartirish va bandlik vazirining o‘rinbosari, yuridik fanlari doktori</td>
                        <td>93-557-45-51</td>
                    </tr>
                    <tr>
                        <td>7.</td>
                        <td>Buzrukxonov Sarvarxon Munavarxonovich</td>
                        <td>O‘zbekiston Respublikasi oliy ta’lim, fan va innovatsiyalar vazirining o‘rinbosari</td>
                        <td>90-980-07-65 <a href="mailto:sb@edu.uz">sb@edu.uz</a></td>
                    </tr>
                    <tr>
                        <td>8.</td>
                        <td>Kenjayev

                            Dilshod Mutalibovich</td>
                        <td>O‘zbekiston Respublikasi maktabgacha va maktab ta’limi vazirining o‘rinbosari</td>
                        <td><a href="mailto:info@xtv.uz">info@xtv.uz</a> @uzedu. 90-176-38-73</td>
                    </tr>
                    <tr>
                        <td>9.</td>
                        <td>Ahmedov Bahodir Majidovich</td>
                        <td>O‘zbekiston Respublikasi madaniyat va turizm vazirining o‘rinbosari</td>
                        <td>94-627-7277</td>
                    </tr>
                    <tr>
                        <td>10.</td>
                        <td>
                            Meliyev Xudoyor Xurramovich</td>
                        <td>O‘zbekiston Respublikasi adliya vazirining o‘rinbosari</td>
                        <td>88-444-50-05.</td>
                    </tr>
                    
                    </tbody>
                </table>


            </div>
        </div>
    );
}

export default Jamoatchilik2;