
export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const FONT_SIZE = "FONT_SIZE";
export const ADD_STYLE1 = "ADD_STYLE1";
export const ADD_STYLE2 = "ADD_STYLE2";
export const ADD_STYLE3 = "ADD_STYLE3";
export const ADD_STYLE = "ADD_STYLE";
export const YOUTUBE_GET = "YOUTUBE_GET";
export const GET_VIDEOS_ALL = "GET_VIDEOS_ALL";
export const GET_VIDEOS = "GET_VIDEOS";
