import React from 'react';

function Mvfunksiya(props) {
    return (
        <div className="mvfunction">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        {/*<p className="mt-2" style={{color: "#989898"}}>Bosh sahifa > O‘zbekiston Respublikasi Mudofaa vazirligi funksiya va vazifalari</p>*/}
                        <h4>O‘zbekiston Respublikasi Mudofaa vazirligi funksiya va vazifalari</h4>
                        <br/>
                        <h5>Funksiyalari</h5>
                        <ul>
                            <li>O‘z tasarrufidagi qo‘shinlarda boshqaruvni amalga oshirish;</li>
                            <li>Mudofaa va harbiy qurilish sohasidagi davlat siyosatini yuritish;</li>
                            <li>O‘zbekiston Respublikasi Mudofaa vazirligi qo‘shinlarini zamonaviy qurol-yarog‘lar, harbiy texnika va moddiy-texnika vositalari bilan jihozlash; harbiy hamkorlikni kengaytirish;</li>
                            <li>Chaqiruvning va Qurolli Kuchlar kadrlari tayyorlashning tashkil etilishini ta’minlash.</li>
                        </ul>
                        <br/>
                        <h5>Vazifalari</h5>
                        <ul>
                            <li>Miliy harbiy strategiya, Qurolli Kuchlar qurilishi va rivojlantirilishining konseptual asoslarini ishlab chiqish uchun mas’ul bo‘ladi;</li>
                            <li>Qo‘shinlarning jangovar harakatlari strategik jihatdan va tezkor rejalashtirilishini hamda ularga rahbarlikni amalga oshiradi;</li>
                            <li>Qo‘shinlarning tezkor va jangovar tayyorgarligiga, ular tashkiliy-shtat tuzilishining qo‘shinlarni qo‘llash shakl va usullariga ko‘nikma hosil qilishiga doir tadbir-choralar ishlab chiqadi hamda amalga oshiradi;</li>
                            <li>O‘z vakolatlari doirasida barcha vazirliklar, davlat qo‘mitalari va idoralar tomonidan, Qurolli Kuchlarning shakl va turdagi qo‘shinlarining, birlashmalari, qo‘shilmalari va bo‘linmalarining qo‘mondonlari tomonidan ijro etilishi majburiy bo‘lgan direktivalar va buyruqlar chiqaradi;</li>
                            <li>Qurolli Kuchlarni, harbiy okruglarni, vazirliklar, davlat qo‘mitalari va idoralarning harbiy tuzilmalarini tezkor-strategik boshqarishning bosh organi vazifasini bajaradi.</li>
                        </ul>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Mvfunksiya;